import gql from "graphql-tag";

const patientMemberFragment = gql`
  fragment patient on Patient {
    id
    kind
    pii {
      fullName
      photo
    }
    __typename
  }
`;

const providerMemberFragment = gql`
  fragment provider on Provider {
    id
    kind
    firstName
    lastName
    __typename
  }
`;

const orgMemberFragment = gql`
  fragment org on Organization {
    id
    name
    kind
    __typename
  }
`;

const systemMemberFragment = gql`
  fragment system on System {
    id
    kind
    __typename
  }
`;

export const channelMembersFragment = gql`
  fragment channelMembers on IMChannelMember {
    ...patient
    ...provider
    ...org
    ...system
    __typename
  }
  ${patientMemberFragment}
  ${providerMemberFragment}
  ${orgMemberFragment}
  ${systemMemberFragment}
`;

export const textMessageFragment = gql`
  fragment textMessage on IMTextMessage {
    id
    kind
    readRequired
    createdAt
    text
    author {
      ...channelMembers
    }
    aeName
    __typename
  }
  ${channelMembersFragment}
`;

export const audioMessageFragment = gql`
  fragment audioMessage on IMAudioMessage {
    id
    kind
    readRequired
    createdAt
    caption: text
    url
    thumbnailUrl
    author {
      ...channelMembers
    }
    __typename
  }
  ${channelMembersFragment}
`;

export const videoMessageFragment = gql`
  fragment videoMessage on IMVideoMessage {
    id
    kind
    readRequired
    createdAt
    caption: text
    url
    thumbnailUrl
    author {
      ...channelMembers
    }
    __typename
  }
  ${channelMembersFragment}
`;

export const eduMaterialMessageFragment = gql`
  fragment eduMaterialMessage on IMEduMaterialMessage {
    id
    kind
    readRequired
    createdAt
    eduMaterial {
      id
      name
      url
      category
      type
    }
    caption: text
    materialId
    deletedAt
    author {
      ...channelMembers
    }
    __typename
  }
  ${channelMembersFragment}
`;

export const helpRequestFragment = gql`
  fragment helpRequest on PatientHelpRequest {
    id
    pk
    sk
    kind
    createdAt
    canceledAt
    completedAt
    completedBy
    caption: text
    fileName
    fileUrl: url
    fileThumbnailUrl: thumbnailUrl
    option {
      title
      type
    }
    author {
      ...channelMembers
    }
    __typename
  }
  ${channelMembersFragment}
`;

export const imageMessageFragment = gql`
  fragment imageMessage on IMImageMessage {
    id
    kind
    readRequired
    createdAt
    caption: text
    url
    thumbnailUrl
    author {
      ...channelMembers
    }
    __typename
  }
  ${channelMembersFragment}
`;

export const channelMessagesFragment = gql`
  fragment channelMessages on IMChannelMessage {
    ...textMessage
    ...imageMessage
    ...audioMessage
    ...videoMessage
    ...eduMaterialMessage
    __typename
  }
  ${textMessageFragment}
  ${imageMessageFragment}
  ${audioMessageFragment}
  ${videoMessageFragment}
  ${eduMaterialMessageFragment}
`;

export const channelMessagesWithHelpRequestsFragment = gql`
  fragment channelMessagesWithHelpRequests on IMChannelMessage {
    ...textMessage
    ...imageMessage
    ...audioMessage
    ...videoMessage
    ...eduMaterialMessage
    ...helpRequest
    __typename
  }
  ${textMessageFragment}
  ${imageMessageFragment}
  ${audioMessageFragment}
  ${videoMessageFragment}
  ${eduMaterialMessageFragment}
  ${helpRequestFragment}
`;
