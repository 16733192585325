import { Module } from "vuex";

import router from "@/router/routes";
import apollo from "@/apollo";
import logger from "@/logger";
import { ICampaignModuleState, IRootState } from "../../../@types/store";
import { ICampaign } from "../../../@types/organization";
import { Campaign, Query } from "generated/types-gql";
import { SET_PATIENT_CAMPAIGNS_SEEN } from "@/graphql/mutations/patient.mutation";
import {
  GET_PATIENT_PAGINATED_SURVEYS,
  GET_PATIENT_SURVEY,
  GET_PATIENT_SURVEYS,
} from "@/graphql/queries/patient.query";
import { PAGINATION_LIMITS } from "@/configs/constants";

const initialState: ICampaignModuleState = {
  patientCampaignMap: {},
  allCampaignsLoaded: false,
  campaignListScrollPosition: null,
};

const module: Module<ICampaignModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getPatientCampaignActivities(
      { commit, rootGetters },
      {
        patientId,
        startKey,
        sort = "DSC",
      }: { patientId: string; startKey: string; sort: "ASC" | "DSC" },
    ) {
      try {
        if (!rootGetters.rootOrganization?.id) {
          return false;
        }
        let data: Query;
        if (!rootGetters.paginationFeatures.surveys) {
          const result = await apollo.query<Query>({
            query: GET_PATIENT_SURVEYS,
            variables: {
              patient: patientId,
              rootOrgId: rootGetters.rootOrganization?.id,
            },
          });
          data = result.data;
        } else {
          const result = await apollo.query<Query>({
            query: GET_PATIENT_PAGINATED_SURVEYS,
            variables: {
              patient: patientId,
              startKey: startKey,
              rootOrgId: rootGetters.rootOrganization?.id,
              limit: PAGINATION_LIMITS.surveys || Infinity,
              sort,
            },
          });
          data = result.data;
        }

        if (
          (data.patient?.paginatedCampaigns || []).length <
            PAGINATION_LIMITS.surveys &&
          sort === "DSC" &&
          rootGetters.paginationFeatures.surveys
        ) {
          commit("setAllCampaignsLoaded");
        }
        const campaigns = rootGetters.paginationFeatures.surveys
          ? data.patient?.paginatedCampaigns
          : data.patient?.campaigns || [];
        commit("setPatientCampaigns", { patientId, campaigns });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getCampaign(
      { commit, rootGetters },
      { campaignId, patientId }: { campaignId: string; patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_SURVEY,
          variables: { campaign: campaignId },
        });
        commit("updatePatientCampaigns", {
          campaign: data.campaign,
          patientId,
        });
        if (
          rootGetters.selectedCampaign?.id === campaignId &&
          rootGetters.isSidebarOpenedFully
        ) {
          commit("selectCampaign", data.campaign);
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async setCampaignsAsSeen(_, { campaignId }: { campaignId: string }) {
      try {
        await apollo.query<Query>({
          query: SET_PATIENT_CAMPAIGNS_SEEN,
          variables: {
            campaignId,
          },
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },
  },
  getters: {
    patientCampaignMap: (state) => {
      return state.patientCampaignMap || {};
    },
    patientCampaigns: (state) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientCampaignMap?.[patientId] || [];
    },
    getCampaignById: (state) => (id: string) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientCampaignMap?.[patientId]?.find((c) => c.id === id);
    },
    allCampaignsLoaded(state) {
      return state.allCampaignsLoaded;
    },
    campaignListScrollPosition(state) {
      return state.campaignListScrollPosition;
    },
    unseenCampaignCount() {
      // TODO handle with timeline events, remove tagged event functionality
      // const patientId = router.currentRoute?.params?.patientId;
      // const alerts =
      //   rootGetters.timelineEvents?.filter(
      //     (a) => a.eventType === "alert" && a.campaign && !a.isNonMedical,
      //   ) || [];
      // const alertSurveys = alerts
      //   .map((a: any) => a.campaign)
      //   ?.filter(
      //     (s: any, index: number, self: any) =>
      //       index === self.findIndex((t: any) => t === s),
      //   );
      // return state.patientCampaignMap?.[patientId]?.filter(
      //   (c) =>
      //     !c.responseViewedAt &&
      //     c?.ppRules?.showInSurveyList &&
      //     (alertSurveys.includes(c.id) || (c.lastCompletedAt && c.provider)),
      // )?.length;
      return 0;
    },
  },
  mutations: {
    setPatientCampaigns(
      state,
      data: { campaigns: ICampaign[]; patientId: string },
    ) {
      const uniqueCampaigns: Campaign[] = [];
      [
        ...(state.patientCampaignMap?.[data.patientId] || []),
        ...data.campaigns,
      ].forEach((item) => {
        const i = uniqueCampaigns.findIndex((x) => x.id === item.id);
        if (i <= -1) {
          uniqueCampaigns.push(item);
        } else if (item.createdAt > uniqueCampaigns[i].createdAt) {
          uniqueCampaigns[i] = item;
        }
      });
      const sorted = [...uniqueCampaigns]?.sort(
        (a, b) => b.createdAt - a.createdAt,
      );
      state.patientCampaignMap[data.patientId] = sorted;
      state.patientCampaignMap = { ...state.patientCampaignMap };
    },

    updatePatientCampaigns(
      state,
      data: { campaign: Campaign; patientId: string },
    ) {
      if (data?.campaign != null) {
        const campaigns = [
          ...(state.patientCampaignMap?.[data.patientId] || []),
        ];
        const indexForReplace = campaigns?.findIndex(
          (c) => c.id === data.campaign.id,
        );
        if (indexForReplace != null && indexForReplace > -1) {
          campaigns?.splice(indexForReplace, 1, data.campaign);
        } else {
          (campaigns || []).push(data.campaign);
        }
        const sorted = [...campaigns]?.sort(
          (a, b) => b.createdAt - a.createdAt,
        );
        state.patientCampaignMap[data.patientId] = sorted;
        state.patientCampaignMap = { ...state.patientCampaignMap };
      }
    },

    setAllCampaignsLoaded(state) {
      state.allCampaignsLoaded = true;
    },

    setCampaignListScrollPosition(state, position: number) {
      state.campaignListScrollPosition = position;
    },

    resetCampaignModule(state) {
      state.patientCampaignMap = {};
      state.allCampaignsLoaded = false;
      state.campaignListScrollPosition = null;
    },
  },
};

export default module;
