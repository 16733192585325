import gql from "graphql-tag";

import {
  channelMessagesFragment,
  channelMessagesWithHelpRequestsFragment,
} from "../fragments/message.fragments";

export const GET_PATIENT_CHANNELS = gql`
  query patient_channels($id: ID!, $orgIds: [String!]) {
    patient(id: $id) {
      id
      imChannels(organizations: $orgIds) {
        id
        name
        type
        orgId
        lastReadRequiredTimestamp
        missedMessages {
          ...channelMessages
        }
      }
      createdAt
    }
  }
  ${channelMessagesFragment}
`;

export const GET_PATIENT_MISSED_MESSAGES = gql`
  query patient_missed_messages($id: ID!, $orgIds: [String!]) {
    patient(id: $id) {
      id
      imChannels(organizations: $orgIds) {
        id
        membersMissedMessageCount
        ownerLastSeenMessageId
        lastOwnerMissedMessagesSeenAt
        lastReadRequiredTimestamp
        orgId
      }
    }
  }
`;

export const GET_CHANNEL_PAGINATED_MESSAGES_WITH_HELP_REQUESTS = gql`
  query channel_paginated_messages_with_help_requests(
    $id: ID!
    $startKey: String
    $limit: Float
    $sort: String
    $HRStartKey: String
    $HRSort: String
    $HRLimit: Float
  ) {
    imChannel(id: $id) {
      id
      name
      type
      membersMissedMessageCount
      ownerLastSeenMessageId
      lastOwnerMissedMessagesSeenAt
      lastReadRequiredTimestamp
      orgId
      paginatedMessagesWithHelpRequests(
        startKey: $startKey
        limit: $limit
        sort: $sort
        HRStartKey: $HRStartKey
        HRSort: $HRSort
        HRLimit: $HRLimit
      ) {
        ...channelMessagesWithHelpRequests
      }
      missedMessages {
        ...channelMessagesWithHelpRequests
      }
      earliestMissedMessage {
        ...channelMessagesWithHelpRequests
      }
    }
  }
  ${channelMessagesWithHelpRequestsFragment}
`;

export const GET_CHANNEL_MESSAGES = gql`
  query channel_messages($id: ID!, $cursorStart: Float, $cursorEnd: Float) {
    imChannel(id: $id) {
      id
      name
      type
      membersMissedMessageCount
      ownerLastSeenMessageId
      lastOwnerMissedMessagesSeenAt
      lastReadRequiredTimestamp
      orgId
      messages(cursorStart: $cursorStart, cursorEnd: $cursorEnd) {
        ...channelMessages
      }
      missedMessages {
        ...channelMessages
      }
      earliestMissedMessage {
        ...channelMessages
      }
    }
  }
  ${channelMessagesFragment}
`;
