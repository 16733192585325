import logger from "@/logger";
import store from "@/store/store";
import $http from "@/services/rest.service";
import { AnalyticsEvent, IPPPageViewInput } from "../../@types/analytics";
import { token } from "./auth.service";

const BASE_PATH = "/pp/orgs";

export const sendAnalytics = async (data: AnalyticsEvent) => {
  try {
    // Backend does not authorize requests without pp/orgs/:orgId prefix
    if (!store.getters?.rootOrganization?.id) {
      logger.warn(`Root organization id is required`);
      return;
    }
    await fetch(
      `${process.env.VUE_APP_API_PATH}/pp/orgs/${store.getters.rootOrganization.id}/analytics`,
      {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          "X-Request-Origin": "provider-portal",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        keepalive: true,
      },
    );
  } catch (error) {
    logger.error(error as Error);
  }
};

export const registerPPPageView = async (
  input: IPPPageViewInput,
  orgId: string,
) => {
  return $http.post(`${BASE_PATH}/${orgId}/page-views`, input);
};
