import { Module } from "vuex";

import {
  IPatientProfileModuleState,
  IPatientTabletStats,
  IPatientTeamSettings,
  IRootState,
} from "../../../@types/store";

import router from "@/router/routes";
import apollo from "@/apollo";
import logger from "@/logger";
import {
  GET_PATIENT_CARE_RESOURCES,
  GET_PATIENT_PROFILE_TAB,
  GET_PATIENT_SOCIAL_HISTORY,
} from "@/graphql/queries/patient.query";
import {
  Mutation,
  PatientCareResources,
  PatientOrganization,
  PatientSocialHistory,
  Query,
} from "generated/types-gql";
import {
  getPatientScreenings,
  getPatientTabletStats,
  getPatientTeamSettings,
  updateOrgPatientScreenings,
  updatePatientProfile,
} from "@/services/patient.service";
import {
  UPDATE_PATIENT_CARE_RESOURCES,
  UPDATE_PATIENT_SOCIAL_HISTORY,
  UPDATE_PATIENT_TIMEZONE,
} from "@/graphql/mutations/patient.mutation";
import {
  IPatientFieldsMetadataGroup,
  IPatientHealthLiteracyScreening,
  IPatientPii,
} from "../../../@types/patient";

const initialState: IPatientProfileModuleState = {
  patientCareResourcesMap: {},
  patientSocialHistoryMap: {},
  patientTabletStatsMap: {},
  patientTypeMap: {},
  patientTeamsMap: {},
  patientScreeningMap: {},
  patientTeamSettings: null,
};

const module: Module<IPatientProfileModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getPatientTeamSettings(
      { commit, rootGetters },
      { patientId, teamId }: { patientId: string; teamId?: string },
    ) {
      try {
        const { data: patientTeamSettings } = await getPatientTeamSettings(
          rootGetters.rootOrganization.id,
          patientId,
          teamId,
        );
        commit("setPatientTeamSettings", patientTeamSettings || {});
      } catch (error) {
        logger.error(error as Error);
      }
    },
    async getPatientProfileTab(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const [{ data: screenings }, { data: stats }, { data: tabData }] =
          await Promise.all([
            getPatientScreenings(rootGetters.rootOrganization?.id, patientId),
            getPatientTabletStats(rootGetters.rootOrganization?.id, patientId),
            apollo.query<Query>({
              query: GET_PATIENT_PROFILE_TAB,
              variables: {
                patient: patientId,
              },
            }),
          ]);
        commit("setPatientSocialHistory", {
          socialHistory: tabData.patient?.socialHistory,
          patientId,
        });
        commit("setPatientCareAndResources", {
          careResources: tabData.patient?.careResources,
          patientId,
        });
        commit("setPatientTeams", {
          teams: tabData.patient?.teams,
          patientId,
        });
        commit("setPatientTabletStats", {
          stats,
          patientId,
        });
        commit("setPatientScreenings", {
          screenings,
          patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientSocialHistory(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_SOCIAL_HISTORY,
          variables: { patient: patientId },
        });
        commit("setPatientSocialHistory", {
          socialHistory: data.patient?.socialHistory,
          patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientSocialHistory(
      { commit, dispatch },
      {
        patient,
        socialHistory,
      }: { patient: string; socialHistory: PatientSocialHistory },
    ) {
      try {
        commit("dataPending");
        await apollo.mutate<Mutation>({
          mutation: UPDATE_PATIENT_SOCIAL_HISTORY,
          variables: {
            input: { ...socialHistory, patient },
          },
        });
        dispatch("getPatientSocialHistory", { patientId: patient });
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async getPatientCareResources(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_CARE_RESOURCES,
          variables: { patient: patientId },
        });
        commit("setPatientCareAndResources", {
          careResources: data.patient?.careResources,
          patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientCareResources(
      { commit, dispatch },
      careResources: PatientCareResources,
    ) {
      try {
        commit("dataPending");
        await apollo.mutate<Mutation>({
          mutation: UPDATE_PATIENT_CARE_RESOURCES,
          variables: { input: careResources },
        });
        dispatch("getPatientCareResources", {
          patientId: careResources.patient,
        });
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async getPatientScreenings(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await getPatientScreenings(
          rootGetters.rootOrganization?.id,
          patientId,
        );
        commit("setPatientScreenings", {
          screenings: data,
          patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientScreening(
      { dispatch, rootGetters },
      {
        patientId,
        screening,
      }: {
        patientId: string;
        screening: IPatientHealthLiteracyScreening;
      },
    ) {
      try {
        await updateOrgPatientScreenings(
          rootGetters.rootOrganization?.id,
          patientId,
          screening,
        );
        dispatch("getPatientScreenings", {
          orgId: rootGetters.rootOrganization?.id,
          patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientPii(
      { commit, dispatch, rootGetters },
      {
        orgId,
        patientId,
        patient,
      }: {
        orgId: string;
        patientId: string;
        patient: IPatientPii;
      },
    ) {
      commit("dataPending");
      try {
        const clonePatient = { ...patient };
        delete clonePatient.timezone;
        await updatePatientProfile(orgId, patientId, clonePatient);
        if (
          patient.timezone != null &&
          patient.timezone !== rootGetters.patient?.pii?.timezone
        ) {
          await apollo.mutate<Mutation>({
            mutation: UPDATE_PATIENT_TIMEZONE,
            variables: {
              input: {
                user: patientId,
                timezone: patient.timezone,
              },
            },
          });
        }
        await dispatch("getPatientPii", { patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },
  },
  getters: {
    patientTypeMap: (state) => {
      return state.patientTypeMap || {};
    },
    patientTabletStatsMap: (state) => {
      return state.patientTabletStatsMap || {};
    },
    patientTeamsMap: (state) => {
      return state.patientTeamsMap || {};
    },
    patientCareResourcesMap: (state) => {
      return state.patientCareResourcesMap || {};
    },
    patientSocialHistoryMap: (state) => {
      return state.patientSocialHistoryMap || {};
    },
    patientScreeningMap: (state) => {
      return state.patientScreeningMap || {};
    },
    patientType(state, rootGetters) {
      const patientId = router.currentRoute?.params?.patientId;
      const typeId = state.patientTypeMap?.[patientId];
      return rootGetters.allPatientTypesMap[typeId];
    },
    patientTabletStats(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientTabletStatsMap?.[patientId] || [];
    },
    patientTeams(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientTeamsMap?.[patientId] || [];
    },
    patientTeamSettings(state) {
      return state.patientTeamSettings;
    },
    patientCareResources: (state) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientCareResourcesMap?.[patientId] || {};
    },
    patientSocialHistory: (state) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientSocialHistoryMap?.[patientId] || {};
    },
    patientScreening(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientScreeningMap?.[patientId] || {};
    },
    getPatientDataByGroup:
      (_, getters) => (group: IPatientFieldsMetadataGroup) => {
        switch (group) {
          case "PHI":
            return getters?.patient?.pii || {};
          case "Social History":
            return getters?.patientSocialHistory || {};
          case "Care and Resources":
            return getters?.patientCareResources || {};
        }
      },
    getPatientFields:
      (_, getters) =>
      (group: IPatientFieldsMetadataGroup, fieldsNames: string[]) => {
        const patientData = getters.getPatientDataByGroup(group);
        return fieldsNames.reduce((fields, fieldName) => {
          return { ...fields, [fieldName]: patientData[fieldName] || null };
        }, {});
      },
    getPatientFieldMetadata: (_, getters) => (field: string) => {
      if (!getters?.patientType?.rules) {
        return {};
      }

      // binary values handling
      return {
        enabled: !!(getters.patientType.rules[field] & 1),
        required: !!(getters.patientType.rules[field] & 2),
        ehr: !!(getters?.patientType.rules[field] & 4),
        ppEditable: !(getters?.patientType.rules[field] & 8),
      };
    },
  },
  mutations: {
    setPatientCareAndResources(
      state,
      data: { careResources: PatientCareResources; patientId: string },
    ) {
      state.patientCareResourcesMap[data.patientId] = data.careResources;
      state.patientCareResourcesMap = { ...state.patientCareResourcesMap };
    },
    setPatientSocialHistory(
      state,
      data: { socialHistory: PatientSocialHistory; patientId: string },
    ) {
      state.patientSocialHistoryMap[data.patientId] = data.socialHistory;
      state.patientSocialHistoryMap = { ...state.patientSocialHistoryMap };
    },
    setPatientType(state, data: { type: string; patientId: string }) {
      state.patientTypeMap[data.patientId] = data.type;
      state.patientTypeMap = { ...state.patientTypeMap };
    },
    setPatientTeams(
      state,
      data: { teams: PatientOrganization[]; patientId: string },
    ) {
      state.patientTeamsMap[data.patientId] = data.teams;
      state.patientTeamsMap = { ...state.patientTeamsMap };
    },
    setPatientTeamSettings(state, data: IPatientTeamSettings) {
      state.patientTeamSettings = data;
    },
    setPatientTabletStats(
      state,
      data: { stats: IPatientTabletStats[]; patientId: string },
    ) {
      state.patientTabletStatsMap[data.patientId] = data.stats;
      state.patientTabletStatsMap = { ...state.patientTabletStatsMap };
    },
    setPatientScreenings(
      state,
      data: { screenings: IPatientHealthLiteracyScreening; patientId: string },
    ) {
      state.patientScreeningMap[data.patientId] = data.screenings;
      state.patientScreeningMap = { ...state.patientScreeningMap };
    },
    resetProfileModule(state) {
      state.patientTabletStatsMap = {};
      state.patientTypeMap = {};
      state.patientTeamsMap = {};
      state.patientCareResourcesMap = {};
      state.patientSocialHistoryMap = {};
      state.patientScreeningMap = {};
      state.patientTeamSettings = null;
    },
  },
};

export default module;
