import $http from "@/services/rest.service";

const BASE_PATH = "/pp/orgs";
export const getAllRoles = async (
  teamRoles: boolean,
  orgId: string,
  projections?: string[],
) => {
  const projection = projections?.join();
  return $http.get(`${BASE_PATH}/${orgId}/roles`, {
    params: { teamRoles, projection },
  });
};
