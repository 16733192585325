import gql from "graphql-tag";

import {
  campaignAnswerFragment,
  campaignTemplateFragment,
} from "../fragments/campaign.fragment";
import {
  carePlanFragment,
  careResourcesFragment,
  medicalSummaryFragment,
  socialHistoryFragment,
} from "../fragments/patient.fragments";
import { resourceFragment } from "../fragments/resource.fragments";

export const GET_PATIENT_INITIAL_DATA = gql`
  query get_patient_initial_data($patient: ID!) {
    patient(id: $patient) {
      id
      timezone
      tags
      createdAt
      rawType
      currentState {
        isActivePatientState
      }
      resources {
        ...resource
      }
      providersInCall {
        id
        photo
        firstName
        lastName
      }
      messaging
      videoCalls
      duplicateName
    }
  }
  ${resourceFragment}
`;

export const GET_PATIENT_PROVIDERS_IN_CALL = gql`
  query get_patient_providers_in_call($patient: ID!) {
    patient(id: $patient) {
      providersInCall {
        id
        photo
        firstName
        lastName
      }
    }
  }
`;

export const GET_PATIENT_INITIAL_DATA_EVENTS = gql`
  query get_patient_initial_data_events($patient: ID!, $rootOrgId: String!) {
    patient(id: $patient) {
      timelineEventsJSON(orgId: $rootOrgId)
      timelineTasksJSON(orgId: $rootOrgId, includeCompleted: true)
      appointmentsJSON
    }
  }
`;

export const GET_PATIENT_INITIAL_DATA_VITALS = gql`
  query get_patient_initial_data_vitals(
    $patient: ID!
    $projections: [String!]!
    $startDate: String
    $endDate: String
  ) {
    patient(id: $patient) {
      surveyAnswers
      vitals(
        projections: $projections
        startDate: $startDate
        endDate: $endDate
      )
    }
  }
`;

export const GET_PATIENT_RESOURCES = gql`
  query get_patient_resources($patient: ID!) {
    patient(id: $patient) {
      resources {
        ...resource
      }
    }
  }
  ${resourceFragment}
`;

export const GET_PATIENT_CARE_PLAN_TAB = gql`
  query get_patient_care_plan_tab($patient: ID!) {
    patient(id: $patient) {
      carePlan {
        ...carePlan
      }
      medicalSummary {
        ...medicalSummary
      }
    }
  }
  ${carePlanFragment}
  ${medicalSummaryFragment}
`;

export const GET_PATIENT_DIAGNOSES = gql`
  query get_patient_diagnoses($patient: ID!) {
    patient(id: $patient) {
      medicalSummary {
        diagnoses
      }
    }
  }
`;

export const GET_PATIENT_PROFILE_TAB = gql`
  query get_patient_profile_tab($patient: ID!) {
    patient(id: $patient) {
      socialHistory {
        ...socialHistory
      }
      careResources {
        ...careResources
      }
      teams {
        id
        careTeamRoles {
          firstName
          lastName
          phoneNumber
          role {
            name
          }
        }
        root
        settings {
          physicianTeam
        }
      }
    }
  }
  ${socialHistoryFragment}
  ${careResourcesFragment}
`;

export const GET_PATIENT_EDU_MATERIALS = gql`
  query get_patient_edu_materials($patient: ID!) {
    patient(id: $patient) {
      id
      eduMaterials {
        id
      }
    }
  }
`;

export const GET_PATIENT_VITALS = gql`
  query get_patient_vitals(
    $patient: ID!
    $projections: [String!]!
    $startDate: String
    $endDate: String
  ) {
    patient(id: $patient) {
      id
      vitals(
        projections: $projections
        startDate: $startDate
        endDate: $endDate
      )
    }
  }
`;

export const GET_PATIENT_NOTIFICATIONS = gql`
  query get_patient_notifications($patient: ID!, $rootOrgId: ID!) {
    patient(id: $patient) {
      id
      notifications(orgId: $rootOrgId) {
        notifications {
          id
        }
        count
        type
        name
      }
    }
  }
`;

export const GET_PATIENT_EVENTS = gql`
  query get_patient_timeline_events(
    $patient: ID!
    $rootOrgIdString: String!
    $rootOrgId: ID!
  ) {
    patient(id: $patient) {
      id
      timelineEventsJSON(orgId: $rootOrgIdString)
      timelineTasksJSON(orgId: $rootOrgIdString, includeCompleted: true)
      appointmentsJSON
      reviewNotesDraft(organizationId: $rootOrgId) {
        rootOrganizationId
        notes
        noteId
        patientId
        providerId
        updatedAt
      }
    }
  }
`;

export const GET_PATIENT_ACTIVITY_LOGS = gql`
  query get_patient_activity_logs($patient: ID!) {
    patient(id: $patient) {
      id
      activityLogsJSON
    }
  }
`;

export const GET_PATIENT_PAGINATED_ACTIVITY_LOGS = gql`
  query get_patient_paginated_activity_logs(
    $patient: ID!
    $startKey: String
    $limit: Float
    $sort: String
    $excludeLogsOfType: [String!]
  ) {
    patient(id: $patient) {
      id
      paginatedActivityLogsJSON(
        startKey: $startKey
        limit: $limit
        sort: $sort
        excludeLogsOfType: $excludeLogsOfType
      )
    }
  }
`;

export const GET_PATIENT_ACTIVE_STATE = gql`
  query get_patient_active_state($patient: ID!) {
    patient(id: $patient) {
      id
      currentState {
        isActivePatientState
      }
    }
  }
`;

export const GET_PATIENT_MEDICAL_SUMMARY = gql`
  query get_patient_medical_summary($patient: ID!) {
    patient(id: $patient) {
      medicalSummary {
        ...medicalSummary
      }
    }
  }
  ${medicalSummaryFragment}
`;

export const GET_PATIENT_SOCIAL_HISTORY = gql`
  query get_patient_social_history($patient: ID!) {
    patient(id: $patient) {
      socialHistory {
        ...socialHistory
      }
    }
  }
  ${socialHistoryFragment}
`;

export const GET_PATIENT_CARE_PLAN = gql`
  query get_patient_care_plan($patient: ID!) {
    patient(id: $patient) {
      carePlan {
        ...carePlan
      }
    }
  }
  ${carePlanFragment}
`;

export const GET_PATIENT_SURVEYS = gql`
  query get_patient_surveys($patient: ID!) {
    patient(id: $patient) {
      campaigns {
        id
        createdAt
        deck
        name
        message
        firstOpenedAt
        lastOpenedAt
        lastCompletedAt
        provider
        responseViewedBy
        responseViewedAt
        ppRules {
          showPatientTimeline
          showLog
          showInSurveyList
        }
        lastAnswer {
          ...answer
        }
        template {
          ...template
        }
      }
    }
  }
  ${campaignAnswerFragment}
  ${campaignTemplateFragment}
`;

export const GET_PATIENT_PAGINATED_SURVEYS = gql`
  query get_patient_paginated_surveys(
    $patient: ID!
    $startKey: String
    $limit: Float
    $sort: String
  ) {
    patient(id: $patient) {
      paginatedCampaigns(startKey: $startKey, limit: $limit, sort: $sort) {
        id
        createdAt
        deck
        name
        message
        firstOpenedAt
        lastOpenedAt
        lastCompletedAt
        provider
        responseViewedBy
        responseViewedAt
        ppRules {
          showPatientTimeline
          showLog
          showInSurveyList
        }
        lastAnswer {
          ...answer
        }
        template {
          ...template
        }
      }
    }
  }
  ${campaignAnswerFragment}
  ${campaignTemplateFragment}
`;

export const GET_PATIENT_SURVEY = gql`
  query get_survey($campaign: ID!) {
    campaign(id: $campaign) {
      id
      createdAt
      deck
      name
      message
      firstOpenedAt
      lastOpenedAt
      lastCompletedAt
      provider
      responseViewedBy
      responseViewedAt
      ppRules {
        showPatientTimeline
        showLog
        showInSurveyList
      }
      lastAnswer {
        ...answer
      }
      template {
        ...template
      }
    }
  }
  ${campaignAnswerFragment}
  ${campaignTemplateFragment}
`;

export const GET_PATIENT_SURVEY_ANSWERS = gql`
  query get_patient_survey_answers($patient: ID!) {
    patient(id: $patient) {
      surveyAnswers
    }
  }
`;

export const GET_PATIENT_CARE_RESOURCES = gql`
  query get_patient_care_resources($patient: ID!) {
    patient(id: $patient) {
      careResources {
        ...careResources
      }
    }
  }
  ${careResourcesFragment}
`;

export const GET_PATIENT_REVIEW_NOTES = gql`
  query get_patient_review_notes($patientId: ID!, $orgId: ID!) {
    patient(id: $patientId) {
      id
      lockedReviewNotes(organizationId: $orgId) {
        rootOrganizationId
        noteId
        patientId
        providerId
      }
      reviewNotesDraft(organizationId: $orgId) {
        rootOrganizationId
        notes
        noteId
        patientId
        providerId
        updatedAt
      }
    }
  }
`;

export const GET_PATIENT_ASSIGNED_PROVIDERS = gql`
  query get_patient_assigned_providers($patient: ID!) {
    patient(id: $patient) {
      id
      providerAssignments {
        organization
        provider
      }
      teams {
        id
        name
        roleAssignments {
          role
          provider
        }
      }
    }
  }
`;
