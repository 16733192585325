import moment from "moment-timezone";
import Vue from "vue";
import VueMoment from "vue-moment";

declare module "vue/types/vue" {
  interface Vue {
    $moment: typeof moment;
  }
}

Vue.use(VueMoment, { moment });
