import {
  CHART_30_DAY_RANGE,
  CHART_START_RANGE,
} from "@/factories/chart-factory";
import { Module } from "vuex";

import { IChartModuleState, IRootState } from "../../../@types/store";

const initialState: IChartModuleState = {
  dateRange: CHART_START_RANGE,
  endDate: "",
  startDate: "",
};

const module: Module<IChartModuleState, IRootState> = {
  state: initialState,

  actions: {
    updateStartDate({ commit }, date: string) {
      commit("setStartDate", date);
    },
    updateEndDate({ commit }, date: string) {
      commit("setEndDate", date);
    },
    updateDateRange({ commit }, range: number) {
      commit("setDateRange", range);
    },
  },
  getters: {
    endDate(state) {
      return state.endDate;
    },

    startDate(state) {
      return state.startDate;
    },

    dateRange(state) {
      return state.dateRange;
    },
    isGranularEnabled(_, rootGetters) {
      return rootGetters?.chartDisplayConfigs?.enable_charts_granularity;
    },
    showGranular(state, rootGetters) {
      return (
        rootGetters?.chartDisplayConfigs?.enable_charts_granularity &&
        state.dateRange <= CHART_30_DAY_RANGE
      );
    },
    showSunriseSunset(state, rootGetters) {
      return (
        rootGetters?.enableSolarEvents && state.dateRange <= CHART_START_RANGE
      );
    },
    enableSpreadCharts(_, rootGetters) {
      return rootGetters?.chartDisplayConfigs?.enabled_spread_charts;
    },
    enableSolarEvents(_, rootGetters) {
      return (
        rootGetters?.chartDisplayConfigs?.enable_charts_granularity &&
        rootGetters?.chartDisplayConfigs?.show_sunset_sunrise_areas
      );
    },
  },
  mutations: {
    setStartDate(state, date: string) {
      state.startDate = date;
    },
    setEndDate(state, date: string) {
      state.endDate = date;
    },
    setDateRange(state, position: number) {
      state.dateRange = position;
    },
    resetChartModule(state) {
      state.dateRange = CHART_START_RANGE;
      state.endDate = "";
      state.startDate = "";
    },
  },
};

export default module;
