
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Quill, { QuillOptionsStatic } from "quill";
import { debounce } from "debounce";

@Component
export default class MyiaEditor extends Vue {
  @Prop({ type: String, default: "" }) private value!: string;
  @Prop({ type: String, default: "" }) private defaultValue!: string;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({
    type: Object,
    default: () => ({
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
        ],
      },
      theme: "snow",
      formats: ["bold", "underline", "italic", "color", "background", "list"],
    }),
  })
  private quillOptions!: QuillOptionsStatic;

  private editor: Quill | null = null;
  private localValue: string = "";

  @Watch("defaultValue")
  private setDefaultValue() {
    if (this.editor != null) {
      this.editor.setText("");
      this.editor.pasteHTML(0, this.defaultValue);
    }
  }

  @Watch("value")
  private setValue(newVal: string) {
    if (this.editor != null) {
      const contentLength = this.editor.getLength();
      if (newVal && contentLength === 1) {
        this.editor.setText("");
        this.editor.pasteHTML(0, newVal);
      }
    }
  }

  @Watch("disabled")
  private toggleDisableState() {
    if (this.editor != null) {
      this.editor.enable(!this.disabled);
    }
  }

  private mounted() {
    const editorInstance = this.$refs.editor as HTMLElement;
    this.editor = new Quill(editorInstance, this.quillOptions);
    this.editor.root.innerHTML = this.value;
    this.editor.enable(!this.disabled);
    // We will add the update event here
    this.editor.on(
      "text-change",
      debounce(() => this.update(), 400),
    );
  }

  private update() {
    if (this.editor != null) {
      this.$emit(
        "input",
        this.editor.getText() ? this.editor.root.innerHTML : "",
      );
    }
  }
}
