
import { Component, Prop, Vue } from "vue-property-decorator";

interface DropdownGroup {
  title?: string;
  items: string[];
}

@Component
export default class MyiaInputGroupSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
  private itemGroups!: DropdownGroup[];
  @Prop({ type: Boolean, default: () => false }) private disabled!: boolean;
  @Prop(String) private defaultValue!: string;
  @Prop(Boolean) private isReset!: boolean;
  @Prop(String) private position!: string;
  @Prop(Boolean) private listGrow!: boolean;
  @Prop({ type: String, default: "100%" }) private width!: string;
  private isOpen: boolean = false;
  private selectedValue: string = "";

  private onSelect(value: string) {
    this.isOpen = false;
    this.selectedValue = value;
    this.$emit("change", value);
  }

  private get isLeft() {
    return this.position === "left";
  }

  private get isRight() {
    return this.position === "right";
  }

  private get listWidth() {
    // If select-box is small and list items don't fit add listGrow prop
    if (this.listGrow) {
      return "100%";
    }
    const input = this.$refs.selectInput as HTMLElement;
    return input ? input.offsetWidth + "px" : "185px";
  }

  private toggleOptions() {
    if (this.disabled) {
      return;
    }
    this.isOpen = !this.isOpen;
  }
}
