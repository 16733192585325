import { render, staticRenderFns } from "./ModalTemplate.vue?vue&type=template&id=9024bbe8&scoped=true&lang=pug&"
import script from "./ModalTemplate.vue?vue&type=script&lang=ts&"
export * from "./ModalTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./ModalTemplate.vue?vue&type=style&index=0&id=9024bbe8&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9024bbe8",
  null
  
)

export default component.exports