import { getCurrentMonth } from "@/factories/date-factory";
import $http from "@/services/rest.service";
import { IPatientTeamSettings } from "../../@types/store";

import { ICampaign } from "../../@types/organization";
import {
  IChecklistItem,
  IDailyVitalsRemovals,
  IMedication,
  IOrgPatientWatchlist,
  IPatientHealthLiteracyScreening,
  IPatientPii,
  IPatientReviewChecklist,
  IPatientReviewNoteDraft,
  IPatientSupportEvent,
  IPatientTarget,
  IPatientTraceRemoval,
  ISupportEvent,
  IPatientNotificationState,
} from "../../@types/patient";
import {
  IFeedbackInput,
  ITimelineEventUniqueKey,
} from "../../@types/patient-timeline-templates";

const BASE_PATH = "/pp/orgs";
const REPORTS_PATH = "/pp-reports";

const PATIENTS_PATH = "patients";
const PATIENTS_PIIS_PATH = `${PATIENTS_PATH}-piis`;
const PATIENTS_STATS_PATH = `vitals`;

const DEFAULT_PII_PROJECTIONS = [
  "name",
  "firstName",
  "middleName",
  "lastName",
  "identifiers",
  "preferredName",
  "namePronunciation",
  "title",
  "suffix",
  "primaryContact",
  "primaryLanguage",
  "race",
  "ethnicity",
  "email",
  "phoneNumber",
  "actualPhoneNumber",
  "homePhone",
  "workPhone",
  "otherPhone",
  "primaryNumber",
  "cellProvider",
  "mailingAddress1",
  "mailingAddress2",
  "city",
  "mailingCity",
  "state",
  "mailingState",
  "zip",
  "mailingZip",
  "maritalStatus",
  "sex",
  "gender",
  "dob",
  "deathDate",
  "photo",
  "location",
  "religion",
  "timezone",
  "address1",
  "address2",
  "otherAddresses",
  "createdAt",
];

export const getAllPatientTypes = async (
  orgId: string,
  projections?: string[],
) => {
  const projection = projections?.join();
  return $http.get(`${BASE_PATH}/${orgId}/patient-types`, {
    params: { projection },
  });
};

export const getPatientTeamSettings = (
  orgId: string,
  patientId: string,
  team?: string,
) => {
  const params = team && { team };

  return $http.get<IPatientTeamSettings>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/team-settings`,
    { params },
  );
};

export const getPatientTabletStats = (orgId: string, patientId: string) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/tablet-stats`,
  );
};

export const getPatientScreenings = (orgId: string, patientId: string) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/screenings`,
  );
};

export const updateOrgPatientScreenings = (
  orgId: string,
  patientId: string,
  screening: IPatientHealthLiteracyScreening,
) =>
  $http.put<IPatientPii>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/screenings`,
    screening,
  );

export const updatePatientReviewNotesDraft = (
  orgId: string,
  patientId: string,
  noteDraft: IPatientReviewNoteDraft,
) =>
  $http.post(`${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/note-draft`, {
    noteDraft,
  });

export const updatePatientNotificationState = (
  orgId: string,
  patientId: string,
  state: IPatientNotificationState,
  notificationIds: string[],
) =>
  $http.post(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/notification-state`,
    {
      notificationIds,
      state,
    },
  );

export const getPatientReportStats = (
  patientId: string,
  token: string,
  month?: string | string[],
  startDate?: string,
  endDate?: string,
) => {
  const projections = `weight,avgHr,avgRr,durationInSleep,durationInBed,pulseRate,systolicBp,diastolicBp,o2`;
  const params =
    startDate != null && endDate != null
      ? {
          token,
          endDate,
          projections,
          startDate,
        }
      : {
          token,
          month: month || getCurrentMonth(),
          projections,
        };
  return $http.get(`${REPORTS_PATH}/${PATIENTS_PATH}/${patientId}/vitals`, {
    params,
  });
};

export const getPatientReviewChecklists = (orgId: string, patientId: string) =>
  $http.get<IPatientReviewChecklist[]>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/review-checklists`,
  );

export const updateReviewMedicalChecklist = (
  orgId: string,
  patientId: string,
  checklistId: string,
  item: IChecklistItem,
) =>
  $http.put<IPatientReviewChecklist[]>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/medical-review-checklists/${checklistId}`,
    item,
  );

export const updateReviewNonMedicalChecklist = (
  orgId: string,
  patientId: string,
  checklistId: string,
  item: IChecklistItem,
) =>
  $http.put<IPatientReviewChecklist[]>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/non-medical-review-checklists/${checklistId}`,
    item,
  );

export const deletePatientVital = (
  orgId: string,
  patientId: string,
  body: IDailyVitalsRemovals,
) =>
  $http.delete(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/${PATIENTS_STATS_PATH}`,
    { data: body },
  );

export const undoVitalDeleting = (
  orgId: string,
  patientId: string,
  body: IDailyVitalsRemovals,
) =>
  $http.delete(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/${PATIENTS_STATS_PATH}-removals`,
    {
      data: body,
    },
  );

export const getHistogramData = (
  orgId: string,
  patientId: string,
  date: string,
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/histograms`,
    {
      params: {
        date,
      },
    },
  );
};

export const getMinuteLevelData = (
  orgId: string,
  patientId: string,
  params: any,
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/hour-vitals`,
    {
      params,
    },
  );
};

export const getTracesData = (
  orgId: string,
  patientId: string,
  date: string,
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/traces`,
    {
      params: {
        date,
      },
    },
  );
};

export const getRectangleData = (
  orgId: string,
  patientId: string,
  startDate: string,
  endDate: string,
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/rectangles`,
    {
      params: {
        endDate,
        startDate,
      },
    },
  );
};

export const getPatientActivity = (
  patientId: string,
  token: string,
  month?: string | string[],
  startDate?: string,
  endDate?: string,
) => {
  const params =
    startDate != null && endDate != null
      ? {
          endDate,
          startDate,
          token,
        }
      : {
          token,
          month: month || getCurrentMonth(),
        };
  return $http.get(
    `${REPORTS_PATH}/${PATIENTS_PATH}/${patientId}/emoji-answers`,
    { params },
  );
};

export const getPatientReportTargets = (patientId: string, token: string) => {
  return $http.get(`${REPORTS_PATH}/${PATIENTS_PATH}/${patientId}/targets`, {
    params: { token },
  });
};

export const getPatientTargets = (orgId: string, patientId: string) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/targets`,
  );
};

export const getPatientsResources = (orgId: string) => {
  return $http.get(`${BASE_PATH}/${orgId}/patients-resources`);
};

export const getPatientResourceStatuses = (
  orgId: string,
  patientId: string,
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/resource-statuses`,
  );
};

export const getPatientListItemEvents = (orgId: string, patientId: string) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/list-events`,
  );
};

export const getPatientWatchlistsHistory = (
  orgId: string,
  patientId: string,
  params: Partial<IOrgPatientWatchlist>,
  projections: string[],
) => {
  return $http.get<IOrgPatientWatchlist[]>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/watchlists-history`,
    {
      params: { projections: projections.join(","), ...params },
    },
  );
};

export const getPatientWatchlists = (
  orgId: string,
  patientId: string,
  projections: string[],
) => {
  return $http.get<IOrgPatientWatchlist[]>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/watchlists`,
    {
      params: { projections: projections.join(",") },
    },
  );
};

export const createPatientWatchlist = (
  orgId: string,
  patientId: string,
  watchlist: Partial<IOrgPatientWatchlist>,
) => {
  return $http.post<Boolean>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/watchlists`,
    watchlist,
  );
};

export const editPatientWatchlist = (
  orgId: string,
  patientId: string,
  watchlist: Partial<IOrgPatientWatchlist>,
) => {
  return $http.put<Boolean>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/watchlists`,
    watchlist,
  );
};

export const deletePatientWatchlist = (
  orgId: string,
  patientId: string,
  watchlist: Partial<IOrgPatientWatchlist>,
) =>
  $http.delete<Boolean>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/watchlists`,
    { data: watchlist },
  );

export const getTimelineEventFeedbacks = (
  orgId: string,
  patientId: string,
  projections: string[],
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/feedbacks`,
    {
      params: { projections: projections.join(","), includeDeleted: true },
    },
  );
};

export const createTimelineEventFeedbacks = (
  orgId: string,
  patientId: string,
  feedback: IFeedbackInput,
  fields: string[],
) => {
  return $http.post(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/feedbacks`,
    { fields, feedback },
  );
};

export const deleteTimelineEventFeedbacks = (
  orgId: string,
  patientId: string,
  events: ITimelineEventUniqueKey[],
) =>
  $http.delete(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/feedbacks`,
    { data: events },
  );

export const createPatientTarget = (
  orgId: string,
  patientId: string,
  target: IPatientTarget,
) => {
  return $http.post(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/targets`,
    target,
  );
};

export const editPatientTarget = (
  orgId: string,
  patientId: string,
  target: IPatientTarget,
) => {
  return $http.put(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/targets/${target.id}`,
    target,
  );
};

export const deletePatientTarget = (
  orgId: string,
  patientId: string,
  targetId: string,
) => {
  return $http.delete(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/targets/${targetId}`,
  );
};

export const undoTargetDelete = (
  orgId: string,
  patientId: string,
  targetId: string,
) => {
  return $http.put(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/targets/${targetId}/undo-delete`,
  );
};

export const deleteRestPeriods = (
  orgId: string,
  patientId: string,
  removalsTrace: IPatientTraceRemoval,
) =>
  $http.delete<ICampaign[]>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/traces`,
    { data: removalsTrace },
  );
export const undoRestPeriods = (
  orgId: string,
  patientId: string,
  removalsTrace: IPatientTraceRemoval,
) =>
  $http.delete<ICampaign[]>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/traces-removals`,
    { data: removalsTrace },
  );

export const videoUrl = (orgId: string, patientId: string) =>
  $http.get(`${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/video-data`);

export const updatePatientFromEhr = (
  orgId: string,
  patientId: string,
  fields: string,
) => {
  return $http.post(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/update-from-ehr`,
    { fields },
  );
};

export const getPatientUploadUrl = (
  orgId: string,
  patientId: string,
  fileName: string,
  contentType: string,
) => {
  const params = {
    contentType,
    fileName,
  };
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PIIS_PATH}/${patientId}/photo-upload-url`,
    { params },
  );
};

export const getPatientPiis = (
  orgId: string,
  patientId: string,
  projections = DEFAULT_PII_PROJECTIONS,
) => {
  return $http.get(`${BASE_PATH}/${orgId}/${PATIENTS_PIIS_PATH}/${patientId}`, {
    params: { projections: projections.join(",") },
  });
};

export const createSupportEvent = (
  orgId: string,
  patientId: string,
  ticket: Partial<IPatientSupportEvent>,
) =>
  $http.post<ISupportEvent>(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/support`,
    ticket,
  );

export const getPatientSupportTickets = (orgId: string, patientId: string) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/support-tickets`,
  );
};

export const getPatientSupportEvents = (
  orgId: string,
  patientId: string,
  projections: string[],
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/support-events`,
    {
      params: { projections: projections.join(",") },
    },
  );
};

export const markSupportTicketResolved = (
  orgId: string,
  patientId: string,
  ticketId: string,
) => {
  return $http.put(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/support-tickets/${ticketId}`,
  );
};

export const updatePatientProfile = (
  orgId: string,
  patientId: string,
  patient: IPatientPii,
) => {
  return $http.put(
    `${BASE_PATH}/${orgId}/${PATIENTS_PIIS_PATH}/${patientId}`,
    patient,
  );
};

export const getPatientLatestVitals = (
  orgId: string,
  patientId: string,
  projections: string[],
) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/latest-vitals`,
    {
      params: { projections: projections.join(",") },
    },
  );
};

export const getPatientMedications = (orgId: string, patientId: string) => {
  return $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/medications`,
  );
};

export const createPatientMedication = (
  orgId: string,
  patientId: string,
  medication: IMedication,
) => {
  return $http.post(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/medications`,
    medication,
  );
};

export const updatePatientMedication = (
  orgId: string,
  patientId: string,
  medication: IMedication,
) => {
  return $http.put(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/medications`,
    medication,
  );
};

export const deletePatientMedication = (
  orgId: string,
  patientId: string,
  pk: string,
  sk: string,
) => {
  return $http.delete(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/medications`,
    { data: { pk, sk } },
  );
};
