import moment from "moment";

import { IChartConfig } from "../../@types/chart";
import { IPatientLocation, IPatientStats } from "../../@types/patient";
import { IRestPeriods } from "../../@types/provider";
import {
  DATE_FORMAT_YYYY_MM_DD,
  TIME_FORMAT_HA,
  TIME_FORMAT_H_MMA,
} from "@/configs/constants";
import {
  MYIA_ORANGE_400,
  LIME_400,
  PURPLE_500,
  TEAL_400,
} from "@/configs/colors";
import { getGranularChartTimeline } from "./date-factory";
import { getSunrise, getSunset } from "sunrise-sunset-js";

export const CHART_START_RANGE = 14;
export const CHART_30_DAY_RANGE = 30;

export const CHART_COLORS = {
  aquamarine: "#00B4A8",
  blue: "#3576E5",
  darkBlue: "#3138A3",
  darkGreen: "#305E72",
  darkOrange: "#DE7312",
  darkRed: "#960909",
  gray: "#9B9B9B",
  green: "#0F8759",
  inkBlue: "#7869D9",
  lightBlue: "#5EC3E3",
  lightOrange: "#FF9E47",
  lightPurple: "#BD38B8",
  orange: "#F95C3D",
  purple: "#B000B4",
  red: "#E02C3D",
  white: "#ffffff",
};

export const CHART_CONFIGS: IChartConfig = {
  emojis: {
    color: CHART_COLORS.lightPurple,
    shape: "circle",
    smooth: false,
    type: "dotted",
  },
  diastolicBp: {
    color: CHART_COLORS.darkGreen,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  glucoseFasting: {
    color: CHART_COLORS.lightPurple,
    shape: "circle",
    smooth: false,
    type: "solid",
  },
  glucosePostMeal: {
    color: CHART_COLORS.lightOrange,
    shape: "triangle",
    smooth: false,
    type: "solid",
  },
  glucosePreMeal: {
    color: CHART_COLORS.blue,
    shape: "rect",
    smooth: false,
    type: "solid",
  },
  hr: {
    color: CHART_COLORS.green,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  inBed: {
    color: CHART_COLORS.lightOrange,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  dailyBreathing: {
    color: CHART_COLORS.lightPurple,
    shape: "circle",
    smooth: true,
    type: "solid",
    multiplePoints: true,
  },
  dailySymptoms: {
    color: CHART_COLORS.lightPurple,
    shape: "circle",
    smooth: true,
    type: "solid",
    multiplePoints: true,
  },
  dailySwelling: {
    color: CHART_COLORS.lightPurple,
    shape: "circle",
    smooth: true,
    type: "dotted",
    multiplePoints: true,
  },
  o2: {
    color: CHART_COLORS.inkBlue,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  pulseRate: {
    color: CHART_COLORS.darkOrange,
    shape: "circle",
    smooth: false,
    type: "solid",
  },
  rr: {
    color: CHART_COLORS.darkRed,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  sbp: {
    color: CHART_COLORS.aquamarine,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  sleep: {
    color: CHART_COLORS.orange,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  walk: {
    color: CHART_COLORS.darkOrange,
    shape: "circle",
    smooth: true,
    type: "solid",
  },
  weight: {
    color: CHART_COLORS.lightBlue,
    shape: "circle",
    smooth: false,
    type: "solid",
  },
  medianRestHr: {
    color: TEAL_400,
    shape: "circle",
    smooth: false,
    type: "solid",
  },
  medianTemperature: {
    color: MYIA_ORANGE_400,
    shape: "circle",
    smooth: false,
    type: "solid",
  },
  temperature: {
    color: LIME_400,
    shape: "circle",
    smooth: false,
    type: "solid",
  },
  medianRestRr: {
    color: PURPLE_500,
    shape: "circle",
    smooth: false,
    type: "solid",
  },
};

export const chartConfigs: any = {
  emojis: {
    title: "Ability to Perform Daily Activities",
  },
  hr: {
    title: "Heart Rate",
  },
  rr: {
    title: "Breathing (Respiratory Rate)",
  },
  sbp: {
    title: "Systolic Blood Pressure",
  },
  sleep: {
    title: "Sleep",
  },
  weight: {
    title: "Weight",
  },
};

export const getIntraDayNameByType = (type: string) => {
  switch (type) {
    case "hr":
      return "Heart Rate";
    case "rr":
      return "Respiratory Rate";
    case "sbp":
      return "Systolic Blood Pressure";
    case "weight":
      return "Weight";
    case "durationInBed":
      return "Sleep";
    default:
      return "";
  }
};

export const getChartName = (type: string) => {
  if (!type) {
    return null;
  }
  return chartConfigs[type].title || "";
};

export const getGranularTimeListForCharts = (
  timeline: string[],
  endDate?: string,
) => {
  const start = timeline?.[0]?.split("T")[0];
  const updatedTimeline: string[] = getTimeListForCharts([start], "", endDate);
  return getGranularChartTimeline(updatedTimeline);
};

export const getTimeListForCharts = (
  timeline: string[],
  month?: string | string[],
  endDate?: string,
) => {
  let newTimeLine: string[] = [];
  if (!timeline.length && month) {
    timeline.push(month + "-01");
  }

  if (!timeline.length && !month) {
    const end = new Date();
    const lessDaysCountFromSixMonth = 10;
    const sixMonths = 6 * (30 * 24 - lessDaysCountFromSixMonth) * 3600 * 1000;
    const start = new Date(end.getTime() - sixMonths);
    timeline.push(moment.utc(start).format(DATE_FORMAT_YYYY_MM_DD));
  }

  if (timeline.length && timeline.length < 14 && !month) {
    timeline.sort();
    const sortedTimelineByAsc = [...timeline];
    const start = new Date(sortedTimelineByAsc[0]).getTime();
    const twoWeeksAgo = 14 * 24 * 3600 * 1000;
    const newStartDate = Date.now() - twoWeeksAgo;
    if (
      start > twoWeeksAgo &&
      moment.utc(start).format(DATE_FORMAT_YYYY_MM_DD) !==
        moment.utc(newStartDate).format(DATE_FORMAT_YYYY_MM_DD)
    ) {
      timeline.push(moment.utc(newStartDate).format(DATE_FORMAT_YYYY_MM_DD));
    }
  }

  if (timeline.length) {
    const monthList: any[] = [];
    timeline.sort();
    const sortedTimelineByAsc = [...timeline];
    const end = month
      ? new Date(timeline[timeline.length - 1])
      : endDate
      ? new Date(endDate)
      : new Date();
    const start = new Date(sortedTimelineByAsc[0]);
    const endMonth = end.getUTCMonth() + 1;
    let startMonth = start.getUTCMonth() + 1;
    let startYear = start.getUTCFullYear();
    let count = 0;
    if (startYear !== end.getUTCFullYear()) {
      count = 12 * (end.getUTCFullYear() - startYear);
    }
    count +=
      startMonth > endMonth
        ? 12 - startMonth + endMonth + 1
        : endMonth - startMonth + 1;
    for (let i = 0; i < count; i++) {
      if (startMonth > 12) {
        startMonth = 1;
        startYear++;
      }
      monthList.push({ month: startMonth, year: startYear });
      startMonth++;
    }
    for (const d of monthList) {
      newTimeLine = newTimeLine.concat(getDaysPerMonth(d.month, d.year));
    }
    newTimeLine = month
      ? newTimeLine
      : removeEmptyDateMargins(newTimeLine, start, end);
  }
  return newTimeLine;
};

const removeEmptyDateMargins = (timeline: string[], start: Date, end: Date) => {
  const startDate = moment.utc(start).format(DATE_FORMAT_YYYY_MM_DD);
  const endDate = moment.utc(end).format(DATE_FORMAT_YYYY_MM_DD);
  let startDateIndex = timeline.indexOf(startDate);
  if (startDateIndex < 0) startDateIndex = 0;

  let endDateIndex = timeline.indexOf(endDate);
  if (endDateIndex < 0) endDateIndex = timeline.length - 1;

  timeline = timeline.splice(startDateIndex, endDateIndex - startDateIndex + 1);
  return timeline;
};

export const getStartDate = (stats: IPatientStats) => {
  if ((stats.timestamp?.length || 0) > CHART_START_RANGE) {
    return 100 - (100 * CHART_START_RANGE) / (stats.timestamp?.length || 1);
  }
  return 0;
};

export const getValuesForCharts = (
  timeline: string[],
  oldTimeLine: string[],
  values: (number | null)[],
) => {
  return timeline.map((date) => {
    const index = oldTimeLine.indexOf(date);
    if (index > -1 && values[index] != null) {
      return values[index];
    }
    return null;
  });
};

export const getMinMaxValue = (values: (number | null)[], max: boolean) => {
  if (values.length === 0) {
    return 0;
  }
  return values.reduce((p, c) => {
    if (p == null) {
      return c;
    }
    if (c == null) {
      return p;
    }
    return max ? Math.max(p, c) : Math.min(p, c);
  });
};

const getDaysPerMonth = (month: number, year: number) => {
  const daysCount = new Date(year, month, 0).getDate();
  const monthFull = month > 9 ? month.toString() : "0" + month;
  const days: string[] = [];
  for (let i = 1; i <= daysCount; i++) {
    days.push(`${year}-${monthFull}-${i > 9 ? i : "0" + i}`);
  }
  return days;
};

export const getEmptyData = (stats: IPatientStats) => {
  if (stats?.timestamp == null) {
    return [];
  }
  return new Array(stats.timestamp.length).fill(0);
};

export const getCuttingDataPositionIndex = (values: (number | null)[]) => {
  const valuesIndex: number[] = [];
  values.forEach((v, index) => {
    if (
      (v == null && values[index + 1] != null) ||
      (v != null && values[index + 1] == null)
    ) {
      valuesIndex.push(index);
    }
  });
  if (valuesIndex.length % 2 !== 0) {
    return [0, ...valuesIndex];
  }
  return valuesIndex;
};
export const getTime = (date: number, minutes?: boolean) => {
  return minutes
    ? moment(date * 1000).format(TIME_FORMAT_H_MMA)
    : moment(date * 1000).format(TIME_FORMAT_HA);
};

export const getRestPeriods = (
  values: (number | null)[],
  timestamp: number[],
) => {
  const positionIndex = getCuttingDataPositionIndex(values);
  const restPeriods: IRestPeriods[] = [];
  let data = [];
  for (let i = 0; i < positionIndex.length; i += 2) {
    data = values.filter(
      (v, index) =>
        index >= positionIndex[i] && index <= positionIndex[i + 1] && v != null,
    );
    const avg = data.reduce((v, b) => (v || 0) + (b || 0));
    restPeriods.push({
      avg: ((avg || 0) / data.filter((a) => a).length).toFixed(1),
      date:
        getTime(timestamp[positionIndex[i]], true) +
        " - " +
        getTime(timestamp[positionIndex[i + 1]], true),
      from: timestamp[positionIndex[i]],
      high: (getMinMaxValue(data, true) || 0).toFixed(1),
      low: (getMinMaxValue(data, false) || 0).toFixed(1),
      to: timestamp[positionIndex[i + 1]],
    });
  }
  return restPeriods;
};

export const getTimelineForSelectedRange = (
  start: string,
  dayCount: number,
) => {
  const startDate = moment(start);
  const days: string[] = [];
  days.push(startDate.format(DATE_FORMAT_YYYY_MM_DD));
  for (let i = 1; i < dayCount; i++) {
    days.push(startDate.add(1, "day").format(DATE_FORMAT_YYYY_MM_DD));
  }
  return days;
};

export const getSolarEventsForPatient = (
  location: IPatientLocation,
  days: string[],
) => {
  const longitude = location.longitude;
  const latitude = location.latitude;
  const areaCoordinates = days.map((day, i) => {
    const nextDay = days[i + 1];
    const sunsetTime = getSunset(latitude, longitude, new Date(day)).getHours();
    const sunriseTime = getSunrise(
      latitude,
      longitude,
      new Date(nextDay),
    ).getHours();
    return [
      {
        xAxis: `${day}T${sunsetTime < 10 ? "0" : ""}${sunsetTime}`,
      },
      {
        xAxis: `${nextDay}T${sunriseTime < 10 ? "0" : ""}${sunriseTime}`,
      },
    ];
  });
  return areaCoordinates.slice(0, -1);
};
