import logger from "@/logger";
import { Module } from "vuex";

import { IRootState, IZendeskModuleState } from "../../../@types/store";
import {
  getZendeskArticle,
  getZendeskArticles,
} from "@/services/organization.service";
import { IZendeskArticle } from "../../../@types/organization";

const initialState: IZendeskModuleState = {
  zendeskArticles: [],
};

const module: Module<IZendeskModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getZendeskArticles({ commit, rootGetters }) {
      try {
        const { data } = await getZendeskArticles(
          rootGetters.rootOrganization.id,
        );
        commit("setZendeskArticles", data);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getZendeskArticle({ commit, rootGetters }, id: string) {
      try {
        const { data } = await getZendeskArticle(
          rootGetters.rootOrganization.id,
          id,
        );
        commit("updateZendeskArticles", data?.zendeskArticle);
      } catch (error) {
        logger.error(error as Error);
      }
    },
  },
  getters: {
    zendeskArticles(state) {
      return state.zendeskArticles;
    },
  },
  mutations: {
    setZendeskArticles(state, articles: IZendeskArticle[]) {
      state.zendeskArticles = articles;
    },
    updateZendeskArticles(state, article: IZendeskArticle) {
      if (state.zendeskArticles == null) {
        return;
      }
      state.zendeskArticles = state.zendeskArticles.map((za) =>
        za.id === article.id
          ? {
              ...za,
              ...article,
            }
          : za,
      );
    },
  },
};

export default module;
