
import { Component, Prop, Vue } from "vue-property-decorator";
import { IConfirmModalOptions } from "../../@types/patient";

@Component
export default class ConfirmModal extends Vue {
  @Prop(Object) private options!: IConfirmModalOptions;
  private confirm() {
    this.options.confirmHandler();
    this.options?.closeHandler();
  }
  private cancel() {
    this.options.cancelHandler();
    this.options?.closeHandler();
  }
}
