import logger from "@/logger";
import Vue from "vue";
import { Module } from "vuex";

import { IRootState, IWSModuleState } from "../../../@types/store";

const initialState: IWSModuleState = {
  socket: {
    connectionId: undefined,
    isConnected: false,
    message: "",
    reconnectError: false,
  },
};

const module: Module<IWSModuleState, IRootState> = {
  state: initialState,

  actions: {
    sendWSMessage(_, message) {
      Vue.prototype.$socket.send(
        JSON.stringify({ message, action: "message" }),
      );
    },
    pingWS() {
      Vue.prototype.$socket.send(JSON.stringify({ action: "ping" }));
    },
  },
  getters: {
    connectionId(state) {
      return state.socket.connectionId;
    },
    wsMessage(state) {
      return state.socket.message;
    },
    socketIsConnected(state) {
      return state.socket.isConnected;
    },
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      logger.error(state, event);
    },
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
    },
    SOCKET_RECONNECT(state, count) {
      logger.info("Reconnect", state, count);
      state.socket.isConnected = false;
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    setSocketConnectionId(state, connectionId: string) {
      state.socket.connectionId = connectionId;
    },
  },
};

export default module;
