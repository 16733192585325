
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModalTemplate extends Vue {
  @Prop(Function) private closeModal!: () => void;
  @Prop(String) private width!: string;
  @Prop(String) private height!: string;
  @Prop(String) private maxHeight!: string;
  @Prop({ default: true, type: Boolean }) private showClose!: boolean;
  @Prop({ default: 0, type: Number }) private overlayOpacity!: number;
}
