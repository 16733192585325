
import mime from "mime";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class MyiaMedia extends Vue {
  @Prop(String) private src!: string;
  @Prop(String) private maxWidth!: string;
  @Prop({ type: String, default: "100%" }) private width!: string;
  @Prop({ type: String, default: "auto" }) private height!: string;
  private attachmentIsOfType(url: string, type: "image" | "audio" | "video") {
    const contentType = mime.getType(url.split("?")?.[0] || "");
    return contentType && contentType.split("/")?.[0] === type;
  }
}
