import $http from "@/services/rest.service";
import { IProvider, ProviderMe } from "../../@types/provider";
import { IPatientLockedReviewNoteInput } from "../../@types/patient";

export const getProviderMe = () => $http.get<ProviderMe>("/pp/me");

export const getProvidersList = (
  orgId: string,
  ids: string[],
  projections?: string[],
) =>
  $http.post<IProvider[]>(`/pp/orgs/${orgId}/get-providers-list`, {
    ids,
    projections,
  });

export const lockReviewNote = (
  orgId: string,
  reviewNote: IPatientLockedReviewNoteInput,
) =>
  $http.post(`/pp/orgs/${orgId}/lock-review-note`, {
    reviewNote,
  });

export const unlockReviewNote = (
  orgId: string,
  reviewNote: IPatientLockedReviewNoteInput,
) =>
  $http.post(`/pp/orgs/${orgId}/unlock-review-note`, {
    reviewNote,
  });
