import Vue from "vue";

Vue.use({
  install(VueLoading) {
    const uid = "vue-loading" + Date.now().toString(16);
    VueLoading.directive("loading", {
      inserted(el, binding) {
        const loadingScreen = document.createElement("span");
        loadingScreen.id = uid;
        loadingScreen.innerHTML = `<div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>`;
        loadingScreen.style.background = "rgba(255, 255, 255, 0.5)";
        loadingScreen.style.zIndex = "144";
        loadingScreen.style.bottom = "0px";
        loadingScreen.style.top = "0px";
        loadingScreen.style.left = "0px";
        loadingScreen.style.right = "0px";
        loadingScreen.style.position = "absolute";
        loadingScreen.style.pointerEvents = "";
        loadingScreen.style.cursor = "default";
        loadingScreen.style.display = binding.value ? "block" : "none";
        el.appendChild(loadingScreen);
      },
      update(_, binding) {
        const loadingScreen = document.getElementById(uid);
        if (loadingScreen != null) {
          loadingScreen.style.display = binding.value ? "block" : "none";
        }
      },
    });
  },
});
