import { Module } from "vuex";

import { IPatientTarget } from "../../../@types/patient";
import { IRootState, ISidebarModuleState } from "../../../@types/store";
import { Campaign, ImChannelMessage } from "../../../generated/types-gql";

const initialState: ISidebarModuleState = {
  isSidebarOpened: false,
  isSidebarOpenedFully: false,
  isToDoListOpened: false,
  openedEditModeFromTimeLine: false,
  selectedCampaign: null,
  selectedCarePlanPage: "",
  selectedEditMode: "",
  selectedMessage: null,
  selectedProfilePage: "",
  selectedTab: "",
  selectedTarget: null,
  selectedSetupScreen: null,
};

const module: Module<ISidebarModuleState, IRootState> = {
  state: initialState,

  actions: {},
  getters: {
    selectedTab(state) {
      return state.selectedTab;
    },
    isToDoListOpened(state) {
      return state.isToDoListOpened;
    },
    openedEditModeFromTimeLine(state) {
      return state.openedEditModeFromTimeLine;
    },
    selectedEditMode(state) {
      return state.selectedEditMode;
    },
    isSidebarOpened(state) {
      return state.isSidebarOpened;
    },
    isSidebarOpenedFully(state) {
      return state.isSidebarOpenedFully;
    },
    selectedMessage(state) {
      return state.selectedMessage;
    },
    selectedTarget(state) {
      return state.selectedTarget;
    },
    selectedProfilePage(state) {
      return state.selectedProfilePage;
    },
    selectedSetupScreen(state) {
      return state.selectedSetupScreen;
    },
    selectedCarePlanPage(state) {
      return state.selectedCarePlanPage;
    },
    selectedCampaign(state) {
      return state.selectedCampaign;
    },
  },
  mutations: {
    setOpenedEditModeFromTimeLine(state, flag: boolean) {
      state.openedEditModeFromTimeLine = flag;
    },
    selectEditMode(state, mode: string) {
      state.selectedEditMode = mode || "";
    },
    openToDoList(state) {
      state.selectedTab = "";
      state.isSidebarOpened = false;
      state.isSidebarOpenedFully = false;
      state.selectedCampaign = null;
      state.selectedMessage = null;
      state.selectedTarget = null;
      state.selectedSetupScreen = null;
      state.selectedCarePlanPage = "";
      state.selectedProfilePage = "";
      state.selectedEditMode = "";
      state.isToDoListOpened = true;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 0);
    },
    closeToDoList(state) {
      state.isToDoListOpened = false;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },
    toggleToDoList(state) {
      state.isToDoListOpened = !state.isToDoListOpened;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },
    resetSidebarModule(state) {
      state.selectedMessage = null;
      state.selectedCampaign = null;
      state.selectedTarget = null;
      state.selectedSetupScreen = null;
    },
    openSidebar(state, openFully: boolean) {
      state.isToDoListOpened = false;
      state.isSidebarOpened = true;
      state.isSidebarOpenedFully = openFully;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },
    selectTab(state, tab: string) {
      if (state.selectedTab !== tab) {
        state.selectedTab = tab;
        state.selectedProfilePage = "";
        state.selectedCarePlanPage = "";
        state.selectedSetupScreen = null;
      }
    },

    closeSidebar(state) {
      state.selectedTab = "";
      state.openedEditModeFromTimeLine = false;
      state.isSidebarOpened = false;
      state.isSidebarOpenedFully = false;
      state.isToDoListOpened = false;
      state.selectedEditMode = "";
      state.selectedCampaign = null;
      state.selectedSetupScreen = null;
      state.selectedCarePlanPage = "";
      state.selectedProfilePage = "";
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },
    selectMessage(state, message: ImChannelMessage) {
      state.selectedMessage = message;
    },
    selectTarget(state, target: IPatientTarget) {
      state.selectedTarget = target;
    },
    selectPatientProfileDetails(state, pageName: string) {
      state.selectedProfilePage = pageName;
    },
    selectCarePlanDetails(state, pageName: string) {
      state.selectedCarePlanPage = pageName;
    },
    selectCampaign(state, campaign: Campaign) {
      state.selectedCampaign = campaign;
    },
    selectSetupScreen(state, screen: string) {
      state.selectedSetupScreen = screen;
    },
  },
};

export default module;
