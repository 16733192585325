
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class MyiaInputCheckbox extends Vue {
  @Prop(String) private label!: string;
  @Prop(Boolean) private value!: boolean;
  @Prop(Boolean) private isDisabled!: boolean;

  private localValue: boolean = false;
  private isItemDisabled: boolean = false;

  @Watch("value")
  private valueChangedFromOutside() {
    this.localValue = this.value;
  }

  @Watch("isDisabled")
  private inputStateChanged() {
    this.isItemDisabled = this.isDisabled;
  }

  private mounted() {
    this.localValue = this.value;
    this.isItemDisabled = this.isDisabled;
  }

  private onChange() {
    this.$emit("input", this.localValue);
  }

  private get labelId() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }
}
