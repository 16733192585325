import {
  ChangeAlgorithm,
  Comparator,
  ICalculatedReadingInfo,
  IConditionResult,
  IDateReadings,
  IMarkerReadings,
  IReading,
  IReadingValue,
  IRuleResult,
  IThreshold,
  IThresholdResult,
  IThresholdRule,
  IThresholdRuleCondition,
  IVitalsReadings,
  ReadingAlgorithm,
  TimeRangeType,
  ValueType,
} from "../types/thresholds";

const multipliers: Record<string, number> = {
  weight: 2.20462,
};

export const celsiusToFahrenheit = (value: number) =>
  +(value * 1.8 + 32).toFixed(2);

const assertNever = (obj: never): never => {
  throw new Error(`Unexpected value: ${JSON.stringify(obj)}`);
};

export const getLongestReadings = (
  data: IVitalsReadings,
): (IDateReadings | null)[] =>
  Object.values(data).reduce((p, c) => (c.length > p.length ? c : p), []);

export const getReadingInfo = (
  readings: IDateReadings,
  algorithm: ReadingAlgorithm,
): ICalculatedReadingInfo | null => {
  switch (algorithm) {
    case "latest": {
      const reading = readings.reduce((p: IReading | null, c) => {
        const cTime = c != null && c.t != null ? c.t : 0;
        const pTime = p != null && p.t != null ? p.t : 0;
        return p != null && pTime > cTime ? p : c;
      }, null);
      return reading == null ? null : { readings: [reading], value: reading.v };
    }
    case "avg": {
      const value =
        readings.length > 0
          ? readings.reduce((p, c) => p + c.v, 0) / readings.length
          : null;
      return value == null ? null : { readings, value };
    }
    case "max": {
      const reading = readings.reduce(
        (p: IReading | null, c) => (p != null && p.v > c.v ? p : c),
        null,
      );
      return reading == null ? null : { readings: [reading], value: reading.v };
    }
    case "min": {
      const reading = readings.reduce(
        (p: IReading | null, c) => (p != null && p.v < c.v ? p : c),
        null,
      );
      return reading == null ? null : { readings: [reading], value: reading.v };
    }
    default:
      throw assertNever(algorithm);
  }
};

export const getValuesBasedOnChange = (
  values: IReadingValue[],
  activeValue: number,
  changeAlgorithm: ChangeAlgorithm,
  valueType?: ValueType | null,
): IReadingValue[] => {
  switch (changeAlgorithm) {
    case "increase": {
      return values.reduce((r, c) => {
        if (c == null || c >= activeValue) {
          r.push(null);
          return r;
        }
        const divider = valueType === "percent" ? c / 100 : 1;
        r.push((activeValue - c) / divider);
        return r;
      }, [] as IReadingValue[]);
    }
    case "decrease": {
      return values.reduce((r, c) => {
        if (c == null || c <= activeValue) {
          r.push(null);
          return r;
        }
        const divider = valueType === "percent" ? c / 100 : 1;
        r.push((c - activeValue) / divider);
        return r;
      }, [] as IReadingValue[]);
    }
    case "absolute":
      return [...values, activeValue];
    default:
      throw assertNever(changeAlgorithm);
  }
};

export const getReadingsBasedOnTime = (
  readings: IMarkerReadings,
  day: number,
  timeRangeType: TimeRangeType,
  pastReadingAlgorithm: ReadingAlgorithm,
  time: number,
): (number | null)[] => {
  switch (timeRangeType) {
    case "consecutive":
      return readings.slice(day - time + 1, day).map((r) => {
        const val = r == null ? null : getReadingInfo(r, pastReadingAlgorithm);
        return val == null ? null : val.value;
      });
    case "range":
      return [readings[day - time]].map((r) => {
        const val = r == null ? null : getReadingInfo(r, pastReadingAlgorithm);
        return val == null ? null : val.value;
      });
    default:
      throw assertNever(timeRangeType);
  }
};

export const getValuesToCompare = (
  {
    readings,
    activeReadingValue,
    day,
    changeAlgorithm,
    pastReadingAlgorithm,
    timeRangeType,
    time,
    valueType,
  }: {
    readings: IMarkerReadings;
    activeReadingValue: number;
    day: number;
    changeAlgorithm: ChangeAlgorithm;
    pastReadingAlgorithm: ReadingAlgorithm;
    timeRangeType: TimeRangeType;
    time: number;
    valueType?: ValueType | null;
  },
  disableChanges?: boolean | null,
): null | number[] => {
  if (
    readings.length === 0 ||
    day < 0 ||
    day >= readings.length ||
    time > readings.length
  ) {
    return null;
  }

  let timeBasedValues = getReadingsBasedOnTime(
    readings,
    day,
    timeRangeType,
    pastReadingAlgorithm,
    time,
  );

  if (!disableChanges) {
    timeBasedValues = timeBasedValues.filter((v) => v != null);
  }

  const values = getValuesBasedOnChange(
    timeBasedValues,
    activeReadingValue,
    changeAlgorithm,
    valueType,
  );
  return values.some((m) => m == null) ? null : (values as number[]);
};

export const isValidThreshold = (threshold: IThreshold): boolean => {
  return (
    threshold.rules != null &&
    Object.values(threshold.rules)
      .filter((rule) => rule.active === true)
      .every(
        (rule) =>
          rule.conditions != null &&
          rule.conditions.every((condition) => {
            return (
              condition.changeAlgorithm != null &&
              condition.comparator != null &&
              condition.value != null &&
              condition.marker != null &&
              condition.activeReadingAlgorithm != null &&
              (condition.changeAlgorithm === "absolute" ||
                (condition.time != null &&
                  condition.timeRangeType != null &&
                  condition.pastReadingAlgorithm != null))
            );
          }),
      )
  );
};

export const getThresholdMarkers = (threshold: IThreshold): Set<string> =>
  new Set(
    Array.prototype.concat.apply(
      [],
      Object.values(threshold.rules || {})
        .filter((rule) => rule.active === true)
        .map((r) => (r.conditions || []).map((c) => c.marker)),
    ),
  );

export const getComparisonResult = (
  values: number[],
  conditionValue: number,
  comparator: Comparator,
): boolean => {
  switch (comparator) {
    case "eq":
      return values.every((v) => v != null && v === conditionValue);
    case "lt":
      return values.every((v) => v != null && v < conditionValue);
    case "lte":
      return values.every((v) => v != null && v <= conditionValue);
    case "gt":
      return values.every((v) => v != null && v > conditionValue);
    case "gte":
      return values.every((v) => v != null && v >= conditionValue);
    default:
      throw assertNever(comparator);
  }
};

export const getConditionResult = (
  condition: IThresholdRuleCondition,
  vitals: IVitalsReadings,
  day: number,
  disableChanges?: boolean | null,
): IConditionResult => {
  if (
    condition.value == null ||
    condition.marker == null ||
    condition.comparator == null ||
    condition.changeAlgorithm == null ||
    condition.activeReadingAlgorithm == null ||
    (condition.changeAlgorithm !== "absolute" &&
      (condition.pastReadingAlgorithm == null ||
        condition.valueType == null)) ||
    vitals[condition.marker] == null ||
    condition.timeRangeType == null
  ) {
    return {
      result: false,
    };
  }
  const multiplier =
    multipliers[condition.marker] == null ? 1 : multipliers[condition.marker];
  const time =
    condition.time == null ||
    (condition.changeAlgorithm === "absolute" &&
      condition.timeRangeType === "range")
      ? 0
      : condition.time;
  const pastReadingAlgorithm =
    condition.pastReadingAlgorithm == null ||
    condition.changeAlgorithm === "absolute"
      ? "avg"
      : condition.pastReadingAlgorithm;
  const activeReadingValue = getReadingInfo(
    vitals[condition.marker][day] || [],
    condition.activeReadingAlgorithm,
  );
  if (activeReadingValue == null || activeReadingValue.value == null) {
    return {
      result: false,
    };
  }
  const valuesToCompare = getValuesToCompare(
    {
      readings: vitals[condition.marker],
      activeReadingValue: activeReadingValue.value,
      day,
      changeAlgorithm: condition.changeAlgorithm,
      pastReadingAlgorithm,
      timeRangeType: condition.timeRangeType,
      time,
      valueType: condition.valueType,
    },
    disableChanges,
  );
  let result =
    valuesToCompare != null &&
    getComparisonResult(
      valuesToCompare.map((v) => {
        const value =
          condition.valueType === "percent"
            ? v
            : condition.marker === "temperature" ||
              condition.marker === "medianTemperature"
            ? celsiusToFahrenheit(v)
            : v * multiplier;
        return Math.round(value * 100) / 100;
      }),
      condition.value,
      condition.comparator,
    );

  if (!disableChanges) {
    result = result && valuesToCompare != null && valuesToCompare.length !== 0;
  }
  return {
    activeReading: activeReadingValue,
    result,
  };
};

export const getRuleResult = (
  rule: IThresholdRule,
  vitals: IVitalsReadings,
  day: number,
  disableChanges?: boolean | null,
): IRuleResult => {
  const results =
    rule.conditions != null && !rule.deletedAt
      ? rule.conditions.map((c) =>
          getConditionResult(c, vitals, day, disableChanges),
        )
      : null;
  return {
    conditions: results,
    result: results != null && results.every((r) => r.result),
  };
};

export const getThresholdResult = (
  threshold: IThreshold,
  vitals: IVitalsReadings,
  day: number,
  disableChanges?: boolean | null,
): IThresholdResult => {
  const results =
    threshold.rules == null
      ? null
      : Object.entries(threshold.rules).reduce(
          (p: Record<string, IRuleResult>, [id, r]) => {
            if (!r.active) {
              return p;
            }
            const result = getRuleResult(r, vitals, day, disableChanges);
            if (!result.result) {
              return p;
            }
            p[id] = result;
            return p;
          },
          {},
        );
  return {
    result: results == null ? false : Object.keys(results).length > 0,
    rules: results,
  };
};

export const getRuleHitPoints = (
  rule: IThresholdRule,
  vitals: IVitalsReadings,
  disableChanges?: boolean | null,
): boolean[] => {
  const readings = getLongestReadings(vitals);
  return readings.map(
    (_, index) => getRuleResult(rule, vitals, index, disableChanges).result,
  );
};

export const getThresholdHitPoints = (
  threshold: IThreshold,
  vitals: IVitalsReadings,
  disableChanges?: boolean | null,
): boolean[] => {
  const readings = getLongestReadings(vitals);
  return readings.map(
    (_, index) =>
      getThresholdResult(threshold, vitals, index, disableChanges).result,
  );
};
