import logger from "@/logger";
import router from "@/router/routes";
import {
  handleAuthentication,
  login,
  logout,
  setSession,
} from "@/services/auth.service";
import { Module } from "vuex";

import { IAuthModuleState, IRootState } from "../../../@types/store";

const initialState: IAuthModuleState = {
  accessToken: localStorage.getItem("access_token"),
  expiresAt: localStorage.getItem("expires_at"),
  idToken: localStorage.getItem("id_token"),
  isTimedOut: false,
};

const module: Module<IAuthModuleState, IRootState> = {
  state: initialState,

  actions: {
    login() {
      login();
    },
    async logout({ commit, dispatch, rootGetters }) {
      await dispatch("registerProviderPortalPageView", {
        connectionId: rootGetters.connectionId,
        organizationId: rootGetters.rootOrganization?.id,
        page: "/logout",
        patientId: rootGetters.patient?.id,
      });
      commit("logout");
      logout();
    },
    async handleAuthentication({ commit }) {
      try {
        const authResult = await handleAuthentication();
        if (authResult.accessToken && authResult.idToken) {
          commit("setIdToken", authResult.idToken);
          setSession(authResult);
          const path = sessionStorage.getItem("destinationPath") || "/";
          sessionStorage.setItem("destinationPath", "");
          await router.push(path);
        } else {
          await router.push("/login");
        }
      } catch (error) {
        logger.error(error as Error);
        await router.push("/login");
      }
    },
  },
  getters: {
    token(state) {
      return state.idToken;
    },
    isTimedOut(state) {
      return state.isTimedOut;
    },
  },
  mutations: {
    logout(state) {
      state.accessToken = null;
      state.idToken = null;
      state.expiresAt = null;
    },
    setIdToken(state, token: string) {
      state.idToken = token;
    },
    setIsTimedOut(state, value: boolean) {
      state.isTimedOut = value;
    },
  },
};

export default module;
