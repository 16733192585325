import { Module } from "vuex";

import { getOrganizationsRecursive } from "@/factories/organization-factory";
import logger from "@/logger";
import { registerPPPageView } from "@/services/analytics.service";
import { registerProviderWSConnection } from "@/services/misc.service";
import { getGlobalSettings } from "@/services/organization.service";
import { getProviderMe } from "@/services/provider.service";

import { IPPPageViewInput } from "../../../@types/analytics";
import { IOrganization } from "../../../@types/organization";
import { IProvider, IRole } from "../../../@types/provider";
import { IProviderModuleState, IRootState } from "../../../@types/store";

const initialState: IProviderModuleState = {
  provider: null,
  providerWSConnection: null,
  roles: null,
};

const module: Module<IProviderModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getVideoCallInitialData({ commit }) {
      commit("dataPending");
      try {
        const { data: providerMe } = await getProviderMe();

        const provider = {
          id: providerMe?.id,
          firstName: providerMe?.firstName,
          lastName: providerMe?.lastName,
          photo: providerMe?.photo,
          namePrefix: providerMe?.namePrefix,
          displayName: providerMe?.displayName,
        };
        commit("setBaseSettings", providerMe?.baseSettings);
        commit("setRoles", providerMe?.roles);
        commit("setProvider", provider);
        const organizationTree = providerMe?.organizationTree || [];
        if (organizationTree.length > 0) {
          commit("setOrganizations", organizationTree);
          const flatTree = getOrganizationsRecursive(organizationTree);
          commit("setOrganizationsFlatMap", flatTree);
          const rootOrgId =
            sessionStorage.getItem("rootOrganizationId") ||
            localStorage.getItem("rootOrganizationId");
          const currentOrgId =
            sessionStorage.getItem("organizationId") ||
            localStorage.getItem("organizationId");
          let rootOrg = rootOrgId
            ? flatTree.find((o) => o.id === rootOrgId)
            : null;
          const currentOrg = currentOrgId
            ? flatTree.find((o) => o.id === currentOrgId)
            : null;
          if (currentOrg != null && rootOrg == null) {
            rootOrg =
              flatTree.find((o) => o.id === currentOrg.root) ||
              ({
                id: currentOrg.root,
              } as IOrganization);
          }
          rootOrg && commit("setRootOrganization", rootOrg);
          currentOrg && commit("setCurrentOrganization", currentOrg);
          if (rootOrg) {
            const { data } = await getGlobalSettings(rootOrg.id, [
              "orgPhoto",
              "providerPhoto",
              "SETTINGS___VIDEO_QUALITY_FEEDBACK_CONFIGS",
            ]);
            commit("setGlobals", data);
          }
          localStorage.setItem("sessionDate", Date.now().toString());
        }
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async getInitialData({ commit, dispatch }) {
      commit("dataPending");
      try {
        // All requests from PP require organization id in header,
        // so please make sure to not send any request before these requests

        const { data: providerMe } = await getProviderMe();

        const provider = {
          id: providerMe?.id,
          firstName: providerMe?.firstName,
          lastName: providerMe?.lastName,
          photo: providerMe?.photo,
          namePrefix: providerMe?.namePrefix,
          displayName: providerMe?.displayName,
        };
        commit("setBaseSettings", providerMe.baseSettings);
        commit("setRoles", providerMe?.roles);
        commit("setProvider", provider);
        const organizationTree = providerMe?.organizationTree || [];
        if (organizationTree.length > 0) {
          commit("setOrganizations", organizationTree);
          const flatTree = getOrganizationsRecursive(organizationTree);
          commit("setOrganizationsFlatMap", flatTree);
          const rootOrgId =
            sessionStorage.getItem("rootOrganizationId") ||
            localStorage.getItem("rootOrganizationId");
          const currentOrgId =
            sessionStorage.getItem("organizationId") ||
            localStorage.getItem("organizationId");
          let rootOrg = rootOrgId
            ? flatTree.find((o) => o.id === rootOrgId)
            : null;
          const currentOrg = currentOrgId
            ? flatTree.find((o) => o.id === currentOrgId)
            : null;
          if (currentOrg != null && rootOrg == null) {
            rootOrg =
              flatTree.find((o) => o.id === currentOrg.root) ||
              ({
                id: currentOrg.root,
              } as IOrganization);
          }
          rootOrg && commit("setRootOrganization", rootOrg);
          currentOrg && dispatch("updateCurrentOrganization", currentOrg);
          commit("setStorageFilters");
          commit("setStoragePriorityFilter");
          commit("setStorageSortValue");

          if (rootOrg) {
            dispatch("resetRootOrganizationData");
            dispatch("getOrganizationWatchlists");
            dispatch("getOrgSettingsAndConfigs");
            dispatch("getAllProviderRoles");
            dispatch("getAllPatientTypes");
          }
          localStorage.setItem("sessionDate", Date.now().toString());
        }
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async registerProviderWsConnection(
      { commit, rootGetters },
      connectionId: string,
    ) {
      try {
        await registerProviderWSConnection(
          connectionId,
          rootGetters.organization.id,
        );
        commit("setProviderWSConnection", true);
      } catch (error) {
        logger.error(error as Error);
      }
    },
    async registerProviderPortalPageView(
      { rootGetters },
      input: IPPPageViewInput,
    ) {
      try {
        await registerPPPageView(input, rootGetters.organization.id);
      } catch (error) {
        logger.error(error as Error);
      }
    },
  },
  getters: {
    // Use allRolesMap for all provider roles
    roles(state) {
      return state.roles;
    },
    provider(state) {
      return state.provider;
    },

    // Use this getter for CUD operations, as they must be registered for logged in provider
    loggedInProviderId(state) {
      return state.provider?.id || localStorage.getItem("loggedInProviderId");
    },

    providerId(state) {
      return state.provider?.id;
    },
    providerWSConnection(state) {
      return state.providerWSConnection;
    },
  },
  mutations: {
    setRoles(state, roles: IRole[]) {
      state.roles = roles;
    },

    setProvider(state, provider: IProvider) {
      state.provider = provider;
    },

    setProviderWSConnection(state, status: boolean) {
      state.providerWSConnection = status;
    },
  },
};

export default module;
