import Vue from "vue";
import Vuex, { ModuleTree } from "vuex";

import { IHttpError, IRootState } from "../../@types/store";
import AuthModule from "./modules/auth.module";
import ActivityModule from "./modules/activity.module";
import CampaignModule from "./modules/campaign.module";
import CarePlanModule from "./modules/care-plan.module";
import ChartModule from "./modules/chart.module";
import GlobalsModule from "./modules/globals.module";
import MessageModule from "./modules/message.module";
import PatientListModule from "./modules/organization.module";
import ProfileModule from "./modules/profile.module";
import PatientModule from "./modules/patient.module";
import ProviderModule from "./modules/provider.module";
import SidebarModule from "./modules/sidebar.module";
import TargetModule from "./modules/target.module";
import ThresholdsModule from "./modules/thresholds.module";
import WSModule from "./modules/websocket.module";
import ZendeskModule from "./modules/zendesk.module";
import router from "../router/routes";
import { sync } from "vuex-router-sync";

Vue.use(Vuex);

const modules: ModuleTree<IRootState> = {
  auth: AuthModule,
  activity: ActivityModule,
  campaign: CampaignModule,
  carePlan: CarePlanModule,
  chart: ChartModule,
  message: MessageModule,
  patient: PatientModule,
  profile: ProfileModule,
  patientList: PatientListModule,
  sidebar: SidebarModule,
  target: TargetModule,
  threshold: ThresholdsModule,
  user: ProviderModule,
  websocket: WSModule,
  globalSettings: GlobalsModule,
  zendesk: ZendeskModule,
};

const initialState: IRootState = {
  isLoading: 0,
  httpError: {},
  videoTabRef: null,
};

const store = new Vuex.Store<IRootState>({
  state: initialState,

  actions: {
    populateHttpErrors({ commit, state }, httpError?: IHttpError) {
      if (!httpError || httpError.status === 401) {
        return;
      }
      if (
        httpError.message === state.httpError.message &&
        state.httpError.statusText === httpError.statusText &&
        (state.httpError.timestamp || 0) > Date.now() - 30 * 1000
      ) {
        return;
      }

      commit("setHttpError", httpError);
    },
  },
  getters: {
    isLoading(state) {
      return !!state.isLoading;
    },
    httpError(state) {
      return state.httpError;
    },
    videoTabRef(state) {
      return state.videoTabRef;
    },
    route(state) {
      return state.route;
    },
  },
  modules,
  mutations: {
    resetDataPending(state) {
      state.isLoading = 0;
    },
    dataReceived(state) {
      state.isLoading--;
    },
    dataPending(state) {
      state.isLoading++;
    },
    setHttpError(state, error: IHttpError) {
      state.httpError = {
        ...error,
        timestamp: Date.now(),
      };
    },
    setVideoTabRef(state, videoTabRef: Window) {
      state.videoTabRef = videoTabRef;
    },
  },
});

sync(store, router);

export default store;
