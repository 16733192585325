import { IOrganization } from "../../@types/organization";
import store from "@/store/store";

export const getProviderById = (id: string) => {
  if (!id) {
    return null;
  }
  const provider = store.getters.providerIdMap[id];
  if (!provider) {
    store.dispatch("getProviders", [id]);
    return null;
  }
  return provider;
};

export const getTeamsByOrg = (org: IOrganization) => {
  const teams: IOrganization[] = [];
  const rec = (o: IOrganization) => {
    o.children?.forEach((team) => {
      if (team.type === "team") {
        teams.push(team);
      } else rec(team);
    });
  };
  rec(org);
  return teams.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
};

export const getOrganizationsRecursive = (organizations: IOrganization[]) => {
  const orgs: IOrganization[] = [];
  const rec = (o: IOrganization) => {
    if (o.children && o.children.length > 0) {
      o.children.forEach((sub) => {
        rec(sub);
      });
    }
    orgs.push(o);
  };
  organizations.forEach((org) => {
    rec(org);
  });
  return orgs;
};

export const getOrganizationUpperBranches = (
  rootOrg?: IOrganization | null,
  org?: IOrganization | null,
) => {
  if (!(org && rootOrg)) {
    return [];
  }
  const parentOrgs: IOrganization[] = [org];
  if (org.parent === "/ROOT") {
    return parentOrgs;
  }
  const allOrgs = getOrganizationsRecursive([rootOrg]);
  const rec = (o: IOrganization) => {
    const parentOrg = allOrgs.find((p) => p.id === o.parent);
    if (parentOrg) {
      parentOrgs.push(parentOrg);
      rec(parentOrg);
    }
  };
  rec(org);
  return parentOrgs;
};
