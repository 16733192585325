
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MyiaInput extends Vue {
  @Prop(String || Number) private value!: string | number;
  @Prop(String) private placeholder!: string;
  @Prop(String) private append!: string;
  @Prop(String) private min!: string;
  @Prop(String) private max!: string;
  @Prop(Boolean) private noBorder!: boolean;
  @Prop(Boolean) private noPadding!: boolean;
  @Prop({ default: "text", type: String }) private type!: string;
  @Prop({ type: String, default: "100%" }) private width!: string;

  private localValue: number | string = "";

  private mounted() {
    this.localValue = this.value;
  }

  private setValue(event: any) {
    this.localValue = event.target.value;
    this.$emit("change", this.localValue);
  }
}
