import {
  getPatientListVitalProjections,
  setPatientListItemDataLoaded,
} from "@/factories/patient-factory";
import $http from "@/services/rest.service";

import { IGlobalSettingEntry } from "../../@types/globals";
import { IQuickMessage } from "../../@types/organization";
import {
  IChecklist,
  IOrgPatientWatchlist,
  IPatient,
} from "../../@types/patient";
import {
  IPatientTimelineEventTemplate,
  IPatientTimelineTaskTemplate,
} from "../../@types/patient-timeline-templates";

const BASE_PATH = "/pp/orgs";

export const getSystemEduMaterials = (orgId: string, fields: string[]) => {
  return $http.get(`${BASE_PATH}/${orgId}/edu-materials`, {
    params: { fields: fields.join(",") },
  });
};

export const getZendeskArticles = (orgId: string) => {
  return $http.get(`${BASE_PATH}/${orgId}/zendesk-articles`);
};

export const getZendeskArticle = (orgId: string, articleId: string) => {
  return $http.get(`${BASE_PATH}/${orgId}/zendesk-articles/${articleId}`);
};

export const getSystemChecklists = (orgId: string, fields: string[]) => {
  return $http.get<IChecklist[] | null>(`${BASE_PATH}/${orgId}/checklists`, {
    params: { fields: fields.join(",") },
  });
};

export const getGlobalSettings = (orgId: string, ids: string[]) => {
  return $http.post<Record<string, IGlobalSettingEntry>>(
    `${BASE_PATH}/${orgId}/load-settings`,
    { ids },
  );
};

export const getResourceTypes = (orgId: string) => {
  return $http.get(`${BASE_PATH}/${orgId}/resource-types`);
};

export const getOrganizationQuickMessages = (
  orgId: string,
  fromRoot = true,
) => {
  return $http.get<IQuickMessage[]>(`${BASE_PATH}/${orgId}/quick-messages`, {
    params: {
      fromRoot,
    },
  });
};

export const getOrganizationPatientsForSearchList = (orgId: string) => {
  return $http.get<IPatient[]>(`${BASE_PATH}/${orgId}/patients-search-list`);
};

export const getOrganizationWatchlists = (orgId: string) => {
  return $http.get<IOrgPatientWatchlist[] | null>(
    `${BASE_PATH}/${orgId}/watchlists`,
  );
};

export const getOrganizationPatientInitialData = async (
  orgId: string,
  orgIds: string[],
  preloadPatientsCount = 0,
) => {
  const {
    data: { orgMembersMap, preloadedPatients, ...rest },
  } = await $http.post(`${BASE_PATH}/${orgId}/get-patients-list-main-data`, {
    orgIds,
    preloadPatientsCount,
    vitalProjections: getPatientListVitalProjections(),
  });
  return {
    ...rest,
    allTeams: Object.values(orgMembersMap),
    preloadedPatients: preloadedPatients.map(setPatientListItemDataLoaded),
  };
};

export const getOrganizationPatientListItems = (
  orgId: string,
  patientIds: string[],
) => {
  return $http.post(`${BASE_PATH}/${orgId}/get-patients-list-items`, {
    patientIds,
    vitalProjections: getPatientListVitalProjections(),
  });
};

export const getPatientTimelineTemplates = (orgId: string) => {
  return $http.get<{
    patientTimelineEventTemplates: IPatientTimelineEventTemplate[];
    patientTimelineTaskTemplates: IPatientTimelineTaskTemplate[];
  }>(`${BASE_PATH}/${orgId}/patient-timeline-templates`);
};
