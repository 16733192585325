import axios from "axios";
import moment from "moment-timezone";
import { Module } from "vuex";

import SWorker from "simple-web-worker";
import apollo from "@/apollo";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_TIMEZONE,
  ResourceIdEnum,
  ZIP_TO_COORDINATES_URL,
} from "@/configs/constants";
import { getSolarEventsForPatient } from "@/factories/chart-factory";
import {
  getGranularChartTimeline,
  getTimelineDates,
} from "@/factories/date-factory";
import {
  getPatientTimelineItemsByDate,
  getVitalProjections,
} from "@/factories/patient-factory";
import {
  COMPLETE_MEDICAL_TIMELINE_EVENT_TASK,
  COMPLETE_NON_MEDICAL_TIMELINE_EVENT_TASK,
  CREATE_TIMELINE_MEDICAL_EVENT_TASK,
  CREATE_TIMELINE_NON_MEDICAL_EVENT_TASK,
  CREATE_TIMELINE_TASK,
  DELETE_MEDICAL_TIMELINE_EVENT,
  DELETE_NON_MEDICAL_TIMELINE_EVENT,
  DELETE_TIMELINE_TASK,
  POST_PATIENT_EVENT,
  POSTPONE_TIMELINE_TASK,
  UNDO_COMPLETE_MEDICAL_TIMELINE_EVENT_TASK,
  UNDO_COMPLETE_NON_MEDICAL_TIMELINE_EVENT_TASK,
  UNDO_MEDICAL_TIMELINE_EVENT_DELETE,
  UNDO_NON_MEDICAL_TIMELINE_EVENT_DELETE,
  UNDO_TIMELINE_TASK_DELETE,
  UPDATE_MEDICAL_TIMELINE_ALERTS,
  UPDATE_MEDICAL_TIMELINE_EVENT,
  UPDATE_NON_MEDICAL_TIMELINE_ALERTS,
  UPDATE_NON_MEDICAL_TIMELINE_EVENT,
  UPDATE_TIMELINE_TASK,
} from "@/graphql/mutations/patient.mutation";
import { POST_RESOURCE_VITALS } from "@/graphql/mutations/resource.mutation";
import {
  GET_PATIENT_ACTIVE_STATE,
  GET_PATIENT_EVENTS,
  GET_PATIENT_INITIAL_DATA,
  GET_PATIENT_INITIAL_DATA_EVENTS,
  GET_PATIENT_INITIAL_DATA_VITALS,
  GET_PATIENT_NOTIFICATIONS,
  GET_PATIENT_PROVIDERS_IN_CALL,
  GET_PATIENT_RESOURCES,
  GET_PATIENT_REVIEW_NOTES,
  GET_PATIENT_SURVEY_ANSWERS,
  GET_PATIENT_VITALS,
} from "@/graphql/queries/patient.query";
import logger from "@/logger";
import router from "@/router/routes";
import {
  getStatDataStructure,
  getStatsMap,
  makeStatsGranular,
  mergeStats,
} from "@/services/patient.parser";
import {
  deletePatientVital,
  deleteRestPeriods,
  getPatientPiis,
  getPatientListItemEvents,
  getPatientResourceStatuses,
  getPatientReviewChecklists,
  undoRestPeriods,
  undoVitalDeleting,
  updatePatientFromEhr,
  updateReviewMedicalChecklist,
  updateReviewNonMedicalChecklist,
  deleteTimelineEventFeedbacks,
  createTimelineEventFeedbacks,
  getTimelineEventFeedbacks,
  getPatientWatchlists,
  createPatientWatchlist,
  editPatientWatchlist,
  deletePatientWatchlist,
  getPatientSupportTickets,
  getPatientSupportEvents,
  getPatientLatestVitals,
  updatePatientReviewNotesDraft,
  updatePatientNotificationState,
} from "@/services/patient.service";

import { AnalyticsEvent } from "../../../@types/analytics";
import { IOrganization } from "../../../@types/organization";
import {
  IChecklist,
  IChecklistItem,
  IDailyVitalsRemovals,
  IPatient,
  IPatientLatestVitals,
  IPatientLocation,
  IPatientNotificationState,
  IPatientReviewChecklist,
  IPatientStructure,
  IPatientTraceRemoval,
  IRawStat,
  IVideoEndCallState,
} from "../../../@types/patient";
import { IPatientModuleState, IRootState } from "../../../@types/store";
import {
  Feedback,
  Mutation,
  Patient,
  PatientTimelineEvent,
  PatientTimelineEventUpdateInput,
  PatientTimelineEventWithTaskCreateInput,
  PatientTimelineTask,
  PatientTimelineTaskCreateBody,
  PatientTimelineTaskUniqueKey,
  PatientTimelineTaskUpdateBody,
  PatientVitalsInputEntity,
  Provider,
  ProviderPortalPageView,
  Query,
} from "../../../generated/types-gql";
import { IProvider } from "../../../@types/provider";
import {
  IFeedback,
  IFeedbackInput,
  ITimelineEventUniqueKey,
} from "../../../@types/patient-timeline-templates";

const initialState: IPatientModuleState = {
  lockedReviewNotes: null,
  notifications: null,
  patient: null,
  patientState: null,
  patientStateLoaded: null,
  patientTimezone: null,
  providersInCall: [],
  resources: [],
  resourceAvailable: false,
  reviewChecklists: null,
  appointments: null,
  timelineTasks: null,
  timelineEvents: null,
  supportEvents: null,
  supportTickets: null,
  vitals: null,
  surveyAnswers: null,
  patientCallEndState: null,
  watchlists: null,
  feedbacks: null,
  enrollmentDate: null,
  messaging: null,
  videoCalls: null,
  duplicateName: null,
  resourceStatuses: [],
  sunriseSunsetTimes: [],
  patientLocation: null,
  patientNoteDraftMap: {},
  mergedStats: [],
  earliestEventTimestamp: null,
  latestVitals: null,
};

const module: Module<IPatientModuleState, IRootState> = {
  state: Object.assign({}, initialState),

  actions: {
    async updateReviewMedicalChecklist(
      { commit, dispatch, rootGetters },
      {
        patientId,
        checklistId,
        item,
      }: {
        patientId: string;
        checklistId: string;
        item: IChecklistItem;
      },
    ) {
      try {
        const orgId = rootGetters.rootOrganization.id;
        await updateReviewMedicalChecklist(orgId, patientId, checklistId, item);
        commit("updateChecklistItem", item);
        dispatch("getPatientReviewChecklists", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updateReviewMedicalChecklists(
      { commit, dispatch, rootGetters },
      {
        patientId,
        checklistIds,
        item,
      }: {
        patientId: string;
        checklistIds: string[];
        item: IChecklistItem;
      },
    ) {
      try {
        const orgId = rootGetters.rootOrganization.id;
        await Promise.all(
          checklistIds.map((id) =>
            updateReviewMedicalChecklist(orgId, patientId, id, item),
          ),
        );
        commit("updateChecklistItem", item);
        dispatch("getPatientReviewChecklists", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updateReviewNonMedicalChecklist(
      { commit, dispatch, rootGetters },
      {
        patientId,
        checklistId,
        item,
      }: {
        patientId: string;
        checklistId: string;
        item: IChecklistItem;
      },
    ) {
      try {
        const orgId = rootGetters.rootOrganization.id;
        await updateReviewNonMedicalChecklist(
          orgId,
          patientId,
          checklistId,
          item,
        );
        commit("updateChecklistItem", item);
        dispatch("getPatientReviewChecklists", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updateReviewNonMedicalChecklists(
      { commit, dispatch, rootGetters },
      {
        patientId,
        checklistIds,
        item,
      }: {
        patientId: string;
        checklistIds: string[];
        item: IChecklistItem;
      },
    ) {
      try {
        const orgId = rootGetters.rootOrganization.id;
        await Promise.all(
          checklistIds.map((checklistId) =>
            updateReviewNonMedicalChecklist(
              orgId,
              patientId,
              checklistId,
              item,
            ),
          ),
        );
        commit("updateChecklistItem", item);
        dispatch("getPatientReviewChecklists", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientInitialData(
      { commit, rootGetters, dispatch, state },
      { patientId }: { patientId: string },
    ) {
      try {
        commit("dataPending");
        const endDate = moment.tz(
          Date.now(),
          state.patientTimezone || DEFAULT_TIMEZONE,
        );
        const startDate = endDate.clone().subtract(2, "weeks");

        const [
          initialDataResult,
          initialDataVitalsResult,
          initialDataEventsResult,
          patientLatestVitals,
          patientResourceStatusesResult,
        ] = await Promise.allSettled([
          apollo.query<Query>({
            query: GET_PATIENT_INITIAL_DATA,
            variables: {
              patient: patientId,
            },
          }),
          apollo.query<Query>({
            query: GET_PATIENT_INITIAL_DATA_VITALS,
            variables: {
              patient: patientId,
              projections: getVitalProjections(),
              startDate: startDate.format(DATE_FORMAT_YYYY_MM_DD),
              endDate: endDate.format(DATE_FORMAT_YYYY_MM_DD),
            },
          }),
          apollo.query<Query>({
            query: GET_PATIENT_INITIAL_DATA_EVENTS,
            variables: {
              patient: patientId,
              rootOrgId: rootGetters.rootOrganization?.id,
            },
          }),
          getPatientLatestVitals(
            rootGetters.rootOrganization?.id,
            patientId,
            getVitalProjections(),
          ),
          getPatientResourceStatuses(
            rootGetters.rootOrganization?.id,
            patientId,
          ),
        ]);
        if (router.currentRoute?.params?.patientId === patientId) {
          const initialData =
            initialDataResult.status === "fulfilled"
              ? initialDataResult?.value?.data?.patient
              : null;
          const initialDataVitals =
            initialDataVitalsResult.status === "fulfilled"
              ? initialDataVitalsResult?.value?.data?.patient
              : null;
          const initialDataEvents =
            initialDataEventsResult.status === "fulfilled"
              ? initialDataEventsResult?.value?.data?.patient
              : null;
          const initialDataLatestVitals =
            patientLatestVitals.status === "fulfilled"
              ? patientLatestVitals?.value?.data
              : null;
          const patientResourceStatuses =
            patientResourceStatusesResult.status === "fulfilled"
              ? patientResourceStatusesResult?.value?.data
              : null;

          const patient = rootGetters?.patientIdMap[patientId] || {};

          //FIXME: deprecated
          const rec = (o: IOrganization) => {
            o.children?.forEach((sub) => {
              if (sub.type === "team") {
                const user = sub.patients?.find((p) => p.id === patient.id);
                if (user) {
                  patient.organization = {
                    id: sub.id,
                    name: sub.name,
                    patients: sub.patients,
                    providerPatients: sub.providerPatients,
                    roleAssignments: sub.roleAssignments,
                  };
                  patient.providers = user.providers;
                }
              }
              rec(sub);
            });
          };
          rec(rootGetters.rootOrganization);
          commit("setPatientType", {
            type: initialData?.rawType,
            patientId,
          });
          commit("setPatient", {
            ...state.patient,
            ...patient,
            tags: initialData?.tags || [],
            id: initialData?.id,
          });
          commit("setLatestVitals", {
            vitals: initialDataLatestVitals,
            surveys: initialDataVitals?.surveyAnswers,
          });
          commit("setPatientFields", {
            ...initialData,
            ...initialDataVitals,
            ...initialDataEvents,
            resourceStatuses: patientResourceStatuses.resources,
          });
          commit("setMergedStats");
          commit("setFirstEventDate", initialDataEvents?.timelineEventsJSON);
          if (rootGetters?.enableSolarEvents) {
            const hub = initialData?.resources?.find(
              (r) =>
                r.id?.includes(ResourceIdEnum.Hub) &&
                r._metadata?.location?.latitude &&
                r._metadata?.location?.longitude,
            );
            commit("setPatientLocation", hub?._metadata.location);
          }
          if (!initialData?.currentState) {
            dispatch("getPatientState", {
              patientId,
            });
          } else {
            commit("setPatientStateLoaded");
          }
          dispatch("getSecondaryData", { patientId });
        }
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async getSecondaryData({ dispatch }, { patientId }: { patientId: string }) {
      // This requests don't have loading overlay
      dispatch("getChecklistsFeedbacks");
      dispatch("getPatientAllVitals", { patientId });
      dispatch("getPatientWatchlists", { patientId });
      dispatch("getPatientPii", { patientId });
      dispatch("getPatientDiagnoses", { patientId });
      dispatch("getPatientMissedMessages", { patientId });
      dispatch("getPatientReviewNotes", { patientId });
      dispatch("getPatientSupportEvents", {
        patientId,
      });
      dispatch("getPatientSupportTickets", {
        patientId,
      });
    },

    async getProvidersInCall({ commit }, { patientId }: { patientId: string }) {
      const { data } = await apollo.query<Query>({
        query: GET_PATIENT_PROVIDERS_IN_CALL,
        variables: {
          patient: patientId,
        },
      });
      commit("setProvidersInCall", data.patient?.providersInCall || []);
    },

    async getResourceStatuses({ commit, rootGetters }, { patientId }) {
      const { data } = await getPatientResourceStatuses(
        rootGetters.rootOrganization?.id,
        patientId,
      );
      commit("setPatientFields", {
        resourceStatuses: data.resources,
      });
    },

    async updatePatientFromEhr(
      { dispatch },
      {
        orgId,
        patientId,
        fields,
      }: { orgId: string; patientId: string; fields: string },
    ) {
      try {
        await updatePatientFromEhr(orgId, patientId, fields);
        dispatch("getPatientPii", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientWatchlists(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      if (!rootGetters.patientWatchlistsEnabled) {
        return;
      }
      try {
        const projections = [
          "patient",
          "provider",
          "title",
          "start",
          "end",
          "notes",
          "lastEditedAt",
          "pk",
          "sk",
          "author",
          "version",
        ];
        const { data } = await getPatientWatchlists(
          rootGetters.rootOrganization.id,
          patientId,
          projections,
        );
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setPatientFields", { id: patientId, watchlists: data });
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientLatestVitals(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await getPatientLatestVitals(
          rootGetters.rootOrganization?.id,
          patientId,
          getVitalProjections(),
        );

        commit("setLatestVitals", {
          vitals: data,
          surveys: [],
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientPii(
      { commit, state, rootGetters },
      { patientId, projections }: { patientId: string; projections?: string[] },
    ) {
      try {
        if (!rootGetters.rootOrganization?.id) {
          logger.error("No root organization id");
          return;
        }
        const { data: pii } = await getPatientPiis(
          rootGetters.rootOrganization?.id,
          patientId,
          projections,
        );
        commit("setPatient", { ...state.patient, pii });
        commit("updatePatientListPatients", [{ id: patientId, pii }]);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientLocationByZip({ commit, state, rootGetters }) {
      try {
        const zip = state.patient?.pii?.zip;
        if (!state.patientLocation && zip && rootGetters?.enableSolarEvents) {
          const zipBasedLocation = await axios.get(
            ZIP_TO_COORDINATES_URL + zip,
          );
          commit("setPatientLocation", zipBasedLocation.data);
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getActiveEvents(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        if (!rootGetters.rootOrganization?.id) {
          return;
        }
        const { data } = await getPatientListItemEvents(
          rootGetters.rootOrganization.id,
          patientId,
        );
        commit("updatePatientListPatients", [
          {
            ...data,
            id: patientId,
          },
        ]);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientSurveyAnswers(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_SURVEY_ANSWERS,
          variables: {
            patient: patientId,
          },
        });
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setSurveyAnswers", data.patient?.surveyAnswers);
          commit("setMergedStats");
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientState({ commit }, { patientId }: { patientId: string }) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_ACTIVE_STATE,
          variables: { patient: patientId },
        });
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setPatientFields", data.patient);
          commit("setPatientStateLoaded");
        }
        commit("updatePatientListPatients", [
          {
            id: patientId,
            currentState: data.patient?.currentState,
          },
        ]);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientNotifications(
      { dispatch, rootGetters },
      {
        patientId,
        notifications,
        state,
      }: {
        patientId: string;
        notifications: string[];
        state: IPatientNotificationState;
      },
    ) {
      try {
        await updatePatientNotificationState(
          rootGetters.rootOrganization.id,
          patientId,
          state,
          notifications,
        );
        dispatch("getPatientNotifications", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientState(
      { commit },
      {
        patientId,
        eventName,
        payload,
      }: { patientId: string; eventName: string; payload?: AnalyticsEvent },
    ) {
      try {
        commit("dataPending");
        await apollo.mutate<Mutation>({
          mutation: POST_PATIENT_EVENT,
          variables: {
            input: {
              patientId,
              eventName,
              payload,
            },
          },
        });
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async createPatientTimelineTask(
      { dispatch, commit },
      taskBody: PatientTimelineTaskCreateBody,
    ) {
      try {
        const { data } = await apollo.mutate<Mutation>({
          mutation: CREATE_TIMELINE_TASK,
          variables: {
            input: taskBody,
          },
        });
        dispatch("getChecklistsFeedbacks");
        const task = data?.createPatientTimelineTask;
        if (task) {
          commit("addTimelineNewTasks", [task]);
        }
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },

    async createPatientTimelineEventTask(
      { dispatch, commit },
      {
        eventTaskBodies,
        isNonMedical = false,
      }: {
        eventTaskBodies: PatientTimelineEventWithTaskCreateInput;
        isNonMedical: boolean;
      },
    ) {
      try {
        const { data } = await apollo.mutate<Mutation>({
          mutation: isNonMedical
            ? CREATE_TIMELINE_NON_MEDICAL_EVENT_TASK
            : CREATE_TIMELINE_MEDICAL_EVENT_TASK,
          variables: {
            input: eventTaskBodies,
          },
        });
        dispatch("getChecklistsFeedbacks");
        const newData = isNonMedical
          ? data?.createPatientNonMedicalTimelineEventWithTask
          : data?.createPatientMedicalTimelineEventWithTask;
        commit("addTimelineNewEvents", newData?.timelineEvents);
        commit("setFirstEventDate", newData?.timelineEvents);
        commit("addTimelineNewTasks", newData?.timelineTasks);
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },
    async updatePatientTimelineEvent(
      { dispatch, commit },
      {
        eventBody,
        isNonMedical = false,
      }: {
        eventBody: PatientTimelineEventUpdateInput;
        isNonMedical: boolean;
      },
    ) {
      try {
        const { data } = await apollo.mutate<Mutation>({
          mutation: isNonMedical
            ? UPDATE_NON_MEDICAL_TIMELINE_EVENT
            : UPDATE_MEDICAL_TIMELINE_EVENT,
          variables: {
            input: eventBody,
          },
        });
        dispatch("getChecklistsFeedbacks");
        const updated = isNonMedical
          ? data?.updatePatientNonMedicalTimelineEvent
          : data?.updatePatientMedicalTimelineEvent;
        commit("updateTimelineEvents", [updated]);
        commit("setFirstEventDate", [updated]);
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },

    async updatePatientAlerts(
      { dispatch, commit },
      {
        taskBodies,
        isNonMedical = false,
      }: {
        taskBodies: PatientTimelineTaskUpdateBody[];
        isNonMedical: boolean;
      },
    ) {
      try {
        const { data } = await apollo.mutate<Mutation>({
          mutation: isNonMedical
            ? UPDATE_NON_MEDICAL_TIMELINE_ALERTS
            : UPDATE_MEDICAL_TIMELINE_ALERTS,
          variables: {
            input: taskBodies,
          },
        });
        dispatch("getChecklistsFeedbacks");
        const updated = isNonMedical
          ? data?.updatePatientNonMedicalAlerts
          : data?.updatePatientMedicalAlerts;
        commit("updateTimelineTasks", updated);
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },

    async updatePatientTimelineTask(
      { dispatch, commit },
      {
        taskBody,
      }: {
        taskBody: PatientTimelineTaskUpdateBody;
      },
    ) {
      try {
        const { data } = await apollo.mutate<Mutation>({
          mutation: UPDATE_TIMELINE_TASK,
          variables: {
            input: taskBody,
          },
        });
        dispatch("getChecklistsFeedbacks");
        commit("updateTimelineTasks", data?.updatePatientTimelineTask);
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },

    async createAlertFeedback(
      { dispatch, commit, rootGetters },
      feedback: IFeedbackInput,
    ) {
      try {
        const { data } = await createTimelineEventFeedbacks(
          rootGetters.rootOrganization?.id,
          feedback.patient,
          feedback,
          ["feedbackTargetId", "pk"],
        );
        const events = data?.map((f: IFeedback) => {
          return {
            id: f.feedbackTargetId,
            hasFeedback: true,
            feedbackId: f.pk,
          };
        });
        commit("updateTimelineEvents", events);
        commit("setFirstEventDate", events);
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },

    async deleteAlertFeedback(
      { dispatch, commit, rootGetters },
      {
        events,
        patientId,
      }: { events: ITimelineEventUniqueKey[]; patientId: string },
    ) {
      try {
        await deleteTimelineEventFeedbacks(
          rootGetters.rootOrganization?.id,
          patientId,
          events,
        );
        const eventsToUpdate: Partial<PatientTimelineEvent>[] = [];
        events.forEach((e) => {
          const id = e.sk.split("#")?.[6];
          if (id) {
            eventsToUpdate.push({
              id,
              hasFeedback: false,
              feedbackId: null,
            });
          }
        });
        commit("updateTimelineEvents", eventsToUpdate);
        commit("setFirstEventDate", eventsToUpdate);
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },

    async getPatientAllVitals(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_VITALS,
          variables: {
            patient: patientId,
            projections: getVitalProjections(),
          },
        });
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setPatientFields", data.patient);
          commit("setMergedStats");
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientFeedbacks(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const feedbackProjections = [
          "pk",
          "feedbackTargetId",
          "selectedOptions",
          "otherOption",
          "updatedAt",
          "provider",
        ];
        const { data } = await getTimelineEventFeedbacks(
          rootGetters.rootOrganization?.id,
          patientId,
          feedbackProjections,
        );

        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setPatientFields", {
            id: patientId,
            timelineEventFeedbacks: data || [],
          });
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientResources(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_RESOURCES,
          variables: {
            patient: patientId,
          },
        });
        commit("setPatientFields", data.patient);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async postResourceVitals(
      { commit, rootGetters },
      { vitals }: { vitals: PatientVitalsInputEntity[] },
    ) {
      try {
        await apollo.mutate<Mutation>({
          mutation: POST_RESOURCE_VITALS,
          variables: {
            input: {
              vitals,
            },
          },
        });
        commit("updateRawStats", {
          vitals,
          author: rootGetters.loggedInProviderId,
        });
        commit("setMergedStats");
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientVitals(
      { commit },
      { patientId, dates }: { patientId: string; dates?: string[] },
    ) {
      try {
        dates?.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        const startDate = dates?.[0];
        const endDate = dates?.[dates.length - 1];
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_VITALS,
          variables: {
            patient: patientId,
            projections: getVitalProjections(),
            startDate,
            endDate,
          },
        });
        if (router.currentRoute?.params?.patientId !== patientId) {
          return;
        }
        if (startDate == null || endDate == null) {
          commit("setPatientFields", data.patient);
        } else {
          commit("replaceVitals", data.patient?.vitals);
        }
        if (data.patient?.vitals?.length) {
          commit("setMergedStats");
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientNotifications(
      { commit, rootGetters },
      { patientId, updateList }: { patientId: string; updateList?: boolean },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_NOTIFICATIONS,
          variables: {
            patient: patientId,
            rootOrgId: rootGetters.rootOrganization?.id,
          },
        });
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setPatientFields", data.patient);
        }
        if (updateList) {
          commit("updatePatientListPatients", [
            {
              id: data.patient?.id,
              notifications: data.patient?.notifications,
            },
          ]);
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientSupportEvents(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await getPatientSupportEvents(
          rootGetters.rootOrganization?.id,
          patientId,
          [
            "id",
            "provider",
            "notes",
            "createdAt",
            "devices",
            "zendeskTicketId",
            "salesforceTicketId",
          ],
        );
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setPatientFields", {
            id: patientId,
            supportEvents: data || [],
          });
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientSupportTickets(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await getPatientSupportTickets(
          rootGetters.rootOrganization?.id,
          patientId,
        );
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setPatientFields", {
            id: patientId,
            supportTickets: data || [],
          });
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientEvents(
      { commit, rootGetters, dispatch },
      { patientId }: { patientId: string },
    ) {
      try {
        if (
          router.currentRoute?.params?.patientId === patientId &&
          rootGetters.rootOrganization?.id
        ) {
          // New events may contain new checklists and feedbacks
          dispatch("getChecklistsFeedbacks");
          const { data } = await apollo.query<Query>({
            query: GET_PATIENT_EVENTS,
            variables: {
              patient: patientId,
              rootOrgId: rootGetters.rootOrganization?.id,
              rootOrgIdString: rootGetters.rootOrganization?.id,
            },
          });
          commit("setPatientFields", data.patient);
          commit("setFirstEventDate", data.patient?.timelineEventsJSON);
          window.dispatchEvent(new Event("patient-events-updated"));
        }
        dispatch("getActiveEvents", { patientId });
        sessionStorage.setItem("lastUpdated", `${Date.now()}`);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async deletePatientVitals(
      { dispatch },
      options: { orgId: string; patientId: string; body: IDailyVitalsRemovals },
    ) {
      try {
        await deletePatientVital(
          options.orgId,
          options.patientId,
          options.body,
        );
        await dispatch("getPatientVitals", { patientId: options.patientId });
        dispatch("getPatientLatestVitals", {
          patientId: options.patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async undoPatientVitalsDelete(
      { dispatch },
      {
        orgId,
        patientId,
        body,
      }: { orgId: string; patientId: string; body: IDailyVitalsRemovals },
    ) {
      try {
        await undoVitalDeleting(orgId, patientId, body);
        await dispatch("getPatientVitals", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientReviewChecklists(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const patientReviewChecklists = await getPatientReviewChecklists(
          rootGetters.rootOrganization?.id,
          patientId,
        );
        if (router.currentRoute?.params?.patientId === patientId) {
          commit("setReviewChecklists", patientReviewChecklists.data);
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientReviewNotes(
      { commit, rootGetters, state },
      { patientId }: { patientId: string },
    ) {
      if (patientId == null || patientId != state.patient?.id) {
        return false;
      }
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_REVIEW_NOTES,
          variables: {
            patientId,
            orgId: rootGetters.rootOrganization?.id,
          },
        });
        commit("setPatientFields", data.patient);
        commit("setPatientNoteDrafts", data.patient);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientReviewNotes(
      { rootGetters },
      {
        patientId,
        noteId,
        notes,
      }: { patientId: string; noteId: string; notes: string },
    ) {
      try {
        await updatePatientReviewNotesDraft(
          rootGetters.rootOrganization?.id,
          patientId,
          {
            organizationId: rootGetters.rootOrganization?.id,
            patientId,
            noteId,
            notes,
          },
        );
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async removeTrace(
      _,
      {
        orgId,
        patientId,
        traceRemoval,
      }: {
        orgId: string;
        patientId: string;
        traceRemoval: IPatientTraceRemoval;
      },
    ) {
      await deleteRestPeriods(orgId, patientId, traceRemoval);
    },
    async undoRemoveTrace(
      _,
      {
        orgId,
        patientId,
        traceRemoval,
      }: {
        orgId: string;
        patientId: string;
        traceRemoval: IPatientTraceRemoval;
      },
    ) {
      await undoRestPeriods(orgId, patientId, traceRemoval);
    },

    async updatePatientViewers(
      { commit, state, rootGetters },
      {
        patientId,
        currentViewers,
        lastViewInfo,
      }: {
        patientId: string;
        currentViewers?: IProvider[];
        lastViewInfo?: ProviderPortalPageView;
      },
    ) {
      const viewersWithoutSelf = (currentViewers ?? []).filter(
        (viewer) => viewer.id !== rootGetters.providerId,
      );
      // Last view info is used to sort patient list by current provider last view dates
      // We should not update last view info if it is not related to current provider
      const isLastViewedBySelf =
        lastViewInfo && lastViewInfo.providerId === rootGetters.providerId;
      if (state.patient?.id === patientId) {
        commit("setPatient", {
          id: patientId,
          currentViewers: viewersWithoutSelf,
          ...(isLastViewedBySelf ? { lastViewInfo } : {}),
        });
      }
      commit("updatePatientListPatients", [
        {
          id: patientId,
          currentViewers: viewersWithoutSelf,
          ...(isLastViewedBySelf ? { lastViewInfo } : {}),
        },
      ]);
    },

    async addPatientToWatchlist(
      { rootGetters },
      {
        patient,
        title,
        notes,
      }: { patient: string; title: string; notes: string },
    ) {
      try {
        const organization = rootGetters.rootOrganization.id;
        await createPatientWatchlist(organization, patient, {
          organization,
          patient,
          title,
          notes,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientWatchlist(
      { rootGetters },
      payload: {
        pk: string;
        sk: string;
        title: string;
        notes: string;
        patient: string;
      },
    ) {
      try {
        await editPatientWatchlist(
          rootGetters.rootOrganization.id,
          payload.patient,
          {
            pk: payload.pk,
            sk: payload.sk,
            title: payload.title,
            notes: payload.notes,
          },
        );
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async removePatientWatchlist(
      { rootGetters },
      payload: {
        pk: string;
        sk: string;
        title: string;
        notes: string;
        patient: string;
      },
    ) {
      try {
        await deletePatientWatchlist(
          rootGetters.rootOrganization.id,
          payload.patient,
          {
            pk: payload.pk,
            sk: payload.sk,
            title: payload.title,
            notes: payload.notes,
          },
        );
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async completePatientTimelineTasks(
      { commit, dispatch },
      {
        patientId,
        taskKeys,
        completeNotes,
        usefulness,
        isNonMedical = false,
      }: {
        patientId: string;
        taskKeys: PatientTimelineTaskUniqueKey[];
        completeNotes: string | null;
        usefulness: string | null;
        isNonMedical: boolean;
      },
    ) {
      try {
        commit("dataPending");
        const { data } = await apollo.mutate<Mutation>({
          mutation: isNonMedical
            ? COMPLETE_NON_MEDICAL_TIMELINE_EVENT_TASK
            : COMPLETE_MEDICAL_TIMELINE_EVENT_TASK,
          variables: {
            input: {
              patient: patientId,
              taskKeys,
              completeNotes,
              usefulness,
            },
          },
        });
        dispatch("getChecklistsFeedbacks");
        const updated = isNonMedical
          ? data?.completePatientNonMedicalTimelineEventTask
          : data?.completePatientMedicalTimelineEventTask;
        commit("updateTimelineTasks", updated);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async undoPatientTimelineTasksComplete(
      { commit, dispatch },
      {
        patientId,
        taskKeys,
        isNonMedical = false,
      }: {
        patientId: string;
        taskKeys: PatientTimelineTaskUniqueKey[];
        isNonMedical: boolean;
      },
    ) {
      try {
        commit("dataPending");
        const { data } = await apollo.mutate<Mutation>({
          mutation: isNonMedical
            ? UNDO_COMPLETE_NON_MEDICAL_TIMELINE_EVENT_TASK
            : UNDO_COMPLETE_MEDICAL_TIMELINE_EVENT_TASK,
          variables: {
            input: {
              patient: patientId,
              taskKeys,
            },
          },
        });
        dispatch("getChecklistsFeedbacks");
        const updated = isNonMedical
          ? data?.undoPatientNonMedicalTimelineEventTaskComplete
          : data?.undoPatientMedicalTimelineEventTaskComplete;
        commit("updateTimelineTasks", updated);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async postponeTimelineTask(
      { commit, dispatch },
      {
        patientId,
        postponedTo,
        taskKeys,
        reasons,
      }: {
        patientId: string;
        postponedTo: number;
        taskKeys: PatientTimelineTaskUniqueKey[];
        reasons: string[] | null;
      },
    ) {
      try {
        commit("dataPending");
        const { data } = await apollo.mutate<Mutation>({
          mutation: POSTPONE_TIMELINE_TASK,
          variables: {
            input: {
              patient: patientId,
              postponedTo,
              taskKeys,
              reasons,
            },
          },
        });
        dispatch("getChecklistsFeedbacks");
        commit("updateTimelineTasks", data?.postponePatientTimelineEventTask);
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
        throw error;
      } finally {
        commit("dataReceived");
      }
    },

    async deletePatientTimelineTask(
      { commit },
      { pk, sk }: { pk: string; sk: string },
    ) {
      try {
        commit("dataPending");
        const { data } = await apollo.mutate<Mutation>({
          mutation: DELETE_TIMELINE_TASK,
          variables: {
            pk,
            sk,
          },
        });
        commit("deleteTimelineTasks", data?.deletePatientTimelineTask?.id);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async deletePatientTimelineEvent(
      { commit },
      {
        pk,
        sk,
        isNonMedical = false,
      }: { pk: string; sk: string; isNonMedical: boolean },
    ) {
      try {
        commit("dataPending");
        const { data } = await apollo.mutate<Mutation>({
          mutation: isNonMedical
            ? DELETE_NON_MEDICAL_TIMELINE_EVENT
            : DELETE_MEDICAL_TIMELINE_EVENT,
          variables: {
            pk,
            sk,
          },
        });
        const deleted = isNonMedical
          ? data?.deletePatientNonMedicalTimelineEvent
          : data?.deletePatientMedicalTimelineEvent;
        commit("deleteTimelineEvents", deleted?.id);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async undoPatientTimelineEventDelete(
      { commit, dispatch },
      {
        pk,
        sk,
        isNonMedical = false,
      }: { pk: string; sk: string; isNonMedical: boolean },
    ) {
      try {
        commit("dataPending");
        const { data } = await apollo.mutate<Mutation>({
          mutation: isNonMedical
            ? UNDO_NON_MEDICAL_TIMELINE_EVENT_DELETE
            : UNDO_MEDICAL_TIMELINE_EVENT_DELETE,
          variables: {
            pk,
            sk,
          },
        });
        dispatch("getChecklistsFeedbacks");
        const newEvent = isNonMedical
          ? data?.undoPatientNonMedicalTimelineEventDelete
          : data?.undoPatientMedicalTimelineEventDelete;
        if (newEvent) {
          commit("addTimelineNewEvents", [newEvent]);
          commit("setFirstEventDate", [newEvent]);
        }
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async undoPatientTimelineTaskDelete(
      { commit, dispatch },
      { pk, sk }: { pk: string; sk: string },
    ) {
      try {
        commit("dataPending");
        const { data } = await apollo.mutate<Mutation>({
          mutation: UNDO_TIMELINE_TASK_DELETE,
          variables: {
            pk,
            sk,
          },
        });
        dispatch("getChecklistsFeedbacks");
        const task = data?.undoDeletePatientTimelineTask;
        if (task) {
          commit("addTimelineNewTasks", [task]);
        }
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    getChecklistsFeedbacks({ dispatch }) {
      const patientId = router.currentRoute?.params?.patientId;
      dispatch("getPatientReviewChecklists", { patientId });
      dispatch("getPatientFeedbacks", { patientId });
    },

    resetPatientDetails({ commit }) {
      commit("resetPatientModule");
      commit("resetProfileModule");
      commit("resetMessageModule");
      commit("resetThresholdsModule");
      commit("resetSidebarModule");
      commit("resetChartModule");
      commit("resetTargetModule");
      commit("resetCampaignModule");
      commit("resetActivityLogsModule");
      commit("resetCarePlanModule");
    },
  },
  getters: {
    eventFeedbackMap(state) {
      const feedbackMap: Record<string, Feedback | undefined> = {};
      for (const f of state.feedbacks ?? []) {
        feedbackMap[f.feedbackTargetId] = f;
      }
      return feedbackMap;
    },
    getResourceAvailable: (state) => {
      return state.resourceAvailable;
    },
    isPatientFromRedox(state) {
      return (state.patient?.pii?.identifiers || []).length > 0;
    },
    patient(state) {
      return state.patient;
    },
    patientTimezone(state) {
      return state.patientTimezone;
    },
    patientEnrollmentDate(state) {
      return state.enrollmentDate;
    },
    patientName(state) {
      return `${state.patient?.pii?.firstName || ""} ${
        state.patient?.pii?.lastName || ""
      }`;
    },
    patientId(state) {
      return state.patient?.id;
    },
    patientPii(state) {
      return state.patient?.pii;
    },
    isPatientStateLoaded(state) {
      return state.patientStateLoaded;
    },
    patientIsActive(state) {
      return state.patientState?.isActivePatientState;
    },
    patientNotifications(state) {
      return state.notifications;
    },
    providersInCall(state) {
      return state.providersInCall;
    },
    patientCallEndState(state) {
      return state.patientCallEndState;
    },
    timelineItemsSortedByDate(state) {
      return getPatientTimelineItemsByDate(
        state.patient,
        state.timelineEvents || [],
        state.timelineTasks || [],
        state.appointments || [],
      );
    },
    timelineEvents(state) {
      return state.timelineEvents || [];
    },
    timelineTasks(state) {
      return state.timelineTasks || [];
    },
    timelineAppointments(state) {
      return state.appointments || [];
    },
    timelineEventIdMap: (state) => {
      const timelineEventIdMap: Record<
        string,
        PatientTimelineEvent | undefined
      > = {};
      for (const c of state.timelineEvents ?? []) {
        timelineEventIdMap[c.id] = c;
      }
      return timelineEventIdMap;
    },
    timelineTaskIdMap: (state) => {
      const timelineTaskIdMap: Record<string, PatientTimelineTask | undefined> =
        {};
      for (const c of state.timelineTasks ?? []) {
        timelineTaskIdMap[c.id] = c;
      }
      return timelineTaskIdMap;
    },
    lockedReviewNotes(state) {
      return state.lockedReviewNotes;
    },
    reviewNotesDraft(state) {
      return state.patientNoteDraftMap;
    },
    mergedStats(state) {
      return state.mergedStats;
    },
    regularVitals(_, rootGetters) {
      return getStatDataStructure(
        rootGetters.regularStatsMap,
        rootGetters.regularTimeline,
      );
    },
    granularVitals(_, rootGetters) {
      if (!rootGetters?.chartDisplayConfigs?.enable_charts_granularity) {
        return {};
      }
      return getStatDataStructure(
        rootGetters.granularStatsMap,
        rootGetters.granularTimeline,
      );
    },
    regularStatsMap(state) {
      return getStatsMap(state.mergedStats || []);
    },
    granularStatsMap(state, rootGetters) {
      if (!rootGetters?.chartDisplayConfigs?.enable_charts_granularity) {
        return {};
      }
      return getStatsMap(makeStatsGranular(state.mergedStats || []));
    },
    granularStatsList(_, rootGetters) {
      if (!rootGetters?.chartDisplayConfigs?.enable_charts_granularity) {
        return {};
      }
      return Object.values(rootGetters.granularStatsMap);
    },
    regularTimeline(state) {
      const endDate = moment.tz(
        Date.now(),
        state.patientTimezone || DEFAULT_TIMEZONE,
      );
      const TWO_WEEKS = endDate.clone().subtract(2, "weeks");
      const SIX_MONTH = endDate.clone().subtract(6, "month");
      let startDate =
        state.mergedStats?.[0]?.date ||
        SIX_MONTH.format(DATE_FORMAT_YYYY_MM_DD);
      if (
        moment
          .tz(startDate, state.patientTimezone || DEFAULT_TIMEZONE)
          .valueOf() > TWO_WEEKS.valueOf()
      ) {
        startDate = TWO_WEEKS.format(DATE_FORMAT_YYYY_MM_DD);
      }
      return getTimelineDates(
        startDate,
        endDate.format(DATE_FORMAT_YYYY_MM_DD),
      );
    },
    granularTimeline(_, rootGetters) {
      if (!rootGetters?.chartDisplayConfigs?.enable_charts_granularity) {
        return [];
      }
      return getGranularChartTimeline(rootGetters.regularTimeline || []);
    },
    vitals(state) {
      return state.vitals;
    },
    events(state) {
      const eventsMap: Record<string, PatientTimelineEvent[] | undefined> = {};
      for (const c of state.timelineEvents ?? []) {
        if (c.eventType != null) {
          eventsMap[c.eventType] = eventsMap[c.eventType] || [];
          eventsMap[c.eventType]?.push(c);
        }
      }
      return eventsMap;
    },
    latestVitals(state) {
      return state.latestVitals;
    },
    reviewChecklists(state) {
      return state.reviewChecklists?.filter((ch) => !ch.onboarding);
    },
    onboardingChecklists(state) {
      return state.reviewChecklists?.filter((ch) => ch.onboarding);
    },
    patientLocation(state) {
      return state.patientLocation;
    },
    sunriseSunsetTimes(state, rootGetters) {
      const location = state.patientLocation;
      if (
        rootGetters?.enableSolarEvents &&
        state.vitals &&
        location?.longitude &&
        location?.latitude
      ) {
        return getSolarEventsForPatient(
          location,
          rootGetters.regularTimeline || [],
        );
      }
      return [];
    },
    resources(state) {
      return state.resources;
    },
    supportedActiveEvents(state) {
      return (
        (state.supportTickets != null &&
          state.supportEvents?.filter((s) => {
            const status = state.supportTickets?.find(
              (z) =>
                z.id === s?.salesforceTicketId ||
                Number(z.id) === s?.zendeskTicketId,
            )?.status;
            return !(status === "solved" || status === "closed");
          })) ||
        []
      );
    },
    supportTickets(state) {
      return state.supportTickets || [];
    },
    watchlists(state) {
      return state.watchlists;
    },
    patientMessaging(state) {
      return state.messaging;
    },
    patientVideoCalls(state) {
      return state.videoCalls;
    },
    resourceStatuses(state) {
      return state.resourceStatuses;
    },
  },
  mutations: {
    resetPatientModule(state) {
      Object.assign(state, initialState);
    },
    setPatientCallEndState(state, status: IVideoEndCallState | null) {
      if (!state.patientCallEndState) {
        state.patientCallEndState = status;
      }
    },
    setPatientAvailability(state, data: { status: boolean }) {
      state.resourceAvailable = data.status;
    },
    setProvidersInCall(state, providers: Provider[]) {
      state.providersInCall = providers;
    },
    setPatient(state, patient: IPatientStructure) {
      state.patient = { ...state.patient, ...patient };
      state.patientTimezone =
        patient.pii?.timezone || state.patientTimezone || DEFAULT_TIMEZONE;
    },
    replaceVitals(state, vitals: IRawStat[]) {
      const updated: IRawStat[] = state.vitals || [];
      vitals.forEach((vital) => {
        !updated.some((sv) => sv.date === vital.date)
          ? updated.push(vital)
          : updated.splice(
              updated.findIndex((sv) => sv.date === vital.date),
              1,
              vital,
            );
      });
      return updated;
    },
    setPatientFields(state, patient: IPatient) {
      state.patientState = patient.currentState || state.patientState;
      state.resources = patient.resources || state.resources;
      state.lockedReviewNotes =
        patient.lockedReviewNotes || state.lockedReviewNotes;
      state.timelineTasks = patient.timelineTasksJSON || state.timelineTasks;
      state.timelineEvents = patient.timelineEventsJSON || state.timelineEvents;
      state.appointments = patient.appointmentsJSON || state.appointments;
      state.surveyAnswers = patient.surveyAnswers || state.surveyAnswers;
      state.vitals = patient.vitals || state.vitals;
      state.supportEvents = patient.supportEvents || state.supportEvents;
      state.supportTickets = patient.supportTickets || state.supportTickets;
      state.notifications = patient.notifications || state.notifications;
      state.patientTimezone =
        patient.timezone || state.patientTimezone || DEFAULT_TIMEZONE;
      state.watchlists = patient.watchlists || state.watchlists;
      state.providersInCall = patient.providersInCall || state.providersInCall;
      state.feedbacks = patient.timelineEventFeedbacks || state.feedbacks;
      state.enrollmentDate = patient.createdAt || state.enrollmentDate;
      state.messaging = patient.messaging ?? state.messaging;
      state.videoCalls = patient.videoCalls ?? state.videoCalls;
      state.duplicateName = patient.duplicateName ?? state.duplicateName;
      state.resourceStatuses =
        patient.resourceStatuses ?? state.resourceStatuses;
    },

    setLatestVitals(
      state,
      {
        vitals,
        surveys,
      }: { vitals: IPatientLatestVitals; surveys: IRawStat[] },
    ) {
      const emojis = surveys?.find((s) => s.emojis?.length);
      const survey = surveys?.find(
        (s) =>
          s.dailySymptoms?.length ||
          s.dailyBreathing?.length ||
          s.dailySwelling?.length,
      );
      // TODO if the survey latest readings are needed, use the sorted survey array
      state.latestVitals = {
        ...vitals,
        emojis: emojis ? [emojis] : state.latestVitals?.emojis,
        dailySymptoms: survey ? [survey] : state.latestVitals?.dailySymptoms,
      };
    },
    addTimelineNewEvents(state, eventBodies: PatientTimelineEvent[]) {
      state.timelineEvents = [...(state.timelineEvents || []), ...eventBodies];
      window.dispatchEvent(new Event("patient-events-updated"));
    },
    addTimelineNewTasks(state, taskBodies: PatientTimelineTask[]) {
      state.timelineTasks = [...(state.timelineTasks || []), ...taskBodies];
      window.dispatchEvent(new Event("patient-events-updated"));
    },
    updateTimelineEvents(state, eventBodies: PatientTimelineEvent[]) {
      state.timelineEvents =
        state.timelineEvents?.map((e) => {
          const event = eventBodies.find((t) => t.id === e.id);
          if (event) {
            return { ...e, ...event };
          }
          return e;
        }) || [];
      window.dispatchEvent(new Event("patient-events-updated"));
    },
    updateTimelineTasks(state, taskBodies: PatientTimelineTask[]) {
      state.timelineTasks =
        state.timelineTasks?.map((e) => {
          const task = taskBodies.find((t) => t.id === e.id);
          if (task) {
            return task;
          }
          return e;
        }) || [];
      window.dispatchEvent(new Event("patient-events-updated"));
    },
    deleteTimelineEvents(state, id: string) {
      state.timelineEvents =
        state.timelineEvents?.filter((e) => e.id !== id) || [];
      window.dispatchEvent(new Event("patient-events-updated"));
    },
    deleteTimelineTasks(state, id: string) {
      state.timelineTasks =
        state.timelineTasks?.filter((e) => e.id !== id) || [];
      window.dispatchEvent(new Event("patient-events-updated"));
    },
    setPatientLocation(state, location: IPatientLocation) {
      state.patientLocation = location;
    },
    setPatientStateLoaded(state) {
      state.patientStateLoaded = true;
    },
    setPatientNoteDrafts(state, patient: Patient) {
      state.patientNoteDraftMap[patient.id] = patient.reviewNotesDraft || [];
      state.patientNoteDraftMap = { ...state.patientNoteDraftMap };
    },
    setSurveyAnswers(state, answers: IRawStat[]) {
      state.surveyAnswers = answers || [];
    },
    setMergedStats(state) {
      SWorker.run(mergeStats, [state.surveyAnswers || [], state.vitals || []])
        .then((result: IRawStat[]) => {
          state.mergedStats = result.sort(
            (b, a) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          );
        })
        .catch(logger.error);
    },
    setFirstEventDate(state, events: PatientTimelineEvent[]) {
      const sortedEvents = [...(events || [])].sort(
        (a, b) => a.startDate - b.startDate,
      );
      state.earliestEventTimestamp = Math.min(
        sortedEvents[0]?.startDate || 0,
        state.earliestEventTimestamp || 0,
      );
    },
    updateRawStats(
      state,
      {
        vitals,
        author,
      }: { vitals: PatientVitalsInputEntity[]; author: string },
    ) {
      if (state.vitals == null) {
        return;
      }
      state.vitals = vitals.reduce((p, c) => {
        const vitalDate = moment
          .tz(c.timestamp, c.timezone || DEFAULT_TIMEZONE)
          .format(DATE_FORMAT_YYYY_MM_DD);
        const dateStatIndex = p.findIndex((s) => s.date === vitalDate);
        const rawStatOfDate =
          dateStatIndex > -1 ? p[dateStatIndex] : { date: vitalDate };

        Object.keys(c).forEach((key) => {
          rawStatOfDate[key] = rawStatOfDate[key] || [];
          rawStatOfDate[key].push([
            c.timestamp,
            (c as Record<string, string | number | undefined>)[key],
            c.device,
            { author },
          ]);
        });

        delete rawStatOfDate.timestamp;
        delete rawStatOfDate.device;
        delete rawStatOfDate.timezone;

        if (dateStatIndex > -1) {
          p[dateStatIndex] = rawStatOfDate;
        } else {
          p.push(rawStatOfDate);
        }
        return p;
      }, state.vitals);
    },
    setReviewChecklists(state, reviewChecklists: IPatientReviewChecklist[]) {
      state.reviewChecklists = reviewChecklists.map((r) => ({
        ...r.checklist,
        rId: r.id,
        completed: r.completed,
      })) as IChecklist[];
    },
    updateChecklistItem(state, item: IChecklistItem) {
      state.reviewChecklists = (state.reviewChecklists || []).map((c) => ({
        ...c,
        items: (c.items || []).map((i) =>
          i.id === item.id ? { ...i, completed: item.completed } : i,
        ),
      }));
    },
  },
};

export default module;
