import $http from "@/services/rest.service";

const BASE_PATH = "/pp/orgs";

export const getCampaignTemplates = async (
  orgId: string,
  ppSendable: boolean,
) => {
  return $http.get(`${BASE_PATH}/${orgId}/campaign-templates-list`, {
    params: { ppSendable },
  });
};

export const getCampaignTemplatesCards = async (
  orgId: string,
  deck: string,
) => {
  return $http.get(`${BASE_PATH}/${orgId}/campaign-template-cards`, {
    params: { deck },
  });
};
