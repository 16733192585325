import { HASHTAG } from "@/configs/constants";
import { getOrganizationPatientListItems } from "@/services/organization.service";
import { getProvidersList } from "@/services/provider.service";
import DataLoader from "dataloader";

export const patientListItemLoader = new DataLoader((keys: readonly string[]) =>
  batchGetPatientListItems(keys),
);
export const providersLoader = new DataLoader((keys: readonly string[]) =>
  batchGetProviders(keys),
);

const batchGetPatientListItems = async (ids: readonly string[]) => {
  const rootOrgId = ids[0].split(HASHTAG)[0];
  if (!rootOrgId) {
    throw new Error(`Unable to get root organization`);
  }
  const patientIds = [...new Set(ids.map((id) => id.split(HASHTAG)[1]))];
  const { data: patientData } = await getOrganizationPatientListItems(
    rootOrgId,
    patientIds,
  );
  return patientIds.map((id) => patientData[id]);
};

const providerProjections = [
  "id",
  "firstName",
  "lastName",
  "namePrefix",
  "photo",
];

const batchGetProviders = async (ids: readonly string[]) => {
  const rootOrgId = ids[0].split(HASHTAG)[0];
  if (!rootOrgId) {
    throw new Error(`Unable to get root organization`);
  }
  const providerIds = [...new Set(ids.map((id) => id.split(HASHTAG)[1]))];
  const { data: providersList } = await getProvidersList(
    rootOrgId,
    providerIds,
    providerProjections,
  );
  return providerIds.map((id) => providersList.find((p) => p.id === id));
};
