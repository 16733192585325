import moment from "moment";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  email,
  integer,
  length,
  max as maxLength,
} from "vee-validate/dist/rules";
import Vue from "vue";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
});

extend("phone", {
  validate(value) {
    return /^[+]*[0-9]{0,3}[-\s]*[(]?[0-9]{1,4}[)]?[-\s/0-9]*$/.test(value);
  },
});

extend("city", {
  validate(value) {
    return /.*\S.*/.test(value);
  },
});

extend("zip", {
  validate(value) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  },
});

extend("childrenCount", {
  validate(value) {
    return value >= 0 && value <= 20;
  },
});

extend("minDate", {
  params: ["min"],
  validate(value, { min }: Record<string, any>) {
    const selectedDate = moment(value);
    const minDate = moment(min);
    if (!selectedDate.isValid() || !minDate.isValid()) {
      return false;
    }
    return selectedDate.isSameOrAfter(minDate);
  },
});

extend("maxDate", {
  params: ["max"],
  validate(value, { max }: Record<string, any>) {
    const selectedDate = moment(value);
    const maxDate = moment(max);
    if (!selectedDate.isValid() || !maxDate.isValid()) {
      return false;
    }
    return selectedDate.isSameOrBefore(maxDate);
  },
});

extend("integer", integer);
extend("length", length);
extend("email", email);
extend("max", maxLength);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
