import {
  DATE_FORMAT_YYYY_MM_DD,
  KG_TO_LB_MULTIPLIER,
  riskClassMap,
  surveyGroupAnswersMap,
  TIME_FORMAT_HH_24,
} from "@/configs/constants";
import { sortDateOrder } from "@/factories/date-factory";
import { getNormalizedTemperatureValue } from "@/factories/patient-factory";
import moment from "moment-timezone";

import {
  IMetricDetails,
  IPatientStats,
  IPatientVitalDetails,
  IPatientVitals,
  IRawStat,
  IVital,
  IVitalMisc,
  IVitalTag,
} from "../../@types/patient";

export const getStatsMap = (rawStats: IRawStat[]) => {
  const statsMap: Record<string, IRawStat | undefined> = {};
  for (const c of rawStats ?? []) {
    statsMap[c.date] = c;
  }
  return statsMap;
};

export const getVitalsValues = (stats: IRawStat, type: string) => {
  const values = [];
  if (stats[type]) {
    const stat: [] = stats[type];
    if (
      type === "avgHr" ||
      type === "avgRr" ||
      type === "durationInBed" ||
      type === "durationInSleep"
    ) {
      const value: null | number = stat?.[stat.length - 1]
        ? stat?.[stat.length - 1][1]
        : null;
      values.push(value);
    } else {
      stat?.sort((a, b) => a?.[0] - b?.[0]);
      for (const v of stat) {
        if (v && v[1]) {
          // TODO remove this hack, once the daily analytics file is reporting in standardized lbs
          if (type === "weight") {
            values.push(getValue(v[1] * KG_TO_LB_MULTIPLIER, type));
          } else if (type === "medianTemperature" || type === "temperature") {
            values.push(getNormalizedTemperatureValue(v[1]));
          } else {
            values.push(getValue(v[1], type));
          }
        } else {
          values.push(null);
        }
      }
    }
  }
  return getAvgValue(values, type);
};

const getTags = (stats: IRawStat, type: string) => {
  return (
    stats[type]
      ?.map((v: IVital[]) => (v?.[3] as IVitalMisc)?.tags)
      ?.filter((v: IVitalTag | null) => v != null)
      ?.flat() || []
  );
};

export const getReportDataStructure = (
  stats: IRawStat[],
  patientTimezone?: string,
) => {
  let timestamp: string[] = [];
  const values: { [key: string]: (number | null)[] } = {
    emojis: [],
    diastolicBp: [],
    glucoseFasting: [],
    glucosePostMeal: [],
    glucosePreMeal: [],
    hr: [],
    inBed: [],
    o2: [],
    dailyBreathing: [],
    dailySwelling: [],
    dailySymptoms: [],
    pulseRate: [],
    rr: [],
    sbp: [],
    sleep: [],
    walk: [],
    weight: [],
    riskScore: [],
    riskClass: [],
    medianRestHr: [],
    maxRestHr: [],
    minRestHr: [],
    medianTemperature: [],
    maxTemperature: [],
    minTemperature: [],
    temperature: [],
    medianRestRr: [],
    maxRestRr: [],
    minRestRr: [],
  };
  const lastTimestamps = JSON.parse(JSON.stringify(values));
  const tags = JSON.parse(JSON.stringify(values));

  if (patientTimezone) {
    const patientDate = moment
      .tz(Date.now(), patientTimezone)
      .format(DATE_FORMAT_YYYY_MM_DD);
    const lastDay = moment.utc(Date.now()).format(DATE_FORMAT_YYYY_MM_DD);
    if (patientDate !== lastDay) {
      stats = stats.filter((s) => s.date !== lastDay);
    }
  }
  if (stats.length) {
    timestamp = sortDateOrder(stats.map((s) => s.date));
    for (const t of timestamp) {
      for (const s of stats) {
        if (s.date === t) {
          values.hr.push(getVitalsValues(s, "avgHr"));
          values.rr.push(getVitalsValues(s, "avgRr"));
          values.sleep.push(getVitalsValues(s, "durationInSleep"));
          values.inBed.push(getVitalsValues(s, "durationInBed"));
          values.pulseRate.push(getVitalsValues(s, "pulseRate"));
          values.weight.push(getVitalsValues(s, "weight"));
          values.emojis.push(getVitalsValues(s, "emojis"));
          values.sbp.push(getVitalsValues(s, "systolicBp"));
          values.diastolicBp.push(getVitalsValues(s, "diastolicBp"));
          values.o2.push(getVitalsValues(s, "o2"));

          tags.hr.push(getTags(s, "avgHr"));
          tags.rr.push(getTags(s, "avgRr"));
          tags.sleep.push(getTags(s, "durationInSleep"));
          tags.inBed.push(getTags(s, "durationInBed"));
          tags.pulseRate.push(getTags(s, "pulseRate"));
          tags.weight.push(getTags(s, "weight"));
          tags.emojis.push(getTags(s, "emojis"));
          tags.sbp.push(getTags(s, "systolicBp"));
          tags.diastolicBp.push(getTags(s, "diastolicBp"));
          tags.o2.push(getTags(s, "o2"));

          lastTimestamps.hr.push(getLatestTimeStamp(s, "avgHr"));
          lastTimestamps.rr.push(getLatestTimeStamp(s, "avgRr"));
          lastTimestamps.sleep.push(getLatestTimeStamp(s, "durationInSleep"));
          lastTimestamps.inBed.push(getLatestTimeStamp(s, "durationInBed"));
          lastTimestamps.pulseRate.push(getLatestTimeStamp(s, "pulseRate"));
          lastTimestamps.weight.push(getLatestTimeStamp(s, "weight"));
          lastTimestamps.sbp.push(getLatestTimeStamp(s, "systolicBp"));
          lastTimestamps.diastolicBp.push(getLatestTimeStamp(s, "diastolicBp"));
          lastTimestamps.emojis.push(getLatestTimeStamp(s, "emojis"));
          lastTimestamps.o2.push(getLatestTimeStamp(s, "o2"));
        }
      }
    }
  }
  const newStats: IPatientStats = {};
  newStats.timestamp = timestamp || [];
  newStats.tags = tags || [];
  newStats.vitals = generateVitals(values, timestamp, lastTimestamps);
  newStats.rawStats = stats;
  return newStats;
};

export const getStatDataStructure = (
  stats: Record<string, IRawStat>,
  timestamps: string[],
) => {
  const typeMap: { [key: string]: string } = {
    hr: "avgHr",
    pulseRate: "pulseRate",
    rr: "avgRr",
    o2: "o2",
    sleep: "durationInSleep",
    weight: "weight",
    inBed: "durationInBed",
    emojis: "emojis",
    sbp: "systolicBp",
    diastolicBp: "diastolicBp",
    glucoseFasting: "glucoseFasting",
    glucosePostMeal: "glucosePostMeal",
    glucosePreMeal: "glucosePreMeal",
    dailySymptoms: "dailySymptoms",
    dailyBreathing: "dailyBreathing",
    dailySwelling: "dailySwelling",
    riskScore: "riskScore",
    riskClass: "riskClass",
    medianRestHr: "medianRestHr",
    maxRestHr: "maxRestHr",
    minRestHr: "minRestHr",
    medianRestRr: "medianRestRr",
    maxRestRr: "maxRestRr",
    minRestRr: "minRestRr",
    medianTemperature: "medianTemperature",
    maxTemperature: "maxTemperature",
    minTemperature: "minTemperature",
    temperature: "temperature",
  };

  const values: { [key: string]: (number | null)[] } = {};
  Object.keys(typeMap).forEach((key) => {
    values[key] = [];
  });
  const lastTimestamps = JSON.parse(JSON.stringify(values));
  const tags = JSON.parse(JSON.stringify(values));

  timestamps.forEach((t) => {
    const s = stats[t];
    if (s) {
      Object.keys(typeMap).forEach((key) => {
        values[key].push(getVitalsValues(s, typeMap[key]));
        tags[key].push(getTags(s, typeMap[key]));
        lastTimestamps[key].push(getLatestTimeStamp(s, typeMap[key]));
      });
    } else {
      Object.keys(typeMap).forEach((key) => {
        values[key].push(null);
        tags[key].push(null);
        lastTimestamps[key].push(null);
      });
    }
  });
  const newStats: IPatientStats = {};
  newStats.tags = tags || [];
  newStats.vitals = generateVitals(values, timestamps, lastTimestamps);
  return newStats;
};

const getLatestTimeStamp = (stats: any, type: string) => {
  const statsOfKind = [...(stats[type] || [])];
  let timestamp: string = "";
  if (statsOfKind && statsOfKind.length) {
    statsOfKind.sort((a: any, b: any) => b[0] - a[0]);
    timestamp = statsOfKind[0]?.[0];
  }
  return timestamp;
};

const generateVitals = (
  statValues: any,
  timestamp: string[],
  lastTimestamps: any,
) => {
  const vitals: any = {};
  const types = [
    "pulseRate",
    "hr",
    "rr",
    "sleep",
    "sbp",
    "diastolicBp",
    "glucoseFasting",
    "glucosePostMeal",
    "glucosePreMeal",
    "o2",
    "weight",
    "inBed",
    "emojis",
    "dailySymptoms",
    "dailyBreathing",
    "dailySwelling",
    "walk",
    "riskClass",
    "riskScore",
    "medianRestHr",
    "maxRestHr",
    "minRestHr",
    "medianRestRr",
    "maxRestRr",
    "minRestRr",
    "medianTemperature",
    "maxTemperature",
    "minTemperature",
    "temperature",
  ];
  for (const type of types) {
    vitals[type] = generateVitalsDetails(
      statValues,
      type,
      timestamp,
      lastTimestamps,
    );
  }
  return vitals as IPatientVitals;
};

const generateVitalsDetails = (
  statValues: any,
  type: string,
  timestamp: string[],
  lastTimestamps: any,
) => {
  if (statValues[type] == null) {
    return {};
  }
  const details: IPatientVitalDetails = {};
  details.avg = getAvgValue(statValues[type], type);
  details.high = getVitalDetails(statValues[type], timestamp, true);
  if (type === "emojis") {
    details.high.mostOften = getMostOftenValue(statValues[type]).value;
    details.high.count = getMostOftenValue(statValues[type]).count;
  }
  details.low = getVitalDetails(statValues[type], timestamp);
  details.values = statValues[type];
  const lastData = getLastUpdatedData(statValues[type], lastTimestamps[type]);
  details.lastUpdated = lastData.date;
  details.value = lastData.value;
  return details;
};

const getMostOftenValue = (values: (number | null)[]) => {
  const emojis = [0, 0, 0, 0, 0];
  if (values.length) {
    for (const v of values) {
      if (v) {
        emojis[v - 1]++;
      }
    }
  }
  const modeValue = Math.max(...emojis);

  return {
    count:
      emojis.indexOf(modeValue) === emojis.lastIndexOf(modeValue)
        ? modeValue
        : 0,
    value: emojis.indexOf(modeValue) + 1,
  };
};

export const makeStatsGranular = (stats: IRawStat[]) => {
  const granularStats: IRawStat[] = [];
  stats.forEach((s) => {
    granularStats.push(...breakStats(s));
  });
  return granularStats;
};

const breakStats = (stat: IRawStat) => {
  const statsMap: Record<string, IRawStat> = {};
  for (const [type, readings] of Object.entries(stat)) {
    if (!readings) {
      continue;
    }
    if (
      type === "avgHr" ||
      type === "avgRr" ||
      type === "durationInBed" ||
      type === "durationInSleep"
    ) {
      const lastReading = readings[readings.length - 1] || null;
      const timestamp = getStatNormalizedTime(lastReading?.[0]);
      statsMap[timestamp] = statsMap[timestamp] || { date: timestamp };
      statsMap[timestamp][type] = [lastReading];
    } else if (type !== "date") {
      readings?.forEach((r: any) => {
        const timestamp = getStatNormalizedTime(r?.[0]);
        statsMap[timestamp] = statsMap[timestamp] || { date: timestamp };
        statsMap[timestamp][type] = statsMap[timestamp][type] || [];
        statsMap[timestamp][type].push(r);
      });
    }
  }
  return Object.values(statsMap) || [];
};

export const getStatNormalizedTime = (timestamp: number) => {
  return moment(timestamp).format(
    `${DATE_FORMAT_YYYY_MM_DD}T${TIME_FORMAT_HH_24}`,
  );
};

const getValue = (value: number | string, type: string) => {
  switch (type) {
    case "emojis":
      return reverseEmojiValue(Number(value));
    case "dailySymptoms":
    case "dailyBreathing":
    case "dailySwelling":
      return surveyGroupAnswersMap[type][value];
    case "riskClass":
      return riskClassMap[value];
    default:
      return Number(value);
  }
};

export const getAvgValue = (values: (number | null)[], type?: string) => {
  const data = values.filter((v) => v != null && !isNaN(v)) as number[];
  if (data.length === 0) {
    return null;
  }
  const value =
    data.reduce(
      (p, c) => (isNaN(p) ? 0 : Number(p)) + (isNaN(c) ? 0 : Number(c)),
    ) / data.length;

  if (type === "durationInSleep" || type === "sleep") {
    return value;
  } else if (type === "emojis") {
    return Math.floor(value);
  }
  return Math.round(value * 10) / 10;
};

export const reverseEmojiValue = (value: number) => {
  const maxValue = 5;
  const minValue = 1;
  return maxValue + minValue - Math.round(value);
};

export const mergeStats = (activity: IRawStat[], vitals: IRawStat[]) => {
  const hashMap: { [key: string]: IRawStat } = {};
  const biointellisenseIdPart = "tracker_bcg_biointellisense_core";
  const continuesDataTypes = [
    "avgHr",
    "avgRr",
    "maxHr",
    "minHr",
    "minRr",
    "maxRr",
    "durationInSleep",
    "durationInBed",
  ];
  const multipleDataTypes = [
    "weight",
    "pulseRate",
    "o2",
    "riskScore",
    "riskClass",
    "systolicBp",
    "diastolicBp",
    "glucoseFasting",
    "glucosePreMeal",
    "glucosePostMeal",
    "medianRestHr",
    "maxRestHr",
    "minRestHr",
    "medianRestRr",
    "maxRestRr",
    "minRestRr",
    "temperature",
    "medianTemperature",
    "maxTemperature",
    "minTemperature",
  ];

  activity.forEach((a) => {
    hashMap[a.date] = hashMap[a.date] || {};
    hashMap[a.date].date = a.date;
    hashMap[a.date].emojis = a.emojis;
    hashMap[a.date].dailySymptoms = a.dailySymptoms;
    hashMap[a.date].dailyBreathing = a.dailyBreathing;
    hashMap[a.date].dailySwelling = a.dailySwelling;
  });

  vitals.forEach((v) => {
    hashMap[v.date] = hashMap[v.date] || {};
    hashMap[v.date].date = v.date;
    continuesDataTypes.forEach((type) => {
      hashMap[v.date][type] = v[type]?.splice(-1);
    });
    multipleDataTypes.forEach((type) => {
      if (type === "pulseRate") {
        hashMap[v.date][type] = hashMap[v.date][type] || [];
        hashMap[v.date].medianRestHr = hashMap[v.date].medianRestHr || [];
        (v?.[type] || [])?.forEach((r) => {
          if (r[2].includes(biointellisenseIdPart)) {
            hashMap[v.date].medianRestHr.push(r);
          } else {
            hashMap[v.date][type]?.push(r);
          }
        });
        hashMap[v.date].medianRestHr =
          hashMap[v.date].medianRestHr.filter((r: any) => r?.[1]) || [];
      } else {
        hashMap[v.date][type] = hashMap[v.date][type] || [];
        hashMap[v.date][type] = hashMap[v.date][type].concat(v?.[type] || []);
        if (type.includes("risk")) {
          hashMap[v.date][type] = hashMap[v.date][type]?.concat(
            v?.[`DATA___${type}`] || [],
          );
        }
      }
      hashMap[v.date][type] =
        hashMap[v.date][type].filter((r: any) => r?.[1]) || [];
    });
  });
  return Object.values(hashMap);
};

const getLastUpdatedData = (
  values: (number | null)[],
  timestamps: string[],
) => {
  const lastDateIndex = [...values].reverse().findIndex((v) => v != null);
  if (lastDateIndex == null) {
    return { date: "", value: null };
  }

  const index = values.length - lastDateIndex - 1;
  return {
    date: timestamps[index],
    value: values[index],
  };
};

const getVitalDetails = (
  values: (number | null)[],
  time: string[],
  high?: boolean,
): IMetricDetails => {
  if (values.length === 0) {
    return {
      date: "",
      value: null,
    };
  }
  const value = values.reduce((p, c) => {
    if (p == null) {
      return c;
    }
    if (c == null) {
      return p;
    }
    return high ? Math.max(p, c) : Math.min(p, c);
  });
  return {
    date: time[values.indexOf(value)],
    value,
  };
};

export const truncateBodytraceValue = (value: number) => {
  return Math.floor(value * 10) / 10;
};
