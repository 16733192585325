import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { OperationDefinitionNode } from "graphql";
import fetch from "unfetch";
import {
  GET_CHANNEL_MESSAGES,
  GET_PATIENT_CHANNELS,
} from "./graphql/queries/message.query";
import logger from "./logger";
import router, { PUBLIC_PAGES } from "./router/routes";
import {
  isAuthenticated,
  isExpired,
  logout,
  silentAuthentication,
  token,
} from "./services/auth.service";
import store from "./store/store";

// Errors from this calls will not be populated in UI
const ERROR_POLICY_IGNORE = [GET_PATIENT_CHANNELS, GET_CHANNEL_MESSAGES];

const isErrorsAllowed = (operationName: string) => {
  return !ERROR_POLICY_IGNORE.some((q) => {
    const definitionNames = (q.definitions as OperationDefinitionNode[]).map(
      (d) => d?.name?.value || "",
    );
    return definitionNames.includes(operationName);
  });
};

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_API_PATH,
  fetch: fetch as any,
});

const authLink = setContext(async (_, { headers }) => {
  const headersCopy = {
    "x-request-origin": "provider-portal",
    ...headers,
  };

  if (store.getters?.rootOrganization?.id) {
    headersCopy["x-request-organization"] = store.getters.rootOrganization.id;
  }

  if (isAuthenticated() && !isExpired()) {
    headersCopy.authorization = `Bearer ${token()}`;
  } else {
    try {
      if (PUBLIC_PAGES.includes(router.currentRoute.name || "")) {
        return {
          headers: headersCopy,
        };
      }
      await silentAuthentication();
      headersCopy.authorization = `Bearer ${token()}`;
    } catch (error) {
      logger.error(error as Error);
      if (!router.currentRoute.meta?.public) {
        logout();
      }
    }
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: headersCopy,
  };
});

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (!isErrorsAllowed(operation.operationName)) {
    return;
  }
  if (graphQLErrors != null) {
    graphQLErrors.map(({ message, extensions }) => {
      store?.dispatch("populateHttpErrors", {
        message,
        statusText: extensions?.code,
      });
    });
  }
});

// Cache implementation
const cache = new InMemoryCache({ addTypename: false });

// Create the apollo client
export default new ApolloClient({
  cache,
  // Disable cache as we are using vuex to update UI
  defaultOptions: {
    query: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      errorPolicy: "ignore",
      fetchPolicy: "no-cache",
    },
  },
  link: ApolloLink.from([errorLink, authLink, httpLink]),
});
