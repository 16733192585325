
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MyiaInputRadio extends Vue {
  @Prop(String) private selectedItem!: string;
  @Prop(String) private item!: string;
  @Prop(String) private name!: string;
  @Prop(String) private label!: string;
  @Prop(Boolean) private disabled!: boolean;

  private get labelId() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  private handleSelect(item: string) {
    this.$emit("change", item);
  }

  private get isChecked() {
    return this.selectedItem === this.item;
  }
}
