
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DATE_FORMAT_M_D_YYYY,
  DATE_FORMAT_YYYY_MM_DD,
} from "@/configs/constants";

@Component
export default class MyiaInputCalendar extends Vue {
  @Prop([Number, String]) private value!: string;
  @Prop(String) private width!: string;
  @Prop([Number, String]) private minDate!: number | string;
  @Prop([Number, String]) private maxDate!: number | string;
  @Prop({ default: true, type: Boolean }) private showArrows!: boolean;

  private localValue: number | string = "";
  private timezone: string = "UTC";
  private datePickerOpened: boolean = false;

  private mounted() {
    this.localValue = this.$moment(this.value).format(DATE_FORMAT_YYYY_MM_DD);
  }

  private get localFormattedValue() {
    return this.$moment
      .utc(this.localValue)
      .format(DATE_FORMAT_M_D_YYYY)
      .valueOf();
  }

  private chooseDay(event: any) {
    this.localValue =
      this.$moment(event.id).valueOf() > this.$moment(this.maxDate).valueOf()
        ? this.$moment(this.maxDate).format(DATE_FORMAT_YYYY_MM_DD)
        : event.id;
    this.datePickerOpened = false;
    this.$emit("change", this.localValue);
  }

  private updateDateTime(direction: number) {
    this.datePickerOpened = false;
    this.localValue = this.$moment
      .utc(this.localValue)
      .clone()
      .add(direction, "day")
      .format(DATE_FORMAT_YYYY_MM_DD);
    this.$emit("change", this.localValue);
  }

  private get calendarAttributes() {
    return [{ highlight: true }];
  }

  private get disableNext() {
    return (
      (this.maxDate &&
        this.$moment(this.maxDate).format(DATE_FORMAT_YYYY_MM_DD) ===
          this.localValue) ||
      this.$moment(this.maxDate).valueOf() <
        this.$moment(this.localValue).valueOf()
    );
  }

  private get disablePrevious() {
    return (
      this.minDate &&
      this.$moment(this.minDate).format(DATE_FORMAT_YYYY_MM_DD) ===
        this.localValue
    );
  }
}
