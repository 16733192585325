import logger from "@/logger";
import { Auth0DecodedHash, WebAuth } from "auth0-js";
import { TIMEOUT_IN_MILLISECONDS } from "@/configs/constants";
import { debounce } from "debounce";

const Auth0 = new WebAuth({
  audience: "",
  clientID: process.env.VUE_APP_AUTH_CLIENT_ID || "",
  domain: process.env.VUE_APP_AUTH_API_PATH || "",
  redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI,
  responseType: "token id_token",
  scope: "openid offline_access",
});

export const login = () => {
  Auth0.authorize();
};

let refreshTokenPromise: Promise<unknown> | null = null;

export const silentAuthentication = () => {
  if (refreshTokenPromise == null) {
    refreshTokenPromise = new Promise((resolve, reject) => {
      Auth0.checkSession({}, (err, authResult) => {
        refreshTokenPromise = null;
        if (err) {
          reject(err);
        } else {
          setSession(authResult);
          resolve(null);
        }
      });
    });
  }
  return refreshTokenPromise;
};

export const logout = () => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("loginViewRegistered");
  localStorage.removeItem("lastInteraction");
  Auth0.logout({
    clientID: process.env.VUE_APP_AUTH_CLIENT_ID,
    returnTo: process.env.VUE_APP_AUTH_REDIRECT_URI,
  });
};

export const silentLogout = () => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("loginViewRegistered");
};

export const setSession = (authData: any) => {
  const expiresAt = authData.expiresIn * 1000 + Date.now();
  localStorage.setItem("access_token", authData.accessToken);
  localStorage.setItem("id_token", authData.idToken);
  localStorage.setItem("expires_at", expiresAt.toString());
  localStorage.setItem("refresh_token", authData.refreshToken);
  localStorage.setItem("loggedInProviderId", authData.idTokenPayload.sub);
};

export const isAuthenticated = () => {
  const expiresAt = localStorage.getItem("expires_at");
  const refreshToken = localStorage.getItem("refresh_token");
  return expiresAt != null && refreshToken != null;
};

export const isExpired = () => {
  const expiresAt = localStorage.getItem("expires_at");
  try {
    return expiresAt == null || new Date() > new Date(Number(expiresAt));
  } catch (error) {
    logger.error(error as Error);
    return false;
  }
};

export const isTimedOut = () => {
  const lastTimestamp = Number(localStorage.getItem("lastInteraction"));
  return lastTimestamp && Date.now() - lastTimestamp > TIMEOUT_IN_MILLISECONDS;
};

export const resetLastInteraction = debounce(() => {
  localStorage.setItem("lastInteraction", `${Date.now()}`);
}, 500);

export const token = () => localStorage.getItem("id_token");

export const handleAuthentication = async () =>
  new Promise<Auth0DecodedHash>((resolve, reject) => {
    Auth0.parseHash((error, result) => {
      if (error || result == null) {
        return reject(error || new Error("Empty auth result"));
      }
      resolve(result);
    });
  });
