import { Query } from "generated/types-gql";
import { Module } from "vuex";

import apollo from "@/apollo";
import {
  ProviderPatientActivityTypes,
  sortTargetsByType,
} from "@/factories/patient-factory";
import { GET_PATIENT_PAGINATED_ACTIVITY_LOGS } from "@/graphql/queries/patient.query";
import logger from "@/logger";
import router from "@/router/routes";
import {
  createPatientTarget,
  deletePatientTarget,
  editPatientTarget,
  getPatientTargets,
  undoTargetDelete,
} from "@/services/patient.service";

import {
  IPatientTarget,
  IProviderPatientActivity,
} from "../../../@types/patient";
import { IRootState, ITargetModuleState } from "../../../@types/store";

const initialState: ITargetModuleState = {
  patientTargetMap: {},
  targetsActivityLogs: null,
};

const module: Module<ITargetModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getTargets(
      { commit, dispatch },
      { orgId, patientId }: { orgId: string; patientId: string },
    ) {
      try {
        const targets = (await getPatientTargets(orgId, patientId)).data;
        commit("setPatientTargets", {
          targets: sortTargetsByType(targets),
          patientId,
        });
        dispatch("getTargetsActivityLogs", { patientId });
      } catch (error) {
        logger.error(error as Error);
      }
    },
    async getTargetsActivityLogs({ commit, rootGetters }, { patientId }) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_PAGINATED_ACTIVITY_LOGS,
          variables: {
            patient: patientId,
            rootOrgId: rootGetters.rootOrganization?.id,
            excludeLogsOfType: [
              ProviderPatientActivityTypes.addTaggedEvent,
              ProviderPatientActivityTypes.answerPRO,
              ProviderPatientActivityTypes.completeTaggedEvent,
              ProviderPatientActivityTypes.completeTaggedEventUndo,
              ProviderPatientActivityTypes.deleteTaggedEvent,
              ProviderPatientActivityTypes.deleteTaggedEventUndo,
              ProviderPatientActivityTypes.deleteTrace,
              ProviderPatientActivityTypes.deleteTraceUndo,
              ProviderPatientActivityTypes.deleteVital,
              ProviderPatientActivityTypes.deleteVitalUndo,
              ProviderPatientActivityTypes.editTaggedEvent,
              ProviderPatientActivityTypes.reviewTaggedEvent,
              ProviderPatientActivityTypes.editThreshold,
              ProviderPatientActivityTypes.finishPRO,
              ProviderPatientActivityTypes.patientJoined,
              ProviderPatientActivityTypes.patientView,
              ProviderPatientActivityTypes.reschedule,
              ProviderPatientActivityTypes.rescheduleUndo,
              ProviderPatientActivityTypes.reviewComplete,
              ProviderPatientActivityTypes.reviewCompleteUndo,
              ProviderPatientActivityTypes.sendingPRO,
              ProviderPatientActivityTypes.supportRequest,
              ProviderPatientActivityTypes.supportResolve,
              ProviderPatientActivityTypes.thresholdWarning,
              ProviderPatientActivityTypes.addTimelineEvent,
              ProviderPatientActivityTypes.addTimelineTask,
              ProviderPatientActivityTypes.editTimelineEvent,
              ProviderPatientActivityTypes.editTimelineTask,
              ProviderPatientActivityTypes.completeTimelineTask,
              ProviderPatientActivityTypes.completeTimelineTaskUndo,
              ProviderPatientActivityTypes.deleteTimelineEvent,
              ProviderPatientActivityTypes.deleteTimelineTask,
              ProviderPatientActivityTypes.deleteTimelineEventUndo,
              ProviderPatientActivityTypes.deleteTimelineTaskUndo,
              ProviderPatientActivityTypes.sendingEduMaterial,
            ],
          },
        });
        commit(
          "setTargetsActivityLogs",
          data?.patient?.paginatedActivityLogsJSON,
        );
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async addNewTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        target,
      }: {
        orgId: string;
        patientId: string;
        target: IPatientTarget;
      },
    ) {
      try {
        commit("dataPending");
        const newTarget = await createPatientTarget(orgId, patientId, target);
        commit("selectTarget", newTarget.data);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },

    async editTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        target,
      }: {
        orgId: string;
        patientId: string;
        target: IPatientTarget;
      },
    ) {
      try {
        commit("dataPending");
        const newTarget = await editPatientTarget(orgId, patientId, target);
        commit("selectTarget", newTarget.data);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },

    async deleteTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        targetId,
      }: {
        orgId: string;
        patientId: string;
        targetId: string;
      },
    ) {
      try {
        commit("dataPending");
        await deletePatientTarget(orgId, patientId, targetId);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
        throw error;
      } finally {
        commit("dataReceived");
      }
    },

    async undoTarget(
      { commit, dispatch },
      {
        orgId,
        patientId,
        targetId,
      }: {
        orgId: string;
        patientId: string;
        targetId: string;
      },
    ) {
      try {
        commit("dataPending");
        await undoTargetDelete(orgId, patientId, targetId);
        dispatch("getTargets", { orgId, patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },
  },
  getters: {
    patientTargetMap: (state) => {
      return state.patientTargetMap || {};
    },
    patientTargets: (state) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientTargetMap?.[patientId] || [];
    },
    patientActivityTargetById: (state) => (id: string) => {
      if (!id) {
        return [];
      }
      return state.targetsActivityLogs?.filter(
        (i) => i.details?.target?.id === id,
      );
    },
  },
  mutations: {
    setPatientTargets(
      state,
      data: { targets: IPatientTarget[]; patientId: string },
    ) {
      state.patientTargetMap[data.patientId] = data.targets;
      state.patientTargetMap = { ...state.patientTargetMap };
    },
    setTargetsActivityLogs(state, data: IProviderPatientActivity[] | null) {
      state.targetsActivityLogs = data;
    },
    resetTargetModule(state) {
      state.patientTargetMap = {};
      state.targetsActivityLogs = null;
    },
  },
};

export default module;
