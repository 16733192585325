import apollo from "@/apollo";
import {
  ImAuthorTypeEnum,
  ImMessageTypeEnum,
  PAGINATION_LIMITS,
} from "@/configs/constants";
import { replaceSubstitutions } from "@/factories/substitution-factory";
import {
  MARK_IM_MESSAGE_SEEN,
  PUT_AUDIO_MESSAGE,
  PUT_EDU_MATERIAL_MESSAGE,
  PUT_IMG_MESSAGE,
  PUT_TEXT_MESSAGE,
  PUT_VIDEO_MESSAGE,
} from "@/graphql/mutations/message.mutation";
import {
  GET_CHANNEL_MESSAGES,
  GET_CHANNEL_PAGINATED_MESSAGES_WITH_HELP_REQUESTS,
  GET_PATIENT_CHANNELS,
  GET_PATIENT_MISSED_MESSAGES,
} from "@/graphql/queries/message.query";
import logger from "@/logger";
import router from "@/router/routes";
import { Module } from "vuex";

import { IPatient } from "../../../@types/patient";
import { IPatientMessagesModuleState, IRootState } from "../../../@types/store";
import {
  ImAudioMessageInput,
  ImChannelMessage,
  ImEduMaterialMessageInput,
  ImImageMessageInput,
  ImTextMessageInput,
  ImVideoMessageInput,
  Mutation,
  Patient,
  Query,
} from "../../../generated/types-gql";
import { getOrganizationQuickMessages } from "@/services/organization.service";
import { IQuickMessage } from "../../../@types/organization";

const initialState: IPatientMessagesModuleState = {
  patientMessageMap: {},
  channelId: null,
  cursorStart: null,
  allMessagesLoaded: false,
  messageListScrollPosition: null,
  quickMessages: null,
  unreadMessagesCount: null,
  earliestMissedMessage: null,
  lastReadRequiredTimestamp: null,
  lastSeenMessageAt: null,
  lastSeenMessageId: null,
  sendMessageSuccess: false,
  sendMessageLoading: false,
  sendMessageError: null,
};

const module: Module<IPatientMessagesModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getQuickMessages({ commit, rootGetters }) {
      try {
        const { data: quickMessages } = await getOrganizationQuickMessages(
          rootGetters.rootOrganization?.id,
          true,
        );
        commit("setQuickMessages", quickMessages);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientChannels(
      { rootGetters, commit, dispatch },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_CHANNELS,
          variables: {
            id: patientId,
            orgIds: [rootGetters.rootOrganization.id],
          },
        });
        const patientChannel = data.patient?.imChannels?.find(
          (c) =>
            c.type === "OrgPatientStream" &&
            c.orgId === rootGetters.rootOrganization.id,
        );
        if (patientChannel != null) {
          commit("setChannelId", patientChannel.id);
          dispatch("getChannelMessages", {
            patientId,
            channelId: patientChannel.id,
          });
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getChannelMessages(
      { state, dispatch, commit, rootGetters },
      {
        patientId,
        channelId,
        startKey,
        sort = "DSC",
        HRStartKey,
        HRSort = "DSC",
      },
    ) {
      try {
        let data: Query;
        if (channelId == null) {
          return;
        }
        if (!rootGetters.paginationFeatures.im_messages) {
          const result = await apollo.query<Query>({
            query: GET_CHANNEL_MESSAGES,
            variables: {
              cursorStart: state.cursorStart,
              id: channelId,
            },
          });
          data = result.data;
        } else {
          const result = await apollo.query<Query>({
            query: GET_CHANNEL_PAGINATED_MESSAGES_WITH_HELP_REQUESTS,
            variables: {
              startKey: startKey || null,
              id: channelId,
              limit: PAGINATION_LIMITS.im_messages || Infinity,
              sort,
              HRStartKey: HRStartKey || null,
              HRSort: HRSort,
              HRLimit: PAGINATION_LIMITS.im_messages || Infinity,
            },
          });
          data = result.data;
        }

        if (state.channelId !== channelId) {
          return;
        }

        commit("setLastMessage", data.imChannel?.earliestMissedMessage);
        commit(
          "setLastReadRequiredTimestamp",
          data.imChannel?.lastReadRequiredTimestamp,
        );
        dispatch("replaceSubstitutions", {
          patientId,
          messages: [
            ...(data.imChannel?.messages || []),
            ...(data.imChannel?.missedMessages || []),
            ...(data.imChannel?.paginatedMessagesWithHelpRequests || []),
          ],
        });
        commit(
          "setUnreadMessagesCount",
          data.imChannel?.membersMissedMessageCount ?? 0,
        );
        commit("setLastSeenMessageInfo", {
          patientId,
          lastSeenMessageAt: data.imChannel?.lastOwnerMissedMessagesSeenAt,
          lastSeenMessageId: data.imChannel?.ownerLastSeenMessageId,
        });
        const imMessages =
          data.imChannel?.paginatedMessagesWithHelpRequests || [];
        if (
          imMessages.length < PAGINATION_LIMITS.im_messages &&
          sort === "DSC" &&
          rootGetters.paginationFeatures.im_messages
        ) {
          commit("setAllMessagesLoaded");
        }

        const patient = rootGetters.patientIdMap[
          rootGetters.patient?.id
        ] as IPatient;
        const cachedPatients = rootGetters.cachedPatientsList as Patient[];
        if (
          patient != null ||
          cachedPatients.find((p) => p.id === rootGetters.patient?.id)
        ) {
          commit("updatePatientListPatients", [
            {
              id: patient.id,
              missedMessageCount:
                data.imChannel?.membersMissedMessageCount ?? 0,
            },
          ]);
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientMissedMessages({ commit, rootGetters }, { patientId }) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_MISSED_MESSAGES,
          variables: {
            id: patientId,
            orgIds: [rootGetters.rootOrganization.id],
          },
        });
        const patientChannel = data.patient?.imChannels?.find(
          (c) => c.orgId === rootGetters.rootOrganization.id,
        );

        commit(
          "setUnreadMessagesCount",
          patientChannel?.membersMissedMessageCount || 0,
        );
      } catch (error) {
        logger.error(error as Error);
      }
    },

    setPatientsListPatientMissedMessages(
      { commit, rootGetters },
      { patientId, orgId, membersMissedMessageCount },
    ) {
      const patient = rootGetters.patientIdMap[patientId] as IPatient;
      const cachedPatients = rootGetters.cachedPatientsList as Patient[];
      if (patient == null && !cachedPatients.find((p) => p.id === patientId)) {
        return null;
      }
      if (orgId === rootGetters.rootOrganization.id) {
        commit("updatePatientListPatients", [
          {
            id: patientId,
            missedMessageCount: membersMissedMessageCount ?? 0,
          },
        ]);
      }
    },

    async markMessageAsSeen({ dispatch, state }, { messageId, patientId }) {
      try {
        await apollo.mutate<Mutation>({
          mutation: MARK_IM_MESSAGE_SEEN,
          variables: {
            id: messageId,
          },
        });
        dispatch("getChannelMessages", {
          channelId: state.channelId,
          patientId,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async sendTextMessage(
      { dispatch, state, commit },
      message: ImTextMessageInput,
    ) {
      try {
        await apollo.mutate<Mutation>({
          mutation: PUT_TEXT_MESSAGE,
          variables: {
            channelId: state.channelId,
            text: message.text,
            readRequired: message.readRequired,
          },
        });
        const patientId = router.currentRoute?.params?.patientId;
        commit("setSendMessageSuccess", true);
        dispatch("getChannelMessages", {
          patientId,
          channelId: state.channelId,
        });
      } catch (error) {
        commit("setSendMessageError", "Failed to send message");
        logger.error(error as Error);
      }
      commit("setSendMessageLoading", false);
    },

    async sendImgMessage(
      { dispatch, state, commit },
      message: ImImageMessageInput,
    ) {
      try {
        await apollo.mutate<Mutation>({
          mutation: PUT_IMG_MESSAGE,
          variables: {
            channelId: state.channelId,
            fileName: message.fileName,
            key: message.key,
            text: message.text || null,
            readRequired: message.readRequired,
          },
        });
        const patientId = router.currentRoute?.params?.patientId;
        commit("setSendMessageSuccess", true);
        dispatch("getChannelMessages", {
          patientId,
          channelId: state.channelId,
        });
      } catch (error) {
        commit("setSendMessageError", "Failed to send message");
        logger.error(error as Error);
      }
      commit("setSendMessageLoading", false);
    },

    async sendAudioMessage(
      { dispatch, state, commit },
      message: ImAudioMessageInput,
    ) {
      try {
        await apollo.mutate<Mutation>({
          mutation: PUT_AUDIO_MESSAGE,
          variables: {
            channelId: state.channelId,
            fileName: message.fileName,
            key: message.key,
            text: message.text || null,
            readRequired: message.readRequired,
          },
        });
        const patientId = router.currentRoute?.params?.patientId;
        commit("setSendMessageSuccess", true);
        dispatch("getChannelMessages", {
          patientId,
          channelId: state.channelId,
        });
      } catch (error) {
        commit("setSendMessageError", "Failed to send message");
        logger.error(error as Error);
      }
      commit("setSendMessageLoading", false);
    },

    async sendVideoMessage(
      { dispatch, state, commit },
      message: ImVideoMessageInput,
    ) {
      try {
        await apollo.mutate<Mutation>({
          mutation: PUT_VIDEO_MESSAGE,
          variables: {
            channelId: state.channelId,
            fileName: message.fileName,
            key: message.key,
            text: message.text || null,
            readRequired: message.readRequired,
          },
        });
        const patientId = router.currentRoute?.params?.patientId;
        commit("setSendMessageSuccess", true);
        dispatch("getChannelMessages", {
          patientId,
          channelId: state.channelId,
        });
      } catch (error) {
        commit("setSendMessageError", "Failed to send message");
        logger.error(error as Error);
      }
      commit("setSendMessageLoading", false);
    },

    async sendEduMaterialMessage(
      { dispatch, state, rootGetters, commit },
      message: ImEduMaterialMessageInput,
    ) {
      try {
        await apollo.mutate<Mutation>({
          mutation: PUT_EDU_MATERIAL_MESSAGE,
          variables: {
            channelId: state.channelId,
            orgId: rootGetters.organization.id,
            materialId: message.materialId,
            text: message.text || null,
            readRequired: message.readRequired,
          },
        });
        const patientId = router.currentRoute?.params?.patientId;
        commit("setSendMessageSuccess", true);
        dispatch("getChannelMessages", {
          patientId,
          channelId: state.channelId,
        });
      } catch (error) {
        commit("setSendMessageError", "Failed to send message");
        logger.error(error as Error);
      }
      commit("setSendMessageLoading", false);
    },

    async replaceSubstitutions(
      { commit, state, rootGetters },
      { patientId, messages },
    ) {
      const messagesToUpdate = messages.map((m: any) => ({
        ...m,
        text:
          (m.text || m.caption) &&
          replaceSubstitutions(
            m.text || m.caption,
            rootGetters.patientPii,
            rootGetters.patientState,
            rootGetters.patientType,
          ),
      }));
      commit("setMessages", { patientId, messages: messagesToUpdate });
      const patientMessages = state.patientMessageMap?.[patientId] || [];
      if (patientMessages != null && patientMessages.length > 0) {
        commit(
          "setCursorStart",
          patientMessages[patientMessages.length - 1].createdAt,
        );
      }
    },
  },
  getters: {
    patientMessageMap(state) {
      return state.patientMessageMap;
    },
    messages(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientMessageMap?.[patientId] || [];
    },
    earliestMissedMessage(state) {
      return state.earliestMissedMessage;
    },
    lastReadRequiredTimestamp(state) {
      return state.lastReadRequiredTimestamp;
    },
    unreadMessagesCount(state) {
      return state.unreadMessagesCount;
    },
    channelId(state) {
      return state.channelId;
    },
    quickMessages(state) {
      return state.quickMessages;
    },
    allMessagesLoaded(state) {
      return state.allMessagesLoaded;
    },
    messageListScrollPosition(state) {
      return state.messageListScrollPosition;
    },
    lastSeenMessageInfo(state) {
      return {
        lastSeenMessageAt: state.lastSeenMessageAt,
        lastSeenMessageId: state.lastSeenMessageId,
      };
    },
    sendMessageSuccess(state) {
      return state.sendMessageSuccess;
    },
    sendMessageLoading(state) {
      return state.sendMessageLoading;
    },
    sendMessageError(state) {
      return state.sendMessageError;
    },
  },
  mutations: {
    resetMessageModule(state) {
      state.channelId = null;
      state.patientMessageMap = {};
      state.unreadMessagesCount = null;
      state.earliestMissedMessage = null;
      state.lastReadRequiredTimestamp = null;
      state.cursorStart = null;
      state.allMessagesLoaded = false;
      state.messageListScrollPosition = null;
      state.lastSeenMessageAt = null;
      state.lastSeenMessageId = null;
      state.sendMessageSuccess = false;
      state.sendMessageLoading = false;
    },
    setChannelId(state, id: string) {
      state.channelId = id;
    },
    setCursorStart(state, timestamp: number) {
      state.cursorStart = timestamp;
    },
    setQuickMessages(state, quickMessages: IQuickMessage[]) {
      state.quickMessages = quickMessages;
    },
    setLastMessage(state, message: ImChannelMessage) {
      state.earliestMissedMessage = message;
    },
    setLastReadRequiredTimestamp(state, timestamp: number) {
      state.lastReadRequiredTimestamp = timestamp;
    },
    setUnreadMessagesCount(state, messagesCount: number) {
      state.unreadMessagesCount = messagesCount;
    },
    setLastSeenMessageInfo(
      state,
      {
        patientId,
        lastSeenMessageAt,
        lastSeenMessageId,
      }: {
        patientId: string;
        lastSeenMessageAt?: number;
        lastSeenMessageId?: string;
      },
    ) {
      const patientLastSeenMessage = (
        state.patientMessageMap[patientId] || []
      ).find((m) => m.id === lastSeenMessageId);
      if (
        patientLastSeenMessage?.author?.kind === ImAuthorTypeEnum.Patient &&
        lastSeenMessageAt
      ) {
        const messages = (state.patientMessageMap[patientId] || []).filter(
          (m) =>
            (m.author?.kind !== ImAuthorTypeEnum.Patient &&
              m.createdAt <= lastSeenMessageAt) ||
            m.author?.kind === ImAuthorTypeEnum.Patient,
        );
        lastSeenMessageId = messages?.slice(-1)?.[0]?.id || lastSeenMessageId;
      }
      state.lastSeenMessageId = lastSeenMessageId;
      state.lastSeenMessageAt = lastSeenMessageAt;
    },
    setMessages(
      state,
      data: { messages: ImChannelMessage[]; patientId: string },
    ) {
      const uniqueMessages: ImChannelMessage[] = [];
      [
        ...(state.patientMessageMap?.[data.patientId] || []),
        ...data.messages,
      ].forEach((item) => {
        const i = uniqueMessages.findIndex((x) => x.id === item.id);
        if (i <= -1) {
          uniqueMessages.push(item);
        } else if (
          item.createdAt > uniqueMessages[i].createdAt ||
          item.kind === ImMessageTypeEnum.HelpRequest
        ) {
          uniqueMessages[i] = item;
        }
      });
      const sorted = [...uniqueMessages]
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
        .reverse();
      state.patientMessageMap[data.patientId] = sorted;
      state.patientMessageMap = { ...state.patientMessageMap };
    },
    setAllMessagesLoaded(state) {
      state.allMessagesLoaded = true;
    },
    setMessageListScrollPosition(state, position: number) {
      state.messageListScrollPosition = position;
    },
    setSendMessageSuccess(state, value: boolean) {
      state.sendMessageSuccess = value;
    },
    setSendMessageLoading(state, value: boolean) {
      state.sendMessageLoading = value;
    },
    setSendMessageError(state, errorMessage: string | null) {
      state.sendMessageError = errorMessage;
    },
  },
};

export default module;
