import logger from "@/logger";
import router, { PUBLIC_PAGES } from "@/router/routes";
import store from "@/store/store";
import axios from "axios";
import Validator from "validator";

import {
  isAuthenticated,
  isExpired,
  logout,
  silentAuthentication,
  token,
} from "./auth.service";

axios.interceptors.request.use(async (config) => {
  const cleanCache = sessionStorage.getItem("cleanCache");
  const newConfig = { ...config };
  const headers = newConfig.headers || {};

  headers["X-Request-Origin"] = "provider-portal";
  headers["Cache-Control"] = "no-cache";
  headers["Pragma"] = "no-cache";
  headers["Expires"] = "0";

  if (cleanCache != null) {
    headers["X-Cache-Control"] = cleanCache;
  }
  // TODO: isURL doesn't work on localhost:port
  if (newConfig.url && !Validator.isURL(newConfig.url)) {
    newConfig.url = `${process.env.VUE_APP_API_PATH}${newConfig.url}`;
  }

  if (isAuthenticated() && !isExpired()) {
    headers.Authorization = `Bearer ${token()}`;
  } else {
    try {
      if (PUBLIC_PAGES.includes(router.currentRoute.name || "")) {
        return newConfig;
      }
      await silentAuthentication();
      headers.Authorization = `Bearer ${token()}`;
    } catch (error) {
      logger.error(error as Error);
      if (!router.currentRoute.meta?.public) {
        logout();
      }
    }
  }

  return newConfig;
});

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    store?.dispatch("populateHttpErrors", {
      status: error.response?.status,
      statusText: error.response?.statusText,
      message: error.response?.data?.message,
    });

    if (error.response.status === 401) {
      return Promise.reject(error.response);
    }
    logger.error(error as Error);
    return Promise.reject(error);
  },
);

export const queryString = (obj: object) =>
  Object.entries(obj)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");

export default axios;
