import "./assets/button.styl";
import "./assets/dropdown.styl";
import "./assets/input.styl";
import "./assets/styles.styl";
import "./directives/loading.directive";
import "./directives/permissions.directive";
import "./plugins/Calendar";
import "./plugins/font-awesome";
import "./plugins/FormValidator";
import "./plugins/myia-components";
import "./plugins/perfect-scrollbar";
import "./plugins/TextHighlight";
import "./plugins/ToggleButton";
import "./plugins/Tooltip";
import "./plugins/v-click-outside";
import "./plugins/v-lazy";
import "./plugins/v-popover";
import "./plugins/v-scroll-to";
import "./plugins/v-toasted";
import "./plugins/vue-infinite-loading";
import "./plugins/vue-websockets";
import "./plugins/VueMoment";
import "normalize.css/normalize.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import router from "@/router/routes";
import store from "@/store/store";
import Vue from "vue";

import App from "./App.vue";
import apolloProvider from "./plugins/vue-apollo";

Vue.config.productionTip = false;

new Vue({
  apolloProvider,
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
