import { Module } from "vuex";

import { ICarePlanModuleState, IRootState } from "../../../@types/store";

import router from "@/router/routes";
import {
  Mutation,
  PatientCarePlan,
  PatientMedicalSummary,
  Query,
} from "generated/types-gql";
import apollo from "@/apollo";
import {
  GET_PATIENT_CARE_PLAN,
  GET_PATIENT_CARE_PLAN_TAB,
  GET_PATIENT_DIAGNOSES,
  GET_PATIENT_MEDICAL_SUMMARY,
} from "@/graphql/queries/patient.query";
import logger from "@/logger";
import {
  UPDATE_PATIENT_CARE_PLAN,
  UPDATE_PATIENT_MEDICAL_SUMMARY,
  UPDATE_PATIENT_MEDICAL_SUMMARY_DIAGNOSES,
} from "@/graphql/mutations/patient.mutation";
import {
  createPatientMedication,
  deletePatientMedication,
  getPatientMedications,
  updatePatientMedication,
} from "@/services/patient.service";
import { IMedication } from "../../../@types/patient";

const initialState: ICarePlanModuleState = {
  patientMedicalSummaryMap: {},
  patientCarePlanMap: {},
  patientMedicationsMap: {},
  patientDiagnosesMap: {},
};

const module: Module<ICarePlanModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getPatientMedicalSummary(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_MEDICAL_SUMMARY,
          variables: { patient: patientId },
        });
        commit("setPatientMedicalSummary", {
          patientId,
          summary: data?.patient?.medicalSummary,
        });
        commit("setDiagnoses", {
          patientId,
          summary: data?.patient?.medicalSummary,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientMedicalSummary(
      { commit, dispatch },
      {
        patient,
        medicalSummary,
      }: { patient: string; medicalSummary: PatientMedicalSummary },
    ) {
      try {
        commit("dataPending");
        await apollo.mutate<Mutation>({
          mutation: UPDATE_PATIENT_MEDICAL_SUMMARY,
          variables: {
            input: { ...medicalSummary, patient },
          },
        });
        dispatch("getPatientMedicalSummary", { patientId: patient });
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async updatePatientMedicalSummaryDiagnoses(
      { commit, dispatch },
      {
        patient,
        diagnoses,
      }: { patient: string; diagnoses: PatientMedicalSummary["diagnoses"] },
    ) {
      try {
        commit("dataPending");
        await apollo.mutate<Mutation>({
          mutation: UPDATE_PATIENT_MEDICAL_SUMMARY_DIAGNOSES,
          variables: {
            input: { diagnoses, patient },
          },
        });
        dispatch("getPatientMedicalSummary", { patientId: patient });
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async getPatientDiagnoses(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_DIAGNOSES,
          variables: { patient: patientId },
        });
        commit("setDiagnoses", {
          patientId,
          summary: data?.patient?.medicalSummary,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientCarePlanTab(
      { commit },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_CARE_PLAN_TAB,
          variables: { patient: patientId },
        });
        commit("setPatientCarePlan", {
          patientId,
          plan: data?.patient?.carePlan,
        });
        commit("setPatientMedicalSummary", {
          patientId,
          summary: data?.patient?.medicalSummary,
        });
        commit("setDiagnoses", {
          patientId,
          summary: data?.patient?.medicalSummary,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientCarePlan({ commit }, { patientId }: { patientId: string }) {
      try {
        const { data } = await apollo.query<Query>({
          query: GET_PATIENT_CARE_PLAN,
          variables: { patient: patientId },
        });
        commit("setPatientCarePlan", {
          patientId,
          plan: data?.patient?.carePlan,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientCarePlan(
      { commit, dispatch },
      { patient, carePlan }: { patient: string; carePlan: PatientCarePlan },
    ) {
      try {
        commit("dataPending");
        await apollo.mutate<Mutation>({
          mutation: UPDATE_PATIENT_CARE_PLAN,
          variables: {
            input: { ...carePlan, patient },
          },
        });
        dispatch("getPatientCarePlan", { patientId: patient });
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async getPatientMedications({ commit, rootGetters }, patientId: string) {
      try {
        const { data } = await getPatientMedications(
          rootGetters.rootOrganization.id,
          patientId,
        );
        commit("setPatientMedications", {
          patientId,
          medications: data,
        });
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async updatePatientMedications(
      { commit, dispatch, rootGetters },
      { patient, medication }: { patient: string; medication: IMedication },
    ) {
      try {
        commit("dataPending");
        await updatePatientMedication(
          rootGetters.rootOrganization.id,
          patient,
          medication,
        );
        dispatch("getPatientMedications", patient);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async createPatientMedication(
      { commit, dispatch, rootGetters },
      { patient, medication }: { patient: string; medication: IMedication },
    ) {
      try {
        commit("dataPending");
        await createPatientMedication(
          rootGetters.rootOrganization.id,
          patient,
          medication,
        );
        dispatch("getPatientMedications", patient);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async removePatientMedications(
      { commit, dispatch, rootGetters },
      { patient, pk, sk }: { patient: string; pk: string; sk: string },
    ) {
      try {
        commit("dataPending");
        await deletePatientMedication(
          rootGetters.rootOrganization.id,
          patient,
          pk,
          sk,
        );
        dispatch("getPatientMedications", patient);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },
  },
  getters: {
    patientMedicalSummaryMap: (state) => {
      return state.patientMedicalSummaryMap || {};
    },
    patientCarePlanMap: (state) => {
      return state.patientCarePlanMap || {};
    },
    patientMedicationsMap: (state) => {
      return state.patientMedicationsMap || {};
    },
    patientDiagnoses: (state, _, __, rootGetters) => {
      const patientId = rootGetters.route?.params?.patientId;
      return state.patientDiagnosesMap?.[patientId];
    },
    patientMedicalSummary(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientMedicalSummaryMap?.[patientId] || {};
    },
    patientCarePlan(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientCarePlanMap?.[patientId] || {};
    },
    patientMedications(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientMedicationsMap?.[patientId] || [];
    },
  },
  mutations: {
    setPatientMedicalSummary(
      state,
      data: { summary: PatientMedicalSummary; patientId: string },
    ) {
      state.patientMedicalSummaryMap[data.patientId] = data.summary;
      state.patientMedicalSummaryMap = { ...state.patientMedicalSummaryMap };
    },
    setDiagnoses(
      state,
      data: { summary: PatientMedicalSummary; patientId: string },
    ) {
      state.patientDiagnosesMap[data.patientId] = data.summary?.diagnoses || {};
      state.patientDiagnosesMap = { ...state.patientDiagnosesMap };
    },
    setPatientCarePlan(
      state,
      data: {
        plan: PatientCarePlan;
        patientId: string;
      },
    ) {
      state.patientCarePlanMap[data.patientId] = data.plan;
      state.patientCarePlanMap = { ...state.patientCarePlanMap };
    },
    setPatientMedications(
      state,
      data: {
        medications: IMedication[];
        patientId: string;
      },
    ) {
      state.patientMedicationsMap[data.patientId] = data.medications;
      state.patientMedicationsMap = { ...state.patientMedicationsMap };
    },
    resetCarePlanModule(state) {
      state.patientMedicalSummaryMap = {};
      state.patientCarePlanMap = {};
      state.patientMedicationsMap = {};
    },
  },
};

export default module;
