import gql from "graphql-tag";

import { channelMessagesFragment } from "../fragments/message.fragments";

export const PUT_TEXT_MESSAGE = gql`
  mutation put_text_message(
    $channelId: ID!
    $text: String!
    $readRequired: Boolean!
  ) {
    sendIMTextMessage(
      input: { channelId: $channelId, text: $text, readRequired: $readRequired }
    ) {
      createdAt
      text
      kind
      id
    }
  }
`;

export const PUT_IMG_MESSAGE = gql`
  mutation put_img_message(
    $channelId: ID!
    $fileName: String!
    $text: String
    $readRequired: Boolean!
    $key: String!
  ) {
    sendIMImageMessage(
      input: {
        channelId: $channelId
        fileName: $fileName
        key: $key
        text: $text
        readRequired: $readRequired
      }
    ) {
      createdAt
      text
      kind
      id
      url
    }
  }
`;

export const PUT_AUDIO_MESSAGE = gql`
  mutation put_audio_message(
    $channelId: ID!
    $fileName: String!
    $text: String
    $readRequired: Boolean!
    $key: String!
  ) {
    sendIMAudioMessage(
      input: {
        channelId: $channelId
        fileName: $fileName
        key: $key
        text: $text
        readRequired: $readRequired
      }
    ) {
      createdAt
      text
      kind
      id
      url
    }
  }
`;

export const PUT_VIDEO_MESSAGE = gql`
  mutation put_video_message(
    $channelId: ID!
    $fileName: String!
    $text: String
    $readRequired: Boolean!
    $key: String!
  ) {
    sendIMVideoMessage(
      input: {
        channelId: $channelId
        fileName: $fileName
        key: $key
        text: $text
        readRequired: $readRequired
      }
    ) {
      createdAt
      text
      kind
      id
      url
    }
  }
`;

export const PUT_EDU_MATERIAL_MESSAGE = gql`
  mutation put_edu_material_message(
    $channelId: ID!
    $orgId: ID
    $materialId: ID!
    $text: String
    $readRequired: Boolean!
  ) {
    sendIMEduMaterialMessage(
      input: {
        channelId: $channelId
        orgId: $orgId
        materialId: $materialId
        text: $text
        readRequired: $readRequired
      }
    ) {
      createdAt
      text
      kind
    }
  }
`;

export const GENERATE_IM_FILE_UPLOAD_URL = gql`
  mutation generate_upload_url($channelId: ID!, $fileName: String!) {
    generateIMFileUploadUrl(
      input: { channelId: $channelId, fileName: $fileName }
    ) {
      key
      url
      fileName
    }
  }
`;

export const MARK_IM_MESSAGE_SEEN = gql`
  mutation mark_message_seen($id: ID!) {
    markIMMessageSeen(id: $id) {
      ...channelMessages
    }
  }
  ${channelMessagesFragment}
`;
