import logger from "@/logger";
import Vue from "vue";
import VueNativeSock from "vue-native-websocket";
import store from "../store/store";

if (process.env.VUE_APP_WS_PATH) {
  Vue.use(VueNativeSock, process.env.VUE_APP_WS_PATH, {
    store,
    reconnection: true,
    reconnectionDelay: 3000,
  });
} else {
  logger.warn("WebSocket loading failed. Missing path.");
}
