import { hasPermission } from "@/factories/permissions-factory";
import Vue from "vue";

Vue.use({
  install(VuePermissions) {
    VuePermissions.directive("has-permission", {
      inserted(el, binding) {
        const permissionList = binding?.value || [];
        let isAllowed = false;
        if (permissionList.length && Array.isArray(permissionList)) {
          for (let i = 0; i < permissionList.length; i++) {
            if (hasPermission(permissionList[i])) {
              isAllowed = true;
              break;
            }
          }
        }
        el.style.display = isAllowed ? "block" : "none";
      },
    });
  },
});
