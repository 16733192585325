import store from "@/store/store";

import { IOrganization } from "../../@types/organization";
import { IRole } from "../../@types/provider";
import { getOrganizationUpperBranches } from "./organization-factory";

const getProviderPermissionsList = (
  organization?: IOrganization | null,
  roles?: IRole[] | null,
) => {
  const permissions: string[] = [];
  roles?.forEach((r) => {
    if (r.organization === organization?.id) {
      permissions.push(...(r?.permissions || []));
    }
  });
  return permissions;
};

export const hasPermission = (permission: string) => {
  const rootOrg = store?.getters?.rootOrganization;
  const currentOrg = store?.getters?.organization;
  const orgs = getOrganizationUpperBranches(rootOrg, currentOrg);
  const permissions: string[] = [];
  orgs.forEach((o) => {
    permissions.push(
      ...getProviderPermissionsList(o, store?.getters?.roles).map((p) =>
        p.toLowerCase(),
      ),
    );
  });
  return permissions.includes(permission.toLowerCase());
};
