import store from "@/store/store";
import Vue from "vue";
import Router from "vue-router";

import AuthGuard from "./auth-guard";
import logger from "@/logger";
import { IPPPageViewInput } from "../../@types/analytics";

export const PUBLIC_PAGES = ["PatientReports"];

Vue.use(Router);

const router = new Router({
  routes: [
    {
      beforeEnter: AuthGuard,
      component: () => import("@/views/PatientPanel.vue"),
      name: "PatientPanel",
      path: "/",
    },
    {
      beforeEnter: AuthGuard,
      component: () => import("@/views/PatientDetails.vue"),
      name: "PatientDetails",
      path: "/patient/:group/:orgId/:patientId",
    },
    {
      component: () => import("@/components/auth/AuthCallback.vue"),
      meta: { public: true },
      name: "LoginCallback",
      path: "/*access_token=*",
    },
    {
      component: () => import("@/components/auth/Login.vue"),
      meta: { public: true },
      name: "Login",
      path: "/login",
    },
    {
      beforeEnter: AuthGuard,
      component: () => import("@/views/PatientReports.vue"),
      meta: { public: true },
      name: "PatientReports",
      path: "/patient-report/:id",
    },
    {
      component: () => import("@/views/PageNotFound.vue"),
      meta: { public: true },
      name: "PageNotFound",
      path: "*",
    },
    {
      beforeEnter: AuthGuard,
      component: () => import("@/views/VideoCall.vue"),
      name: "VideoCall",
      path: "/video-call/:orgId/:patientId",
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  const connectionId = store.getters.connectionId;
  const providerWSConnection = store.getters.providerWSConnection;
  const orgId = to.params.orgId || store.getters.rootOrganization?.id;

  if (connectionId != null && providerWSConnection != null && orgId != null) {
    const input: IPPPageViewInput = {
      connectionId,
      organizationId: orgId,
      page: to.fullPath,
      patientId: to.params.patientId,
    };
    store.dispatch("registerProviderPortalPageView", input).catch((ex) => {
      logger.error(ex);
    });
  }
});

export default router;
