import { isAuthenticated, isTimedOut } from "@/services/auth.service";
import { Route } from "vue-router";

export default async (to: Route, __: any, next: any) => {
  switch (to.name) {
    case "Login": {
      next();
      break;
    }
    case "PatientReports": {
      if (!to.query?.token) {
        next({ name: "PageNotFound" });
      }
      break;
    }
    default: {
      if (!isAuthenticated() || isTimedOut()) {
        !sessionStorage.getItem("destinationPath") &&
          sessionStorage.setItem("destinationPath", to.path);
        next({ name: "Login" });
      }
    }
  }
  next();
};
