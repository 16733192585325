import $http from "@/services/rest.service";

const BASE_PATH = "/pp/orgs";

const THRESHOLDS_PATH = "thresholds";
const PATIENTS_PATH = "patients";

export const getThresholds = (orgId: string, patientId: string) =>
  $http.get(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/${THRESHOLDS_PATH}`,
  );

export const updatePatientThreshold = (
  orgId: string,
  patientId: string,
  threshold: any,
) =>
  $http.put(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/${THRESHOLDS_PATH}/${threshold.id}`,
    threshold,
  );

export const deletePatientThreshold = (
  orgId: string,
  patientId: string,
  thresholdId: string,
) =>
  $http.delete(
    `${BASE_PATH}/${orgId}/${PATIENTS_PATH}/${patientId}/${THRESHOLDS_PATH}/${thresholdId}`,
  );
