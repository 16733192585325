import gql from "graphql-tag";
import {
  timelineEventFragment,
  timelineTaskFragment,
} from "../fragments/patient.fragments";

export const POST_PATIENT_EVENT = gql`
  mutation post_patient_event($input: PatientEventInput!) {
    postPatientEvent(input: $input)
  }
`;

export const SET_PATIENT_CAMPAIGNS_SEEN = gql`
  mutation set_patient_campaigns_seen($campaignId: String!) {
    setCampaignAsSeen(campaignId: $campaignId)
  }
`;

export const UPDATE_PATIENT_TIMEZONE = gql`
  mutation update_patient_timezone($input: PatientTimezoneInput!) {
    updateUserTimezone(input: $input)
  }
`;

export const UPDATE_PATIENT_MEDICAL_SUMMARY = gql`
  mutation update_patient_medical_summary($input: MedicalSummaryInput!) {
    updatePatientMedicalSummary(input: $input) {
      patient
    }
  }
`;

export const UPDATE_PATIENT_MEDICAL_SUMMARY_DIAGNOSES = gql`
  mutation update_patient_medical_summary_diagnoses(
    $input: MedicalSummaryDiagnosesInput!
  ) {
    updatePatientMedicalSummaryDiagnoses(input: $input) {
      patient
    }
  }
`;

export const UPDATE_PATIENT_SOCIAL_HISTORY = gql`
  mutation update_patient_social_history($input: SocialHistoryInput!) {
    updatePatientSocialHistory(input: $input) {
      patient
    }
  }
`;

export const UPDATE_PATIENT_CARE_PLAN = gql`
  mutation update_patient_care_plan($input: CarePlanInput!) {
    updatePatientCarePlan(input: $input) {
      patient
    }
  }
`;

export const UPDATE_PATIENT_CARE_RESOURCES = gql`
  mutation update_patient_care_resources($input: CareResourcesInput!) {
    updatePatientCareResources(input: $input) {
      patient
    }
  }
`;

export const SEND_PATIENT_CAMPAIGN = gql`
  mutation send_patient_campaign($input: CampaignInput!) {
    sendPatientCampaign(input: $input) {
      id
    }
  }
`;

export const CREATE_TIMELINE_MEDICAL_EVENT_TASK = gql`
  mutation create_timeline_medical_event_task(
    $input: PatientTimelineEventWithTaskCreateInput!
  ) {
    createPatientMedicalTimelineEventWithTask(input: $input) {
      timelineEvents {
        ...timelineEvent
      }
      timelineTasks {
        ...timelineTask
      }
    }
  }
  ${timelineTaskFragment}
  ${timelineEventFragment}
`;

export const CREATE_TIMELINE_TASK = gql`
  mutation create_timeline_task($input: PatientTimelineTaskCreateBody!) {
    createPatientTimelineTask(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const CREATE_TIMELINE_NON_MEDICAL_EVENT_TASK = gql`
  mutation create_timeline_non_medical_event_task(
    $input: PatientTimelineEventWithTaskCreateInput!
  ) {
    createPatientNonMedicalTimelineEventWithTask(input: $input) {
      timelineEvents {
        ...timelineEvent
      }
      timelineTasks {
        ...timelineTask
      }
    }
  }
  ${timelineTaskFragment}
  ${timelineEventFragment}
`;

export const POSTPONE_TIMELINE_TASK = gql`
  mutation postpone_timeline_task(
    $input: PatientTimelineEventTasksPostponeInput!
  ) {
    postponePatientTimelineEventTask(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const UPDATE_NON_MEDICAL_TIMELINE_EVENT = gql`
  mutation update_non_medical_timeline_event(
    $input: PatientTimelineEventUpdateInput!
  ) {
    updatePatientNonMedicalTimelineEvent(input: $input) {
      ...timelineEvent
    }
  }
  ${timelineEventFragment}
`;

export const UPDATE_MEDICAL_TIMELINE_EVENT = gql`
  mutation update_medical_timeline_event(
    $input: PatientTimelineEventUpdateInput!
  ) {
    updatePatientMedicalTimelineEvent(input: $input) {
      ...timelineEvent
    }
  }
  ${timelineEventFragment}
`;

export const UPDATE_NON_MEDICAL_TIMELINE_ALERTS = gql`
  mutation update_non_medical_timeline_alerts(
    $input: [PatientTimelineTaskUpdateBody!]!
  ) {
    updatePatientNonMedicalAlerts(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const UPDATE_MEDICAL_TIMELINE_ALERTS = gql`
  mutation update_medical_timeline_alerts(
    $input: [PatientTimelineTaskUpdateBody!]!
  ) {
    updatePatientMedicalAlerts(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const UPDATE_TIMELINE_TASK = gql`
  mutation update_timeline_task($input: PatientTimelineTaskUpdateBody!) {
    updatePatientTimelineTask(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const COMPLETE_MEDICAL_TIMELINE_EVENT_TASK = gql`
  mutation complete_medical_timeline_event_task(
    $input: PatientTimelineTaskCompleteInput!
  ) {
    completePatientMedicalTimelineEventTask(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const COMPLETE_NON_MEDICAL_TIMELINE_EVENT_TASK = gql`
  mutation complete_non_medical_timeline_event_task(
    $input: PatientTimelineTaskCompleteInput!
  ) {
    completePatientNonMedicalTimelineEventTask(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const UNDO_COMPLETE_MEDICAL_TIMELINE_EVENT_TASK = gql`
  mutation undo_complete_medical_timeline_event_task(
    $input: PatientTimelineTaskCompleteUndoInput!
  ) {
    undoPatientMedicalTimelineEventTaskComplete(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const UNDO_COMPLETE_NON_MEDICAL_TIMELINE_EVENT_TASK = gql`
  mutation undo_complete_non_medical_timeline_event_task(
    $input: PatientTimelineTaskCompleteUndoInput!
  ) {
    undoPatientNonMedicalTimelineEventTaskComplete(input: $input) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const DELETE_NON_MEDICAL_TIMELINE_EVENT = gql`
  mutation delete_non_medical_timeline_event($pk: String!, $sk: String!) {
    deletePatientNonMedicalTimelineEvent(pk: $pk, sk: $sk) {
      id
    }
  }
`;

export const DELETE_MEDICAL_TIMELINE_EVENT = gql`
  mutation delete_medical_timeline_event($pk: String!, $sk: String!) {
    deletePatientMedicalTimelineEvent(pk: $pk, sk: $sk) {
      id
    }
  }
`;

export const DELETE_TIMELINE_TASK = gql`
  mutation delete_timeline_task($pk: String!, $sk: String!) {
    deletePatientTimelineTask(pk: $pk, sk: $sk) {
      id
    }
  }
`;

export const UNDO_NON_MEDICAL_TIMELINE_EVENT_DELETE = gql`
  mutation undo_non_medical_timeline_event_delete($pk: String!, $sk: String!) {
    undoPatientNonMedicalTimelineEventDelete(pk: $pk, sk: $sk) {
      ...timelineEvent
    }
  }
  ${timelineEventFragment}
`;

export const UNDO_MEDICAL_TIMELINE_EVENT_DELETE = gql`
  mutation undo_medical_timeline_event_delete($pk: String!, $sk: String!) {
    undoPatientMedicalTimelineEventDelete(pk: $pk, sk: $sk) {
      ...timelineEvent
    }
  }
  ${timelineEventFragment}
`;

export const UNDO_TIMELINE_TASK_DELETE = gql`
  mutation undo_timeline_task_delete($pk: String!, $sk: String!) {
    undoDeletePatientTimelineTask(pk: $pk, sk: $sk) {
      ...timelineTask
    }
  }
  ${timelineTaskFragment}
`;

export const SEND_PATIENT_RESTART_REQUEST = gql`
  mutation send_patient_restart_request($resourceId: ID!, $target: String!) {
    sendPatientRestartRequest(resourceId: $resourceId, target: $target)
  }
`;

//#endregion
