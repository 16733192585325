
import Vue from "vue";
import { ImChannelMessage } from "../generated/types-gql";
import { IHttpError } from "../@types/store";
import { ToastOptions } from "vue-toasted";
import { WSActionTypes } from "../@types/globals";
import { IPPPageViewInput } from "../@types/analytics";
import { ImMessageTypeEnum, INTERACTION_EVENT_LIST } from "./configs/constants";
import { isTimedOut, resetLastInteraction } from "./services/auth.service";
import { debounce } from "debounce";

export default Vue.extend({
  created() {
    this.updatePatientEvents = debounce(this.updatePatientEvents, 800);
  },
  mounted() {
    const lastLoadTime = sessionStorage.getItem("loadTime");
    const timeNow = Date.now();
    sessionStorage.setItem("loadTime", timeNow.toString());
    if (lastLoadTime) {
      if (timeNow - parseInt(lastLoadTime, 10) < 5000) {
        sessionStorage.setItem("cleanCache", "0");
      } else {
        sessionStorage.removeItem("cleanCache");
      }
    }

    INTERACTION_EVENT_LIST.forEach((event) => {
      window.addEventListener(event, resetLastInteraction);
    });

    this.intervalId = window.setInterval(this.checkTimeout, 60 * 1000);
  },
  destroyed() {
    INTERACTION_EVENT_LIST.forEach((event) => {
      window.removeEventListener(event, resetLastInteraction);
    });
    clearInterval(this.intervalId);
  },
  data: function () {
    return {
      intervalId: 0,
    };
  },
  computed: {
    socketIsConnected() {
      return this.$store.getters.socketIsConnected;
    },
    token() {
      return this.$store.getters.token;
    },
    connectionId() {
      return this.$store.getters.connectionId;
    },
    providerWSConnection() {
      return this.$store.getters.providerWSConnection;
    },
    rootOrganization() {
      return this.$store.getters.rootOrganization;
    },
    wsMessage() {
      return this.$store.getters.wsMessage;
    },
    httpError() {
      return this.$store.getters.httpError;
    },
    isInVideoCallPage() {
      return this.$route.name === "VideoCall";
    },
  },
  methods: {
    registerPageView() {
      const providerWSConnection = this.$store.getters.providerWSConnection;
      const connectionId = this.$store.getters.connectionId;
      const rootOrgId =
        this.$route.params.orgId || this.$store.getters.rootOrganization?.id;
      if (
        providerWSConnection != null &&
        connectionId != null &&
        rootOrgId != null
      ) {
        const input: IPPPageViewInput = {
          connectionId,
          organizationId: rootOrgId,
          page: this.$route.fullPath,
          patientId: this.$route.params.patientId,
        };
        if (!localStorage.getItem("loginViewRegistered")) {
          localStorage.setItem("loginViewRegistered", "true");
          this.$store.dispatch("registerProviderPortalPageView", {
            ...input,
            page: "/login",
          });
        }
        this.$store.dispatch("registerProviderPortalPageView", input);
      }
    },

    updatePatientEvents(patientId: string) {
      this.$store.dispatch("getPatientEvents", {
        patientId,
      });
    },

    registerProviderWsConnection() {
      const token = this.$store.getters.token;
      const connectionId = this.$store.getters.connectionId;
      const rootOrganization = this.$store.getters.rootOrganization?.id;
      if (token && connectionId && rootOrganization) {
        this.$store.dispatch("registerProviderWsConnection", connectionId);
      }
    },

    checkTimeout() {
      if (isTimedOut() && !this.$store.getters.isTimedOut) {
        this.$store.commit("setIsTimedOut", true);
      }
    },
  },
  watch: {
    // 1.Identify socket after connection
    socketIsConnected() {
      if (this.$store.getters.socketIsConnected) {
        this.$store.dispatch("sendWSMessage", { do: "IDENTIFY" });
      }
    },
    // 2.Register provider connection after authentication token passed
    token() {
      this.registerProviderWsConnection();
    },
    // 3.Register provider connection after socket identification passed
    connectionId() {
      this.registerProviderWsConnection();
    },
    // 4.Register provider page view after provider connection registered
    providerWSConnection() {
      this.registerPageView();
    },
    rootOrganization() {
      this.registerPageView();
      // 5.Register provider ws connection when rootOrgExists
      this.registerProviderWsConnection();
    },
    // Handle websocket messages here
    wsMessage() {
      const message = this.$store.getters.wsMessage;
      if (message == null) {
        return;
      }
      const data = JSON.parse(message.data);
      switch (data.action as WSActionTypes) {
        case "CONNECTED":
          this.$store.commit("setSocketConnectionId", data.connectionId);
          break;
        case "CURRENT_VIEWERS_CHANGED": {
          this.$store.dispatch("updatePatientViewers", {
            patientId: data.patientId,
            currentViewers: data.currentViewers,
            lastViewInfo: data.lastViewInfo,
          });
          break;
        }
        case "REVIEW_NOTES_LOCKED":
        case "REVIEW_NOTES_UNLOCKED":
          this.$store.dispatch("getPatientReviewNotes", {
            patientId: data.patientId,
          });
          break;
        case "UPDATE_PATIENT_NOTIFICATIONS":
          if (this.$store.getters.rootOrganization) {
            this.$store.dispatch("getPatientNotifications", {
              patientId: data.patientId,
              updateList: true,
            });
          }
          break;
        case "UPDATE_PATIENT_STATE":
          if (this.$route.params.patientId === data.patientId) {
            this.$store.commit("setPatientFields", data.patient);
          }
          this.$store.commit("updatePatientListPatients", [
            {
              id: data.patientId,
              currentState: data.patient?.currentState,
            },
          ]);
          break;
        case "CAMPAIGN_SENT":
        case "CAMPAIGN_OPENED":
        case "CAMPAIGN_ANSWER_SUBMITTED":
        case "CAMPAIGN_FINISHED":
        case "CAMPAIGN_CARD_SKIPPED":
        case "CAMPAIGN_VIEWED":
        case "CAMPAIGN_VIEWED_STATE_RESET":
          if (this.$route.params.patientId === data.patientId) {
            if (data.campaign == null) {
              this.$store.dispatch("getCampaign", {
                campaignId: data.campaignId,
                patientId: data.patientId,
              });
            } else {
              this.$store.commit("updatePatientCampaigns", {
                campaign: data.campaign,
                patientId: data.patientId,
              });

              if (
                this.$store.getters.selectedCampaign?.id === data.campaignId &&
                this.$store.getters.isSidebarOpenedFully
              ) {
                this.$store.commit("selectCampaign", data.campaign);
              }
            }
          }
          break;
        case "UPDATE_PATIENT_HELP_REQUESTS":
          if (
            this.$route.params.patientId === data.patientId &&
            this.$store.getters.channelId
          ) {
            this.$store.dispatch("getChannelMessages", {
              channelId: this.$store.getters.channelId,
              patientId: data.patientId,
            });
          }
          break;
        case "UPDATE_PATIENT_EVENTS":
          if (!this.isInVideoCallPage) {
            this.updatePatientEvents(data.patientId);
          }
          break;
        case "SILENT_PING_RESPONDED":
          this.$store.commit("setPatientAvailability", {
            status: true,
          });
          break;
        case "VIDEO_CALL_ENDED": {
          if (this.$route.params.patientId === data.patientId) {
            this.$store.commit("setProvidersInCall", []);
            if (this.isInVideoCallPage) {
              this.$store.commit("setPatientCallEndState", "callEnded");
            }
          }
          break;
        }
        case "UPDATE_PROVIDERS_IN_CALL": {
          if (this.$route.params.patientId === data.patientId) {
            this.$store.commit("setProvidersInCall", data.providers || []);
          }
          break;
        }
        case "VIDEO_CALL_DECLINED": {
          if (this.$route.params.patientId === data.patientId) {
            if (this.isInVideoCallPage) {
              this.$store.commit(
                "setPatientCallEndState",
                "patientDeclinedCall",
              );
            }
            this.$store.commit("setProvidersInCall", []);
          }
          break;
        }
        case "VIDEO_CALL_MISSED": {
          if (this.$route.params.patientId === data.patientId) {
            if (this.isInVideoCallPage) {
              this.$store.commit("setPatientCallEndState", "patientMissedCall");
            }
            this.$store.commit("setProvidersInCall", []);
          }
          break;
        }
        case "PATIENT_NEW_MESSAGE":
          if (data.patientId != null) {
            this.$store.dispatch("setPatientsListPatientMissedMessages", {
              patientId: data.patientId,
              orgId: data.orgId,
              membersMissedMessageCount: data.membersMissedMessageCount,
            });
          }
          if (
            this.$route.params.patientId === data.patientId &&
            this.rootOrganization.id === data.orgId
          ) {
            this.$store.commit(
              "setUnreadMessagesCount",
              data?.membersMissedMessageCount || 0,
            );
          }
          if (
            data.imChannelId != null &&
            this.$route.params.patientId === data.patientId &&
            this.$store.getters.channelId === data.imChannelId
          ) {
            const imMessages = (this.$store.getters.messages || []).filter(
              (m: ImChannelMessage) => m.kind !== ImMessageTypeEnum.HelpRequest,
            );
            const helpRequests = (this.$store.getters.messages || []).filter(
              (m: ImChannelMessage) => m.kind === ImMessageTypeEnum.HelpRequest,
            );
            this.$store.dispatch("getChannelMessages", {
              channelId: data.imChannelId,
              sort: "ASC",
              startKey: imMessages[imMessages.length - 1]?.id,
              HRStartKey: helpRequests[helpRequests.length - 1]?.id,
              HRSort: "ASC",
              patientId: data.patientId,
            });
          }
          break;
        case "UPDATE_PATIENT_MESSAGES":
          if (this.isInVideoCallPage) {
            break;
          }

          this.$store.dispatch("setPatientsListPatientMissedMessages", {
            patientId: data.patientId,
            orgId: data.orgId,
            membersMissedMessageCount: data.membersMissedMessageCount,
          });
          if (
            this.$route.params.patientId === data.patientId &&
            this.rootOrganization.id === data.orgId
          ) {
            this.$store.commit(
              "setUnreadMessagesCount",
              data?.membersMissedMessageCount || 0,
            );
            if (this.$store.getters.channelId === data.imChannelId) {
              this.$store.commit(
                "setLastReadRequiredTimestamp",
                data.lastReadRequiredTimestamp,
              );
              this.$store.commit("setLastSeenMessageInfo", {
                patientId: data.patientId,
                lastSeenMessageAt: data.lastOwnerMissedMessagesSeenAt,
                lastSeenMessageId: data.ownerLastSeenMessageId,
              });
            }
          }
          break;
        case "UPDATE_PATIENT_LATEST_VITALS":
          if (this.isInVideoCallPage) {
            break;
          }
          if (this.$route.params.patientId === data.patientId) {
            this.$store.dispatch("getPatientLatestVitals", {
              patientId: data.patientId,
            });
            this.$store.dispatch("getPatientVitals", {
              patientId: data.patientId,
              dates: data.dates,
            });
          }
          this.$store.commit("updatePatientListPatients", [
            { id: data.patientId, latestVitals: data.latestVitals },
          ]);
          break;
        case "UPDATE_PATIENT_LATEST_SURVEY_ANSWERS":
          if (!this.isInVideoCallPage) {
            this.$store.commit("updatePatientListPatients", [
              {
                id: data.patientId,
                latestSurveyAnswer: data.latestSurveyAnswer,
              },
            ]);
            if (this.$route.params.patientId === data.patientId) {
              this.$store.dispatch("getPatientSurveyAnswers", {
                patientId: data.patientId,
              });
            }
          }
          break;
        case "UPDATE_ORGANIZATION_PATIENT_WATCHLISTS":
          if (!this.$store.getters.patientWatchlistsEnabled) {
            return;
          }
          this.$store.dispatch("getOrganizationWatchlists", {
            rootOrgId: data.organization,
          });
          if (this.$route.params.patientId) {
            this.$store.dispatch("getPatientWatchlists", {
              patientId: this.$route.params.patientId,
            });
          }
          break;
        default:
      }
    },
    httpError() {
      const error = this.$store.getters.httpError as IHttpError;
      if (
        this.$store.getters.httpError != null &&
        (this.$store.getters.isHttpErrorToastVisible ||
          (this.isInVideoCallPage &&
            this.$store.getters.showHttpErrorInVideoCallPage))
      ) {
        const toastOptions: ToastOptions & {
          [key: string]: any;
        } = {
          duration: 4000,
          className: "myia-toasted",
          keepOnHover: true,
        };
        this.$toasted.show(
          error.message || error.statusText || "",
          toastOptions,
        );
      }
    },
  },
});
