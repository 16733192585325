import gql from "graphql-tag";

export const campaignTemplateFragment = gql`
  fragment template on CampaignTemplate {
    id
    createdAt
    name
    cards {
      flavor
      options {
        text
        value
      }
      questions
      template
      type
    }
  }
`;

export const campaignAnswerFragment = gql`
  fragment answer on CampaignAnswer {
    answer
    attachments
    card {
      flavor
      options {
        text
        value
      }
      questions
      template
      type
    }
    skipped
  }
`;
