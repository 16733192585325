import gql from "graphql-tag";

export const timelineTaskFragment = gql`
  fragment timelineTask on PatientTimelineTask {
    author
    completeNotes
    completedAt
    completedBy
    createdAt
    createdBy
    description
    displayName
    notes
    eventId
    provider
    id
    name
    noteDraftId
    organization
    patient
    pk
    sk
    sk2
    taskDate
    usefulness
    taskTemplateId
    updatedAt
    version
    checklists
    reminder
    reminderUnit
    postponed {
      timestamp
      postponedTo
      reasons
      provider
    }
    ppRules {
      ppSendable
      showPatientTimeline
      visibleInPP
    }
    __typename
  }
`;

export const timelineEventFragment = gql`
  fragment timelineEvent on PatientTimelineEvent {
    author
    canHaveTask
    createdAt
    updatedAt
    createdBy
    displayName
    description
    descriptionSource
    detail
    detailSource
    endDate
    isNonMedical
    eventName
    eventTemplateId
    dateNotEditable
    eventType
    campaign
    visitType
    id
    organization
    patient
    provider
    pk
    sk
    sk2
    source
    repeatCron
    severity
    pushToEhr
    startDate
    tags
    isOngoing
    vitals
    selectedTags
    taskId
    thresholds
    alertPayload
    version
    notes
    canHaveFeedback
    hasFeedback
    feedback {
      selectedOptions
      otherOption
      feedbackTargetId
      author
    }
    ppRules {
      canHaveVitals
      icon
      ppSendable
      showPatientTimeline
      visibleInPP
    }
    __typename
  }
`;

export const carePlanFragment = gql`
  fragment carePlan on PatientCarePlan {
    appointmentFrequency
    contingencyPlan
    carePlans {
      createdAt
      notes
      planType
    }
  }
`;

export const medicalSummaryFragment = gql`
  fragment medicalSummary on PatientMedicalSummary {
    diagnoses
    problemList {
      createdAt
      text
      name
    }
    summary
    symptoms
    symptomTriggerRate
    symptomTriggerReasons
  }
`;

export const socialHistoryFragment = gql`
  fragment socialHistory on PatientSocialHistory {
    socialHistoryAttributes {
      anxious
      dementia
      irritable
      depressed
      nonVerbal
      shortTermMemoryLoss
      hardOfHearing
      blind
      wheelChair
      bedBound
      monitorsGlucoseLvl
      monitoredBySgf
      otherAssistance
    }
    notes
    maritalStatus
    numberOfChildren
    livingSituation
    annualHouseholdIncome
    socialActivities
    exercise
    eatingVegetables
    abilityToRead
    educationLevel
    bestWayToLearn
    comfortWithTechnology
  }
`;

export const careResourcesFragment = gql`
  fragment careResources on PatientCareResources {
    externalPhysicians {
      name
      hospital
      phone
    }
    selfCare {
      confidence
      getsMeds
      givesMeds
      hasTransportation
    }
    caregivers {
      name
      relationship
      phone
      canDiscussPhi
      secondaryContact
      livesWithPatient
      levelOfCareProvided
      getsMeds
      givesMeds
      providesTransportation
      distanceFromPatientInMiles
      careProvidedLevel
    }
    additionalSupport
    homeHealthNurse {
      company
      phone
      visitFrequency
      canDrawLabs
      canGiveFluidsMedications
    }
    transportation {
      problemsFrequency
      sameDay
      arrangeDays
      serviceName
      servicePhone
      description
    }
    pharmacy
    address
    city
    state
    zip
    phone
    delivery
    primaryOfficeLab
    externalFacilityLab
    facilityLabPhone
    homeSafetyDevices {
      grabBar
      stairLift
      lifeAlert
      elevatedToiletSeat
      other
    }
  }
`;
