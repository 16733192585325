
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MyiaIcon extends Vue {
  @Prop({ default: "svg" }) private format!: string;
  @Prop(String) private icon!: string;
  @Prop(String) private width!: string;
  @Prop(String) private height!: string;
  @Prop(Boolean) private disabled!: boolean;

  private get iconSrc() {
    // Please keep image name "-disabled" suffix format in disabled image names
    return require("@/assets/images/" +
      this.icon +
      (this.disabled ? "-disabled" : "") +
      "." +
      this.format);
  }
}
