import { IPatientPii, IPatientState, IPatientType } from "../../@types/patient";

export const replaceSubstitutions = (
  inputText: string,
  pii: IPatientPii | null,
  patientState?: IPatientState | null,
  patientType?: IPatientType | null,
) => {
  const matches = inputText.match(/{{.*?}}/g);
  if (matches == null) {
    return inputText;
  }

  const uniqueMatches = [...new Set(matches)].map((m) =>
    m.replace(/{{/g, "").replace(/}}/g, ""),
  );
  const substitutionValues = uniqueMatches.map((m) => {
    const match = m as keyof IPatientPii | "type" | "firstInitial";
    switch (match) {
      case "firstInitial":
        return pii?.firstName?.substr(0, 1);
      case "state":
        return patientState && patientState.name;
      case "type":
        return patientType && patientType.name;
      case "preferredName":
        return pii?.preferredName || pii?.firstName;
      default: {
        const sliceMatches = match.match(/\[(.*?)]/);
        return pii != null && sliceMatches != null
          ? getSubstitutionSubstring(
              match.substr(0, match.indexOf("[")) as keyof IPatientPii,
              sliceMatches[1],
              pii,
            )
          : pii && pii[match];
      }
    }
  });

  let populatedText = inputText;

  uniqueMatches.forEach((m, i) => {
    populatedText = populatedText.replace(
      new RegExp(`{{${m.replace(/\[/gi, "\\[").replace(/]/gi, "\\]")}}}`, "g"),
      (substitutionValues[i] as string) || m,
    );
  });

  return populatedText;
};

const getSubstitutionSubstring = (
  property: keyof IPatientPii,
  slice: string,
  pii: IPatientPii,
) => {
  const value = pii[property];
  if (value == null || typeof value !== "string") {
    return value;
  }
  const { start, end } = parseSliceString(slice, value.length);
  return value.substring(start, end);
};

export const parseSliceString = (slice: string, length: number) => {
  let [start, end] = slice
    .split(":")
    .map((s) => (isNaN(parseInt(s, 10)) ? NaN : Number(s)));
  start = isNaN(start) ? 0 : start < 0 ? length + start : start;
  end = isNaN(end)
    ? length
    : end < 0
    ? length + end
    : end > length
    ? length
    : end;
  return { start, end };
};
