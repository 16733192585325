import {
  getThresholdAllMarkers,
  ThresholdOrderPriorityByMarker,
} from "@/factories/thresholds-factory";
import logger from "@/logger";
import router from "@/router/routes";
import {
  deletePatientThreshold,
  getThresholds,
  updatePatientThreshold,
} from "@/services/thresholds.service";
import { Module } from "vuex";

import { IRootState, IThresholdModuleState } from "../../../@types/store";
import { IThreshold } from "../../../@types/thresholds";

const initialState: IThresholdModuleState = {
  openedThresholds: [],
  selectedRuleKey: null,
  patientThresholdMap: {},
};

const module: Module<IThresholdModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getThresholds(
      { commit, rootGetters },
      { patientId }: { patientId: string },
    ) {
      try {
        const { data: thresholds } = await getThresholds(
          rootGetters.rootOrganization.id,
          patientId,
        );
        commit("setPatientThresholds", { patientId, thresholds });
      } catch (error) {
        logger.error(error as Error);
      }
    },
    async updateThreshold(
      { commit, dispatch },
      { orgId, patientId, threshold },
    ) {
      commit("dataPending");
      try {
        await updatePatientThreshold(orgId, patientId, threshold);
        dispatch("getThresholds", { patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      } finally {
        commit("dataReceived");
      }
    },

    async deleteThreshold({ dispatch }, { orgId, patientId, thresholdId }) {
      try {
        await deletePatientThreshold(orgId, patientId, thresholdId);
        dispatch("getThresholds", { patientId });
      } catch (error) {
        logger.error(error as Error);
        dispatch("populateHttpErrors", (error as any).response);
      }
    },
  },
  getters: {
    selectedRuleKey(state) {
      return state.selectedRuleKey;
    },
    selectedThreshold(state) {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientThresholdMap?.[patientId]?.find(
        (tr) => tr.rules?.[state.selectedRuleKey || ""] != null && !tr.hidden,
      );
    },
    openedThresholds(state) {
      return state.openedThresholds;
    },
    patientThresholdMap(state) {
      return state.patientThresholdMap;
    },
    patientThresholds: (state) => {
      const patientId = router.currentRoute?.params?.patientId;
      return state.patientThresholdMap?.[patientId] || [];
    },
  },
  mutations: {
    resetThresholdsModule(state) {
      state.selectedRuleKey = null;
      state.openedThresholds = [];
      state.patientThresholdMap = {};
    },

    resetThresholdSelectState(state) {
      state.selectedRuleKey = null;
      state.openedThresholds = [];
    },

    selectRule(state, key: string | null) {
      state.selectedRuleKey = key;
    },

    setPatientThresholds(
      state,
      data: { thresholds: IThreshold[]; patientId: string } | null,
    ) {
      const sorted = [...(data?.thresholds || [])]?.sort(
        (a, b) =>
          Math.min(
            ...getThresholdAllMarkers(a).map(
              (m) =>
                ThresholdOrderPriorityByMarker[
                  m as keyof typeof ThresholdOrderPriorityByMarker
                ],
            ),
          ) -
          Math.min(
            ...getThresholdAllMarkers(b).map(
              (tm) =>
                ThresholdOrderPriorityByMarker[
                  tm as keyof typeof ThresholdOrderPriorityByMarker
                ],
            ),
          ),
      );
      if (data != null) {
        state.patientThresholdMap[data.patientId] = sorted || [];
      }
      state.patientThresholdMap = { ...state.patientThresholdMap };
    },

    toggleThreshold(state, thresholdId: string) {
      if (state.openedThresholds.some((id) => id === thresholdId)) {
        state.openedThresholds = state.openedThresholds.filter(
          (id) => id !== thresholdId,
        );
        return;
      }
      state.openedThresholds.push(thresholdId);
    },
  },
};

export default module;
