export const deviceNameMap: Record<string, string> = {
  app_android_myia_companion: "Android App",
  "app_mobile_myia_health-connect": "MYIA Health Connect",
  app_android_myia_hub: "Hub",
  app_ios_myia_companion: "iOS App",
  "monitor_blood-pressure_omron_hem-9210t": "Omron BP Monitor",
  "monitor_blood-pressure_and_ua-651ble": "A&D BP Monitor",
  "monitor_blood-pressure_withings_connect_wpm05": "Withings BP Monitor",
  "monitor_blood-pressure_bodytrace_bt105": "Bodytrace Blood Pressure",
  "monitor_pulse-oximeter_contec_cms50d-bt": "Pulse Ox Contec",
  "monitor_pulse-oximeter_contec_cms50d-btv1_7": "Pulse Ox Contec V1_7",
  "monitor_pulse-oximeter_nonin_3230": "Pulse Ox Nonin",
  tracker_bcg_biointellisense_core: "BioIntellisense Tracker",
  tracker_bcg_withings_sleep_wsm02: "Withings Sleep Sensor",
  tracker_bcg_emfit_qs: "Sleep Sensor",
  "tracker_bcg_early-sense_noomi": "EarlySense Sleep Sensor",
  patch_ecg_vivalnk_vitalscout: "ECG Patch",
  scale_bodytrace_bt005: "Bodytrace Weight Scale",
  "scale_bmi_and_uc-352ble": "A&D Scale Monitor",
  "scale_bmi_withings_wbs-06": "Withings Weight Scale",
  "scale_bmi_yolanda_qn-wifi-0105": "Yolanda Weight Scale",
  "smart-ring": "Smart Ring",
  "virtual_blood-glucose_myia_manual": "Manual Blood Glucose",
  "virtual_blood-pressure_myia_manual": "Manual Blood Pressure",
  "virtual_pulse-oximeter_myia_manual": "Manual Pulse Ox",
  "virtual_ml_myia_cgp+ttev1": "MLCGP",
  "virtual_ml_myia_grad+boostv1": "MLGRAD",
  "virtual_ml_myia_ieiv1.3": "MLIEI",
  virtual_scale_bmi_myia_manual: "Manual Weight Scale",
  unidentified_hotspot_device: "Hotspot",
  unidentified_unknown_device: "Other Devices",
  unidentified_video_device: "Video",
  other_issue: "Other",
  alert_task_notification_issue: "Alerts/Tasks/Notifications",
  charts_vitals_issue: "Charts/Vitals",
  surveys_issue: "Surveys",
  messaging_issue: "Messaging",
};

export const ID_SEPARATOR = "___";

export enum NonResourceIssueTypeEnum {
  TaskNotification = "alert_task_notification_issue",
  Vital = "charts_vitals_issue",
  Survey = "surveys_issue",
  Message = "messaging_issue",
  Other = "other_issue",
}

export enum ResourceIdEnum {
  AndroidCompanion = "app_android_myia_companion",
  Cradlepoint = "router_iot_cradlepoint_ibr600c",
  BioIntellisense = "tracker_bcg_biointellisense_core",
  Contec = "monitor_pulse-oximeter_contec_cms50d-bt",
  ContecV1_7 = "monitor_pulse-oximeter_contec_cms50d-btv1_7",
  Nonin = "monitor_pulse-oximeter_nonin_3230",
  Emfit = "tracker_bcg_emfit_qs",
  Hub = "app_android_myia_hub",
  HealthConnectApp = "app_mobile_myia_health-connect",
  iOSCompanion = "app_ios_myia_companion",
  Omron = "monitor_blood-pressure_omron_hem-9210t",
  AndBP = "monitor_blood-pressure_and_ua-651ble",
  AndScale = "scale_bmi_and_uc-352ble",
  AndScaleSecondary = "scale_bmi_and_uc-352ble-secondary",
  Ring = "smart-ring",
  Vivalnk = "patch_ecg_vivalnk_vitalscout",
  Yolanda = "scale_bmi_yolanda_qn-wifi-0105",
  BodytraceScale = "scale_bodytrace_bt005",
  BodytraceBloodPressure = "monitor_blood-pressure_bodytrace_bt105",
  WithingsScale = "scale_bmi_withings_wbs-06",
  WithingsSleepSensor = "tracker_bcg_withings_sleep_wsm02",
  WithingsBP = "monitor_blood-pressure_withings_connect_wpm05",
  EarlySenseSleepSensor = "tracker_bcg_early-sense_noomi",
  ManualBloodGlucose = "virtual_blood-glucose_myia_manual",
  ManualBloodPressure = "virtual_blood-pressure_myia_manual",
  ManualPulseOximeter = "virtual_pulse-oximeter_myia_manual",
  ManualScale = "virtual_scale_bmi_myia_manual",
  ManualTemperature = "virtual_temperature_myia_manual",
  ManualBloodGlucoseProvider = "virtual_blood-glucose_myia_manual-provider",
  ManualBloodPressureProvider = "virtual_blood-pressure_myia_manual-provider",
  ManualPulseOximeterProvider = "virtual_pulse-oximeter_myia_manual-provider",
  ManualScaleProvider = "virtual_scale_bmi_myia_manual-provider",
  ManualTemperatureProvider = "virtual_temperature_myia_manual-provider",
  MachineLearningCGP = "virtual_ml_myia_cgp+ttev1",
  MachineLearningGrad = "virtual_ml_myia_grad+boostv1",
  MachineLearningIEI = "virtual_ml_myia_ieiv1.3",
  LSTBP = "monitor_blood-pressure_lst_migrate",
  LSTManualBPProvider = "virtual_blood-pressure_lst_manual-provider",
  LSTManualBP = "virtual_blood-pressure_lst_manual",
  LSTPulseOx = "monitor_pulse-oximeter_lst_migrate",
  LSTManualPulseOxProvider = "virtual_pulse-oximeter_lst_manual-provider",
  LSTManualPulseOx = "virtual_pulse-oximeter_lst_manual",
  LSTScale = "scale_bmi_lst_migrate",
  LSTManualScaleProvider = "virtual_scale_bmi_lst_manual-provider",
  LSTManualScale = "virtual_scale_bmi_lst_manual",
  LSTBloodGlucose = "monitor_blood-glucose_lst_migrate",
  LSTManualBloodGlucoseProvider = "virtual_blood-glucose_lst_manual-provider",
  LSTManualBloodGlucose = "virtual_blood-glucose_lst_manual",
  LSTTemperature = "monitor_temperature_lst_migrate",
  LSTManualTemperatureProvider = "virtual_temperature_lst_manual-provider",
  LSTManualTemperature = "virtual_temperature_lst_manual",
  Hotspot = "unidentified_hotspot_device",
  OtherDevices = "unidentified_unknown_device",
  Video = "unidentified_video_device",
}

export const deviceImgPathMap: Record<string, string> = {
  app_android_myia_hub: require("@/assets/images/device-hub.jpg"),
  "app_mobile_myia_health-connect": require("@/assets/images/app-health-connect.png"),
  patch_ecg_vivalnk_vitalscout: require("@/assets/images/device-vivalink.jpg"),
  "scale_bmi_withings_wbs-06": require("@/assets/images/device-withings.jpg"),
  "scale_bmi_yolanda_qn-wifi-0105": require("@/assets/images/device-yolanda.jpg"),
  tracker_bcg_emfit_qs: require("@/assets/images/device-emfit.png"),
  "monitor_pulse-oximeter_contec_cms50d-bt": require("@/assets/images/device-contec-pulseox.jpg"),
  "monitor_pulse-oximeter_contec_cms50d-btv1_7": require("@/assets/images/device-contec-pulseox.jpg"),
  "monitor_pulse-oximeter_nonin_3230": require("@/assets/images/device-nonin-pulseox.png"),
  "monitor_blood-pressure_omron_hem-9210t": require("@/assets/images/device-omron.jpg"),
  "monitor_blood-pressure_bodytrace_bt105": require("@/assets/images/device-bodytrace-bp.png"),
  "monitor_blood-pressure_and_ua-651ble": require("@/assets/images/device-and-bp.png"),
  "monitor_blood-pressure_withings_connect_wpm05": require("@/assets/images/device-withings-bp.png"),
  "scale_bmi_and_uc-352ble": require("@/assets/images/device-and-scale.png"),
  "scale_bmi_and_uc-352ble-secondary": require("@/assets/images/device-and-scale.png"),
  scale_bodytrace_bt005: require("@/assets/images/device-bodytrace-scale.png"),
  "tracker_bcg_early-sense_noomi": require("@/assets/images/device-earlysense.png"),
  tracker_bcg_biointellisense_core: require("@/assets/images/device-biointellisense.png"),
  "monitor_pulse-oximeter": require("@/assets/images/device-general-pulse-ox.png"),
  "monitor_blood-pressure": require("@/assets/images/device-general-bp-monitor.png"),
  scale_bmi: require("@/assets/images/device-general-scale.png"),
  default: require("@/assets/images/device-general.png"),
};

export enum ResourceShortTypes {
  Companion = "companion",
  Contec = "contec",
  ContecV1_7 = "contec_v1_7",
  Nonin = "nonin",
  Emfit = "emfit",
  Omron = "omron",
  AndBP = "andBP",
  AndScale = "andScale",
  AndScaleSecondary = "andScaleSecondary",
  BodytraceScale = "bodytraceScale",
  BodytraceBloodPressure = "bodytraceBP",
  Vivalnk = "vivalnk",
  Yolanda = "yolanda",
  WithingsScale = "withingsScale",
  WithingsSleepSensor = "withingsSleepSensor",
  WithingsBP = "withingsBloodPressure",
  EarlySenseSleepSensor = "earlySense",
  ManualBloodGlucose = "manualBloodGlucose",
  ManualBloodPressure = "manualBloodPressure",
  ManualPulseOximeter = "manualPulseOximeter",
  ManualScale = "manualScale",
  ManualTemperature = "manualTemperature",
  ManualBloodGlucoseProvider = "manualBloodGlucoseProvider",
  ManualBloodPressureProvider = "manualBloodPressureProvider",
  ManualPulseOximeterProvider = "manualPulseOximeterProvider",
  ManualScaleProvider = "manualScaleProvider",
  ManualTemperatureProvider = "manualTemperatureProvider",
  MachineLearningGrad = "ml_grad",
  MachineLearningCGP = "ml_cgp",
  MachineLearningIEI = "ml_iei",
  LSTBP = "lstBloodPressure",
  LSTManualBPProvider = "manualBloodPressureProvider",
  LSTManualBP = "manualBloodPressure",
  LSTPulseOx = "lstPulseOximeter",
  LSTManualPulseOxProvider = "manualPulseOximeterProvider",
  LSTManualPulseOx = "manualPulseOximeter",
  LSTScale = "lstScale",
  LSTManualScaleProvider = "manualScaleProvider",
  LSTManualScale = "manualScale",
  LSTBloodGlucose = "lstBloodGlucose",
  LSTManualBloodGlucoseProvider = "manualBloodGlucoseProvider",
  LSTManualBloodGlucose = "manualBloodGlucose",
  LSTTemperature = "lstTemperature",
  LSTManualTemperatureProvider = "manualTemperatureProvider",
  LSTManualTemperature = "manualTemperature",
}

export enum TimelineEventTaskCategories {
  NewTask = "New Task",
  ClearedTask = "Cleared Task",
  PersonalEvent = "Personal Event",
  MedicalEvent = "Medical Event",
}

export const continuesDataTypes: string[] = [
  "avgHr",
  "avgRr",
  "maxHr",
  "minHr",
  "minRr",
  "maxRr",
  "durationInSleep",
  "durationInBed",
];

export const multipleDataTypes: string[] = [
  "weight",
  "pulseRate",
  "o2",
  "riskScore",
  "riskClass",
  "systolicBp",
  "diastolicBp",
  "glucoseFasting",
  "glucosePreMeal",
  "glucosePostMeal",
  "medianRestHr",
  "maxRestHr",
  "minRestHr",
  "medianRestRr",
  "maxRestRr",
  "minRestRr",
  "temperature",
  "medianTemperature",
  "maxTemperature",
  "minTemperature",
];

export const riskClassMap: Record<string, number> = {
  high: 3,
  medium: 2,
  low: 1,
};

export const surveyGroupAnswersMap: Record<string, Record<string, number>> = {
  dailyBreathing: {
    better_than_yesterday: 3,
    same_as_yesterday: 2,
    worse_than_yesterday: 1,
  },
  dailySwelling: {
    better_than_yesterday: 3,
    same_as_yesterday: 2,
    worse_than_yesterday: 1,
  },
  dailySymptoms: {
    better_than_usual: 3,
    same_as_usual: 2,
    worse_than_usual: 1,
  },
};

export const listPageOrderedReadings: string[] = [
  "riskClass",
  "riskScore",
  "pulseRate",
  "respiratoryRate",
  "sbp",
  "weight",
  "o2",
  "glucose",
  "temperature",
  "survey",
];

export const KG_TO_LB_MULTIPLIER = 2.20462;
export const HASHTAG = "#";
export const PATIENT_ID_TYPES = ["MRN", "EPI", "MR"];
export const PRELOADED_PATIENTS_COUNT = 20;

export enum IChartDisplayTypesEnum {
  Regular = "Chart View",
  Detailed = "Detailed Chart",
  Histogram = "Histogram",
  Table = "Table",
}

export enum ImMessageTypeEnum {
  Text = "Text",
  Image = "Image",
  Audio = "Audio",
  Video = "Video",
  Campaign = "Campaign",
  EduMaterial = "EduMaterial",
  HelpRequest = "HelpRequest",
}

export enum ImAuthorTypeEnum {
  Org = "Org",
  Patient = "Patient",
  Provider = "Provider",
  System = "System",
}

export const DEFAULT_TIMEZONE =
  process.env.VUE_APP_DEFAULT_TIMEZONE || "US/Central";

export const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const DEFAULT_ROLE_COLOR = "#979797";

// do not edit the following two formats,
// they are essential for charts and reports to work properly
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_YYYY_MM = "YYYY-MM";

export const DATE_FORMAT_M_D_YYYY = "M/D/YYYY";
export const DATE_FORMAT_DDDD = "dddd";
export const DATE_FORMAT_MMM_D = "MMM D";
export const DATE_FORMAT_MMM_D_YYYY = "MMM D, YYYY";
export const DATE_FORMAT_DDDD_MMM_D = "dddd, MMM D";
export const DATE_FORMAT_DDDD_MMM_D_H_MMA_Z = "dddd, MMM D, h:mma z";
export const DATE_FORMAT_DDDD_MMM_D_YYYY = "dddd, MMM D, YYYY";
export const DATE_FORMAT_DDDD_MMM_D_YYYY_H_MMA_Z = "dddd, MMM D, YYYY, h:mma z";
export const DATE_TIME_FORMAT_DDD_MMM_D_H_MMA = "ddd, MMM D, h:mma";
export const DATE_TIME_FORMAT_DDD_MMM_D_H_MMA_Z = "ddd, MMM D, h:mma z";
export const DATE_FORMAT_MMM_D_MMA = "MMM D, h:mma";
export const DATE_TIME_FORMAT_DDDD_MMM_D_YYYY_H_MMA_Z =
  "dddd, MMM D, YYYY, h:mma z";
export const DATE_FORMAT_MMMM = "MMMM";
export const DATE_FORMAT_YYYY = "YYYY";
export const DATE_FORMAT_D = "D";

export const TIME_FORMAT_H_MMA = "h:mma";
export const TIME_FORMAT_H_MM_A = "h:mm a";
export const TIME_FORMAT_H_MMA_Z = "h:mma z";
export const TIME_FORMAT_Z = "z";
export const TIME_FORMAT_HH = "hh";
export const TIME_FORMAT_HH_24 = "HH";
export const TIME_FORMAT_MM = "mm";
export const TIME_FORMAT_A = "a";
export const TIME_FORMAT_HA = "ha";

export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const HOUR_IN_SECONDS = MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_SECOND = 1000;
export const DAY_IN_MILLISECONDS =
  HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const DAY_IN_SECONDS =
  HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30;
export const DAYS_IN_YEAR = 365;

export const REPEATING_EVENT_MIN_RANGE = DAYS_IN_WEEK * DAY_IN_MILLISECONDS;
export const REPEATING_EVENT_FORWARD_LIMIT = DAYS_IN_YEAR * DAY_IN_MILLISECONDS;

export const APPOINTMENT_PASSED_THRESHOLD =
  MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const INTERACTION_EVENT_LIST = [
  "click",
  "mousedown",
  "scroll",
  "mousemove",
  "keypress",
];

export const TIMEOUT_IN_MILLISECONDS = 30 * 60 * 1000;

export const PAGINATION_LIMITS = {
  im_messages: 100,
  surveys: 100,
  activity_logs: 100,
};

export const hoursRangeMap: Record<string, string> = {
  T00: "12am - 1am",
  T01: "1am - 2am",
  T02: "2am - 3am",
  T03: "3am - 4am",
  T04: "4am - 5am",
  T05: "5am - 6am",
  T06: "6am - 7am",
  T07: "7am - 8am",
  T08: "8am - 9am",
  T09: "9am - 10am",
  T10: "10am - 11am",
  T11: "11am - 12pm",
  T12: "12pm - 1pm",
  T13: "1pm - 2pm",
  T14: "2pm - 3pm",
  T15: "3pm - 4pm",
  T16: "4pm - 5pm",
  T17: "5pm - 6pm",
  T18: "6pm - 7pm",
  T19: "7pm - 8pm",
  T20: "8pm - 9pm",
  T21: "9pm - 10pm",
  T22: "10pm - 11pm",
  T23: "11pm - 12am",
};

export const ZIP_TO_COORDINATES_URL =
  "https://usa-zip-to-coordinates.cyberlabs.workers.dev/";
