import moment from "moment";
import {
  DATE_FORMAT_YYYY_MM,
  DATE_FORMAT_YYYY_MM_DD,
  TIME_FORMAT_HH_24,
} from "@/configs/constants";

export const secondsToHms = (seconds: number) => {
  if (!seconds) {
    return null;
  }
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  return { h, m, s };
};

export const getCurrentMonth = () => {
  return moment().format(DATE_FORMAT_YYYY_MM);
};

export const getBirthDate = (date: string) => {
  if (!date) {
    return "";
  }
  const [year, month, day] = date.split("-");
  return `${Number(month)}/${Number(day)}/${year}`;
};

export const getPatientAge = (date?: string | null) => {
  if (!date) {
    return null;
  }
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const sortDateOrder = (dates: string[]) => {
  return dates.sort((b: string, a: string) => {
    return new Date(b).getTime() - new Date(a).getTime();
  });
};

export const dateDiffInDays = (a: Date, b: Date) => {
  const msPerDay = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / msPerDay);
};

export const getTimelineDates = (
  startDate: string,
  endDate: string,
  makeGranular?: boolean,
) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const dateArr: string[] = [];

  while (start.isSameOrBefore(end)) {
    dateArr.push(start.format(DATE_FORMAT_YYYY_MM_DD));
    start.add(1, "days");
  }
  if (dateArr[dateArr.length - 1] !== endDate) {
    dateArr.push(endDate);
  }
  return makeGranular ? getGranularChartTimeline(dateArr) : dateArr;
};

export const getHourlyTimeRange = (startDate: number, endDate: number) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const timeArray: string[] = [];

  while (start.isSameOrBefore(end)) {
    timeArray.push(
      start.format(`${DATE_FORMAT_YYYY_MM_DD}T${TIME_FORMAT_HH_24}`),
    );
    start.add(1, "hour");
  }
  return timeArray;
};

export const getGranularChartTimeline = (dailyTimeline: string[]) => {
  if (!dailyTimeline.length) {
    return [];
  }
  const granularTimeline: string[] = [];
  dailyTimeline.forEach((value) => {
    for (let i = 0; i < 24; i++) {
      granularTimeline.push(`${value}T${i < 10 ? "0" : ""}${i}`);
    }
  });
  return granularTimeline;
};

export const zoneAbbr: { [key: string]: string } = {
  AHDT: "Alaska Daylight Time",
  AHST: "Alaska Standard Time",
  AST: "Atlantic Standard Time",
  BDT: "Hawaii-Aleutian Daylight Time",
  BST: "Hawaii-Aleutian Standard Time",
  CDT: "Central Daylight Time",
  CHST: "Chamorro Standard Time",
  CST: "Central Standard Time",
  EDT: "Eastern Daylight Time",
  EST: "Eastern Standard Time",
  HST: "Hawaii Standard Time",
  MDT: "Mountain Daylight Time",
  MST: "Mountain Standard Time",
  PDT: "Pacific Daylight Time",
  PST: "Pacific Standard Time",
  SDT: "Samoa Daylight Time",
  SST: "Samoa Standard Time",
};
