import ConfirmModal from "@/components/ConfirmModal.vue";
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import MyiaEditor from "@/components/MyiaEditor.vue";
import MyiaIcon from "@/components/MyiaIcon.vue";
import MyiaInput from "@/components/MyiaInput.vue";
import MyiaInputCalendar from "@/components/MyiaInputCalendar.vue";
import MyiaInputCheckbox from "@/components/MyiaInputCheckbox.vue";
import MyiaInputRadio from "@/components/MyiaInputRadio.vue";
import MyiaInputSelect from "@/components/MyiaInputSelect.vue";
import MyiaInputGroupSelect from "@/components/MyiaInputGroupSelect.vue";
import MyiaLoading from "@/components/MyiaLoading.vue";
import MyiaMedia from "@/components/MyiaMedia.vue";
import MyiaTimedOut from "@/components/MyiaTimedOut.vue";
import PieLoader from "@/components/PieLoader.vue";
import NoPermissionIndicator from "@/components/NoPermissionIndicator.vue";
import Vue from "vue";

Vue.component("myia-input", MyiaInput);
Vue.component("myia-editor", MyiaEditor);
Vue.component("myia-loading", MyiaLoading);
Vue.component("myia-icon", MyiaIcon);
Vue.component("myia-input-calendar", MyiaInputCalendar);
Vue.component("myia-input-select", MyiaInputSelect);
Vue.component("myia-input-group-select", MyiaInputGroupSelect);
Vue.component("myia-input-radio", MyiaInputRadio);
Vue.component("myia-input-checkbox", MyiaInputCheckbox);
Vue.component("myia-modal-template", ModalTemplate);
Vue.component("myia-confirm-modal", ConfirmModal);
Vue.component("myia-media", MyiaMedia);
Vue.component("myia-no-permission", NoPermissionIndicator);
Vue.component("myia-timed-out", MyiaTimedOut);
Vue.component("pie-loader", PieLoader);
