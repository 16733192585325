import {
  getProviderById,
  getTeamsByOrg,
} from "@/factories/organization-factory";
import {
  DATE_FORMAT_YYYY_MM_DD,
  HASHTAG,
  PRELOADED_PATIENTS_COUNT,
} from "@/configs/constants";
import {
  getAdditionalProperties,
  getUniqueIdentifiers,
  setPatientListItemDataLoaded,
  sortByAlerts,
  sortByAlphabetic,
  sortByRecentlyViewed,
  sortByReviewDate,
} from "@/factories/patient-factory";
import logger from "@/logger";
import router from "@/router/routes";
import {
  getOrganizationPatientInitialData,
  getOrganizationWatchlists,
} from "@/services/organization.service";
import { getPatientsResources } from "@/services/patient.service";
import { patientListItemLoader, providersLoader } from "@/utils/data_loader";
import { Organization, Patient, Pii, Provider } from "generated/types-gql";
import moment from "moment-timezone";
import { Module } from "vuex";

import { IOrganization } from "../../../@types/organization";
import { IOrgPatientWatchlist, IPatient } from "../../../@types/patient";
import {
  IListFilters,
  IOrganizationModuleState,
  IPatientFilterBadgeShortType,
  IPatientSortType,
  IPriorityPatientFilterBadgeShortType,
  IRootState,
} from "../../../@types/store";

const initialState: IOrganizationModuleState = {
  organizations: null,
  organizationTreeFlatMap: {},
  organizationTeamHistoryMap: {},
  rootOrganization: null,
  currentOrganization: null,
  selectedTeams: [],
  allProvidersList: [],
  patientWatchlistMap: {},
  cachedPatientLists: {},
  patientIdMap: {},
  listFilters: {
    providerAssignments: [],
    badge: null,
    teamAssignment: null,
  },
  priorityListFilter: null,
  listSortValue: null,
  delayedDataLoaded: false,
  patientSearchOrgIdMap: {},
  isPatientSearchItemsLoading: false,
};

const module: Module<IOrganizationModuleState, IRootState> = {
  state: initialState,

  actions: {
    async getTeamPatients({ commit, state, getters }, teams: string[]) {
      try {
        commit("dataPending");

        const cachedTeamPatients = teams
          .flatMap((t) => state.cachedPatientLists[t])
          .filter((p) => p);
        const teamsToFetch = teams.filter(
          (t) => state.cachedPatientLists[t] == null,
        );
        let allTeams: {
          patients: Patient[];
          watchlists: IOrgPatientWatchlist[];
          organization: string;
        }[] = [];

        let preloadedPatients: IPatient[] = [];
        const count = getters.isAllPatientListHidden
          ? 0
          : PRELOADED_PATIENTS_COUNT;
        if (teamsToFetch.length > 0 && state.rootOrganization?.id) {
          const data = await getOrganizationPatientInitialData(
            state.rootOrganization.id,
            teamsToFetch || [],
            count,
          );
          preloadedPatients = data.preloadedPatients;
          allTeams = data.allTeams;
          allTeams.forEach((t) => {
            commit("cachePatients", {
              list: t.patients,
              cacheId: t.organization,
            });
          });
        }
        if (
          state.selectedTeams.length !== teams.length ||
          state.selectedTeams.some((t) => !teams.includes(t.id))
        ) {
          return;
        }
        const patientList =
          allTeams.flatMap((t) => t.patients).concat(cachedTeamPatients) || [];
        commit("setPatientList", patientList);
        commit("updatePatientListPatients", preloadedPatients);
        sessionStorage.setItem("lastUpdated", `${Date.now()}`);
      } catch (error) {
        logger.error(error as Error);
      } finally {
        commit("dataReceived");
      }
    },

    async resetRootOrganizationData({ commit }) {
      try {
        commit("resetRootOrgMembers");
        commit("setQuickMessages", null);
        commit("updateDelayedDataLoaded", true);
        commit("resetCachePatients");
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getOrganizationResources({ commit }, orgId: string) {
      try {
        const resources = (await getPatientsResources(orgId))?.data;
        commit("setOrgResources", resources);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getPatientListData({ commit, state }, ids: string[]) {
      const rootOrgId = state.rootOrganization?.id;
      if (!rootOrgId) {
        return;
      }
      try {
        const patientData = await patientListItemLoader.loadMany(
          ids.map((id) => `${rootOrgId}${HASHTAG}${id}`),
        );
        const patientList = patientData.map(setPatientListItemDataLoaded);
        commit("updatePatientListPatients", patientList);
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getOrganizationWatchlists({ commit, state, getters }) {
      try {
        if (state.rootOrganization?.id == null) {
          throw new Error("Missing root organization ID");
        }
        if (!getters.patientWatchlistsEnabled) {
          return;
        }
        const { data: watchlists } = await getOrganizationWatchlists(
          state.rootOrganization.id,
        );
        commit(
          "setWatchlists",
          (watchlists ?? []).filter((w) => w.sk.endsWith("v0")) || [],
        );
      } catch (error) {
        logger.error(error as Error);
      }
    },

    async getProviders({ commit, rootGetters }, ids: string[]) {
      ids = ids.filter((id) => id.toLowerCase() !== "system");
      if (!ids.length) {
        return;
      }
      try {
        const rootOrgId = rootGetters.rootOrganization?.id;
        const providers = await providersLoader.loadMany(
          ids.map((id) => `${rootOrgId}${HASHTAG}${id}`),
        );

        if (providers.filter(Boolean)?.length) {
          commit("updateProviderList", providers);
        }
      } catch (error) {
        logger.error(error as Error);
      }
    },

    updateCurrentOrganization(
      { state, commit, dispatch },
      organization: IOrganization,
    ) {
      if (state.currentOrganization?.id == organization.id) {
        return;
      }
      commit("setCurrentOrganization", organization);
      const savedTeamIds: string[] = [];
      if (state.organizationTeamHistoryMap[organization.id]?.length) {
        savedTeamIds.push(...state.organizationTeamHistoryMap[organization.id]);
      } else {
        const fromSession = sessionStorage.getItem("selectedTeams");
        const fromLocal = localStorage.getItem("selectedTeams");
        if (fromSession) {
          savedTeamIds.push(...JSON.parse(fromSession));
        } else if (fromLocal) {
          savedTeamIds.push(...JSON.parse(fromLocal));
        }
        commit("updateOrganizationTeamMap", savedTeamIds);
      }
      const teams = getTeamsByOrg(organization);
      const selectedTeams =
        teams.length === 1
          ? teams
          : teams.filter((t) => savedTeamIds.includes(t.id));
      dispatch("updateSelectedTeams", selectedTeams);
    },
    updateSelectedTeams({ dispatch, commit }, teams: Organization[]) {
      commit("resetPatientList");
      commit("setSelectedTeams", teams);
      dispatch(
        "getTeamPatients",
        teams.map((t) => t.id),
      );
      commit(
        "updateOrganizationTeamMap",
        teams.map((t) => t.id),
      );
    },

    async updatePatientListData({ state, commit }) {
      if (!state.selectedTeams.length || !state.rootOrganization?.id) {
        return;
      }
      const teamIds: string[] = (state.selectedTeams as IOrganization[])?.map(
        (t) => t.id,
      );

      const { allTeams } = await getOrganizationPatientInitialData(
        state.rootOrganization.id,
        teamIds || [],
        0,
      );

      allTeams.forEach((t: any) => {
        commit("cachePatients", {
          list: t.patients,
          cacheId: t.organization,
        });
      });

      if (
        state.selectedTeams.some(
          (selectedTeam) => !teamIds.includes(selectedTeam.id),
        )
      ) {
        return;
      }

      const patientList =
        allTeams
          .filter((team: any) =>
            state.selectedTeams.find(
              (selectedTeam) => selectedTeam.id === team.organization,
            ),
          )
          .flatMap((t: any) => t.patients) || [];
      commit("setPatientList", patientList);
      sessionStorage.setItem("lastUpdated", `${Date.now()}`);
    },
  },
  getters: {
    organizationTreeFlatMap(state) {
      return state.organizationTreeFlatMap;
    },
    organizations(state) {
      return state.organizations;
    },

    rootOrganization(state) {
      return state.rootOrganization;
    },

    currentTeam(state) {
      return state.selectedTeams.length === 1 ? state.selectedTeams[0] : null;
    },

    selectedTeams(state) {
      return state.selectedTeams;
    },

    isOrgInRootOrgTree(state, _, __, rootGetters) {
      const id = rootGetters.route.params?.orgId || "";
      const org = state.organizationTreeFlatMap[id];
      return org?.root === state.rootOrganization?.id;
    },

    delayedDataLoaded(state) {
      return state.delayedDataLoaded;
    },

    isListPageNewHeaderEnabled(_, getters) {
      return getters?.listPageConfigs?.enable_header_new_design;
    },

    isAllPatientListHidden(_, getters) {
      return getters?.listPageConfigs?.hide_all_patient_list;
    },

    teamMembers(_, getters) {
      const currentTeam: IOrganization = getters.currentTeam;
      if (currentTeam == null) {
        return [];
      }
      return Object.values(currentTeam.roleAssignments || {})
        ?.filter((role) => role.provider && role.role)
        .map((r) => {
          return {
            role: getters.allRolesMap[r.role as string],
            ...(getters.providerIdMap?.[r.provider || ""] ||
              getProviderById(r.provider || "") ||
              {}),
          };
        });
    },

    organization(state) {
      return state.currentOrganization;
    },

    currentOrganizationTeamsIdMap(_, getters) {
      const teamsMap: Record<string, IOrganization | undefined> = {};
      const teams: IOrganization[] = getters.currentOrganizationTeams || [];
      for (const c of teams) {
        teamsMap[c.id] = c;
      }
      return teamsMap;
    },

    currentOrganizationTeams(state) {
      return state.currentOrganization
        ? getTeamsByOrg(state.currentOrganization)
        : [];
    },

    rootOrganizationTeamsIdMap(state) {
      const teamsMap: Record<string, IOrganization | undefined> = {};
      const teams: IOrganization[] = state.rootOrganization
        ? getTeamsByOrg(state.rootOrganization)
        : [];
      for (const c of teams) {
        teamsMap[c.id] = c;
      }
      return teamsMap;
    },

    teamPatientsList(state) {
      return Object.values(state.patientIdMap);
    },
    priorityPatientFilteredList(state, getters) {
      let patientList: IPatient[] = [...getters.teamPatientsList];
      const includePatientWithTasks =
        getters?.listPageConfigs?.include_tasks_in_priority_patients;
      switch (state.priorityListFilter) {
        case "clinicalAlerts":
        case "nonClinicalAlerts": {
          patientList = patientList.filter((p) =>
            state.priorityListFilter === "clinicalAlerts"
              ? p.medicalAlertsCount
              : p.nonMedicalAlertsCount,
          );
          break;
        }
        case "watchlist": {
          patientList = patientList.filter(
            (p) => state.patientWatchlistMap?.[p.id],
          );
          break;
        }
        default: {
          patientList = patientList.filter(
            (p) =>
              p.medicalAlertsCount ||
              p.nonMedicalAlertsCount ||
              state.patientWatchlistMap?.[p.id] ||
              (includePatientWithTasks && p.todayTasksCount),
          );
        }
      }

      return includePatientWithTasks
        ? sortByAlerts(
            patientList.sort(
              (a, b) => (b.todayTasksCount || 0) - (a.todayTasksCount || 0),
            ),
          )
        : sortByAlerts(patientList);
    },
    patientFilteredList(state, getters) {
      const { badge } = state.listFilters;

      let patientList: IPatient[] = [...getters.teamPatientsList];

      switch (badge) {
        case "newMessage":
          patientList = patientList.filter(
            (p) => (p.missedMessageCount || 0) > 0,
          );
          break;
        case "missingVitals":
          patientList = patientList.filter((p) =>
            p.$notificationTypes?.includes("missing-vitals"),
          );
          break;
        case "missingReceipts":
          patientList = patientList.filter((p) =>
            p.$notificationTypes?.includes("missing-read-receipt"),
          );
          break;
        case "noContact":
          patientList = patientList.filter((p) =>
            p.$notificationTypes?.includes("no-contact"),
          );
          break;
        case "contactRequests":
          patientList = patientList.filter((p) =>
            p.$notificationTypes?.includes("call-requested"),
          );
          break;
        case "providerSentSurveys":
          patientList = patientList.filter((p) =>
            p.$notificationTypes?.includes("provider-sent-surveys"),
          );
          break;
        case "notCompliant":
          patientList = patientList.filter((p) =>
            p.tags?.includes("not compliant"),
          );
          break;
        case "discharged":
          patientList = patientList.filter((p) =>
            p.tags?.includes("discharged"),
          );
          break;
        case "inHospital":
          patientList = patientList.filter((p) =>
            p.tags?.includes("hospitalized"),
          );
          break;
        case "notActivated":
          patientList = patientList.filter(
            (p) => !p?.currentState?.isActivePatientState,
          );
          break;
      }

      switch (state.listSortValue) {
        case "Alphabetical A-Z":
          return sortByAlphabetic(patientList, true);
        case "Alphabetical Z-A":
          return sortByAlphabetic(patientList);
        case "Your Recently Viewed":
          return sortByRecentlyViewed(patientList);
        case "Next Scheduled":
          return sortByReviewDate(patientList);
        default:
          return sortByReviewDate(patientList);
      }
    },
    patientPosition: (_, getters) => (patientId: string) => {
      let nextPatient: string | null = null;
      let previousPatient: string | null = null;
      let patientList: IPatient[] = getters.patientFilteredList;
      if (router.currentRoute?.params?.group === "search") {
        patientList = getters.teamPatientsList;
      } else if (router.currentRoute?.params?.group === "priority") {
        patientList = getters.priorityPatientFilteredList;
      }
      const index = patientList.findIndex((p) => p.id === patientId);

      if (index !== -1) {
        nextPatient = patientList[index + 1]?.id;
        previousPatient = patientList[index - 1]?.id;
      }

      return {
        groupLength: patientList.length,
        nextPatient,
        patientId,
        previousPatient,
      };
    },
    providerName: (state) => (providerId: string) =>
      providerId === "system"
        ? "system"
        : state.allProvidersList?.find((p) => p.id === providerId)?.firstName ||
          "unknown",

    providerIdMap: (state) => {
      const idMap: Record<string, Provider | undefined> = {};
      for (const c of state.allProvidersList ?? []) {
        idMap[c.id] = c;
      }
      return idMap;
    },

    patientIdMap: (state) => {
      return state.patientIdMap || {};
    },

    listFilters(state) {
      return state.listFilters;
    },

    priorityListFilter(state) {
      return state.priorityListFilter;
    },

    listSortValue(state) {
      return state.listSortValue;
    },

    watchlistMap: (state) => {
      return state?.patientWatchlistMap || {};
    },

    patientSearchItems: (state) => {
      return Object.values(
        state?.patientSearchOrgIdMap[state.rootOrganization?.id ?? ""] ?? {},
      );
    },

    isPatientSearchItemsLoading: (state) => {
      return state.isPatientSearchItemsLoading;
    },
    cachedPatientsList: (state) => {
      return Object.values(state.cachedPatientLists).flat() ?? [];
    },
  },
  mutations: {
    updateOrganizationTeamMap(state, teams: string[]) {
      if (state.currentOrganization) {
        state.organizationTeamHistoryMap[state.currentOrganization.id] =
          teams || [];
      }
    },
    updateDelayedDataLoaded(state, value: boolean) {
      state.delayedDataLoaded = value;
    },

    updatePatientListPatients(state, newList: IPatient[]) {
      if (!Array.isArray(newList)) {
        return;
      }

      const cachedPatientsMap: Record<string, Patient | undefined> = {};
      for (const team in state.cachedPatientLists) {
        for (const patient of state.cachedPatientLists[team]) {
          cachedPatientsMap[patient.id] = patient;
        }
      }

      const selectedTeams = state.selectedTeams.map((t) => t.id);
      const newPatientsMap: Record<string, Patient> = {};
      for (const p of newList) {
        if (!state.patientIdMap[p.id] && !cachedPatientsMap[p.id]) {
          continue;
        }
        // Trying to get pii from search list in order to sort by alphabetical order
        const pii =
          state.patientSearchOrgIdMap?.[state.rootOrganization?.id ?? ""]?.[
            p.id
          ]?.pii;

        const identifiers = [
          ...(cachedPatientsMap[p.id]?.pii?.identifiers || []),
          ...(pii?.identifiers || []),
          ...(state.patientIdMap[p.id]?.pii?.identifiers || []),
          ...(p.pii?.identifiers || []),
        ];

        const patientData = {
          ...state.patientIdMap[p.id],
          ...p,
          pii: {
            ...cachedPatientsMap[p.id]?.pii,
            ...pii,
            ...state.patientIdMap[p.id]?.pii,
            ...p.pii,
            identifiers: getUniqueIdentifiers(identifiers),
          } as Pii,
        };
        const formattedData = {
          ...patientData,
          ...getAdditionalProperties(patientData),
        };
        if (state.patientIdMap[p.id]) {
          state.patientIdMap[p.id] = {
            ...formattedData,
          };
          newPatientsMap[p.id] = {
            ...newPatientsMap[p.id],
            ...formattedData,
          };
          for (const orgId in state.cachedPatientLists) {
            if (selectedTeams.includes(orgId)) {
              state.cachedPatientLists[orgId] = (
                state.cachedPatientLists[orgId] || []
              ).map((p) => ({ ...p, ...newPatientsMap[p.id] }));
            }
          }
        } else {
          for (const id in state.cachedPatientLists) {
            for (const [index, patient] of state.cachedPatientLists[
              id
            ].entries()) {
              const pat =
                patient.id === p.id
                  ? { ...patient, ...formattedData }
                  : { ...patient, ...getAdditionalProperties(patient) };
              state.cachedPatientLists[id][index] = { ...pat };
            }
          }
        }
      }
    },
    resetPatientList(state) {
      state.patientIdMap = {};
    },

    resetCachePatients(state) {
      state.cachedPatientLists = {};
    },

    resetRootOrgMembers(state) {
      state.allProvidersList = [];
      state.patientWatchlistMap = {};
    },

    resetListFilters(state) {
      state.listFilters = {
        providerAssignments: [],
        badge: null,
        teamAssignment: null,
      };
    },

    resetPriorityListFilters(state) {
      state.priorityListFilter = null;
    },

    setWatchlists(state, watchlists: IOrgPatientWatchlist[]) {
      const patientMap: Record<string, IOrgPatientWatchlist | undefined> = {};
      for (const c of watchlists ?? []) {
        patientMap[c.patient] = c;
      }
      state.patientWatchlistMap = patientMap;
    },

    setPatientSearchItems(state, patients: IPatient[]) {
      if (!state.rootOrganization?.id) {
        return;
      }
      const patientsIdMap: Record<string, IPatient> = {};
      for (const p of patients) {
        patientsIdMap[p.id] = p;
      }
      state.patientSearchOrgIdMap = {
        ...state.patientSearchOrgIdMap,
        [state.rootOrganization.id]: patientsIdMap,
      };
    },

    setPatientSearchItemsLoading(state, isLoading: boolean) {
      state.isPatientSearchItemsLoading = isLoading;
    },

    setPatientList(state, list: IPatient[]) {
      const patientMap: Record<string, Patient | undefined> = {};
      for (const c of list ?? []) {
        const pii =
          state.patientSearchOrgIdMap?.[state.rootOrganization?.id ?? ""]?.[
            c.id
          ]?.pii;
        const patient = {
          pii,
          ...state.patientIdMap[c.id],
          ...c,
        };
        patientMap[c.id] = {
          ...patient,
          ...getAdditionalProperties(patient),
        };
      }
      state.patientIdMap = patientMap;
    },

    updateProviderList(state, list: Provider[]) {
      const missedProviders = list.filter(
        (p) => !state.allProvidersList.some((provider) => provider.id === p.id),
      );
      if (!missedProviders.length) return;
      state.allProvidersList = [...state.allProvidersList, ...missedProviders];
    },

    cachePatients(
      state,
      { list, cacheId }: { list: Patient[]; cacheId: string },
    ) {
      state.cachedPatientLists[cacheId] = list;
    },

    setAllProvidersList(state, list: Provider[]) {
      state.allProvidersList = list;
    },

    setRootOrganization(state, organization: IOrganization) {
      //Always set root organization from organization tree, as we use its child orgs
      state.rootOrganization = organization;
      sessionStorage.setItem("rootOrganizationId", organization.id);
      localStorage.setItem("rootOrganizationId", organization.id);
    },

    setSelectedTeams(state, organizations: IOrganization[]) {
      const ids = organizations.map((t) => t.id);
      sessionStorage.setItem("selectedTeams", JSON.stringify(ids) || "");
      localStorage.setItem("selectedTeams", JSON.stringify(ids) || "");
      state.selectedTeams = organizations;
    },

    setCurrentOrganization(state, organization: IOrganization) {
      sessionStorage.setItem("organizationId", organization.id);
      localStorage.setItem("organizationId", organization.id);
      state.currentOrganization = organization;
    },

    setOrganizations(state, organizations: IOrganization[]) {
      state.organizations = organizations;
    },

    setOrganizationsFlatMap(state, organizations: IOrganization[]) {
      const orgMap: Record<string, IOrganization | undefined> = {};
      for (const c of organizations ?? []) {
        orgMap[c.id] = c;
      }
      state.organizationTreeFlatMap = orgMap;
    },

    setPriorityListFilter(state, filter: IPriorityPatientFilterBadgeShortType) {
      state.priorityListFilter = filter;
    },

    setStoragePriorityFilter(state) {
      state.priorityListFilter =
        (sessionStorage.getItem(
          "priorityFilterBadge",
        ) as IPriorityPatientFilterBadgeShortType) || null;
    },

    setListFilter(state, listFilters: Partial<IListFilters>) {
      state.listFilters = {
        ...state.listFilters,
        ...listFilters,
      };
    },

    setListSortValue(state, value: IPatientSortType) {
      state.listSortValue = value;
    },

    setStorageSortValue(state) {
      const today = moment()
        .tz(moment.tz.guess())
        .format(DATE_FORMAT_YYYY_MM_DD);
      const fromStorage = moment(Number(localStorage.getItem("sessionDate")))
        .tz(moment.tz.guess())
        .format(DATE_FORMAT_YYYY_MM_DD);

      state.listSortValue =
        today !== fromStorage
          ? "Next Scheduled"
          : ((sessionStorage.getItem("sortBy") ||
              localStorage.getItem("sortBy")) as IPatientSortType) ||
            "Next Scheduled";
    },

    setStorageFilters(state) {
      state.listFilters = {
        ...state.listFilters,
        badge: sessionStorage.getItem(
          "filterBadge",
        ) as IPatientFilterBadgeShortType,
      };
    },
  },
};

export default module;
