export const BLACK = "#000000";
export const WHITE = "#FFFFFF";
export const GRAY_100 = "#F5F5F5";
export const GRAY_200 = "#D8D8D8";
export const RED_400 = "#E02C3D";
export const BlUE_400 = "#2F72E4";
export const SKY_BLUE_200 = "#CDEAFD";
export const SKY_BLUE_400 = "#56A6DF";
export const LIME_200 = "#D8F0B9";
export const LIME_300 = "#A6D56A";
export const LIME_400 = "#69B508";
export const PURPLE_100 = "#EFEBFA";
export const PURPLE_400 = "#8569D9";
export const PURPLE_500 = "#5836A0";
export const TEAL_100 = "#E0F5F4";
export const TEAL_200 = "#BAEEEB";
export const TEAL_300 = "#75D7D2";
export const TEAL_400 = "#00B4A8";
export const TEAL_500 = "#087171";
export const GREEN_400 = "#3EA27C";
export const MYIA_ORANGE_100 = "#FEEAE6";
export const MYIA_ORANGE_400 = "#F95C3D";
