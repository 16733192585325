import moment from "moment";

import { IPatientActivity, IVitalsArray } from "../../@types/patient";
import { IThreshold, IThresholdsDataRange } from "../../@types/thresholds";
import { getThresholdMarkers } from "../../thresholds-rule-engine/src";
import {
  IReading,
  IThresholdRule,
  IVitalsReadings,
} from "../../thresholds-rule-engine/types/thresholds";
import { DATE_FORMAT_YYYY_MM_DD } from "@/configs/constants";
import { PatientTimelineEvent } from "generated/types-gql";
import store from "@/store/store";

export const markerVitalHashMap: { [key: string]: string } = {
  hr: "avgHr",
  o2: "o2",
  rr: "avgRr",
  sbp: "systolicBp",
  systolicBp: "systolicBp",
  pulseRate: "pulseRate",
  glucoseFasting: "glucoseFasting",
  glucosePostMeal: "glucosePostMeal",
  glucosePreMeal: "glucosePreMeal",
  diastolicBp: "diastolicBp",
  weight: "weight",
  temperature: "temperature",
  medianTemperature: "medianTemperature",
  medianRestRr: "medianRestRr",
  medianRestHr: "medianRestHr",
};

export const globalThresholdNameMap: { [key: string]: string } = {
  "Global utility lower range threshold for absolute weight":
    "Calculated Weight Lower Range",
  "Global utility threshold for absolute weight": "Absolute Outer Weight Range",
  "Global utility upper range threshold for absolute weight":
    "Calculated Weight Upper Range",
};

export const metricMarkerMap: { [key: string]: string } = {
  diastolicBp: "mmHg",
  glucoseFasting: "mg/dl",
  glucosePostMeal: "mg/dl",
  glucosePreMeal: "mg/dl",
  hr: "bpm (avg)",
  avgHr: "bpm (avg)",
  o2: "%",
  pulseRate: "bpm (avg)",
  rr: "bpm (avg)",
  avgRr: "bpm (avg)",
  medianRestRr: "bpm (avg)",
  systolicBp: "mmHg",
  sbp: "mmHg",
  weight: "lbs",
  temperature: store?.getters?.showTemperatureInCelsius ? "°C " : "°F ",
  medianTemperature: store?.getters?.showTemperatureInCelsius ? "°C " : "°F ",
};

export const rawStatMetricNameMap: { [key: string]: string } = {
  hr: "avgHr",
  o2: "o2",
  rr: "avgRr",
  systolicBp: "systolicBp",
  diastolicBp: "diastolicBp",
  weight: "weight",
  survey: "survey",
  pulseRate: "pulseRate",
  glucoseFasting: "glucoseFasting",
  glucosePostMeal: "glucosePostMeal",
  glucosePreMeal: "glucosePreMeal",
};

export const metricFullNameMap: { [key: string]: string } = {
  avgRr: "Respiratory Rate (In Bed)",
  DATA___avgRr: "Respiratory Rate (In Bed)",
  medianRestRr: "Respiratory Rate",
  rr: "Respiratory Rate (In Bed)",
  diastolicBp: "Diastolic Blood Pressure",
  durationInBed: "Duration In Bed",
  DATA___durationInBed: "Duration In Bed",
  durationInSleep: "Duration In Sleep",
  DATA___durationInSleep: "Duration In Sleep",
  glucoseFasting: "Glucose Fasting",
  glucosePostMeal: "Glucose Post Meal",
  glucosePreMeal: "Glucose Pre Meal",
  avgHr: "Heart Rate (In Bed)",
  DATA___avgHr: "Heart Rate (In Bed)",
  hr: "Heart Rate (In Bed)",
  o2: "Blood Oxygen Saturation",
  pulseRate: "Heart Rate",
  systolicBp: "Systolic Blood Pressure",
  weight: "Weight",
  temperature: "Body Temperature",
  medianTemperature: "Skin Temperature",
};

export enum ThresholdOrderPriorityByMarker {
  pulseRate = 1,
  medianRestRr,
  systolicBp,
  diastolicBp,
  o2,
  glucoseFasting,
  glucosePreMeal,
  glucosePostMeal,
  weight,
  avgHr,
  avgRr,
}

export const conditionIsInvalid = (
  value?: number | null,
  marker?: string | null,
  minValue?: number | null,
) => {
  if (!value) {
    return { message: "Must be numerical value.", status: true };
  }

  const valueRanges: any = {
    hr: [1, 200],
    avgHr: [1, 200],
    o2: [1, 100],
    rr: [1, 100],
    avgRr: [1, 100],
    medianRestRr: [1, 100],
    systolicBp: [1, 299],
    diastolicBp: [1, 299],
    weight: [1, 999],
    pulseRate: [1, 250],
    glucoseFasting: [1, 600],
    glucosePreMeal: [1, 600],
    glucosePostMeal: [1, 600],
    medianTemperature: [33, 200],
    temperature: [33, 200],
  };
  const timeRange = [1, 90];

  if (!Number.isInteger(value)) {
    return {
      message: "Decimal numbers are not allowed",
      status: true,
    };
  }

  if (minValue && value < minValue) {
    return {
      message: `The min value must be at least ${minValue}.`,
      status: true,
    };
  }

  if (marker == null) {
    if (value < timeRange[0] || value > timeRange[1]) {
      return {
        message: `Must be a number between ${timeRange[0]} and ${timeRange[1]}.`,
        status: true,
      };
    }
  } else {
    if (value < valueRanges[marker][0] || value > valueRanges[marker][1]) {
      return {
        message: `Must be a number between ${valueRanges[marker][0]} and ${valueRanges[marker][1]}.`,
        status: true,
      };
    }
  }
  return { status: false };
};

export const getMeasurementName = (marker?: string | null) => {
  switch (marker) {
    case "hr":
    case "rr":
    case "avgHr":
    case "avgRr":
    case "pulseRate":
    case "medianRestRr":
      return "bpm ";
    case "systolicBp":
    case "diastolicBp":
      return "mmHg ";
    case "weight":
      return "lbs ";
    case "glucoseFasting":
    case "glucosePreMeal":
    case "glucosePostMeal":
      return "mg/dl ";
    case "medianTemperature":
    case "temperature":
      return store?.getters?.showTemperatureInCelsius ? "°C " : "°F ";
    default:
      return "";
  }
};

export const getStatType = (statName?: string | null) => {
  switch (statName) {
    case "hr":
    case "avgHr":
      return "Nighttime HR daily ";
    case "systolicBp":
      return "Daily SBP ";
    case "diastolicBp":
      return "Daily DBP ";
    case "weight":
      return "Weight daily ";
    case "rr":
    case "avgRr":
      return "Nighttime RR daily ";
    case "medianRestRr":
      return "Respiratory Rate ";
    case "o2":
      return "Blood Oxygen Saturation ";
    case "pulseRate":
      return "Heart Rate ";
    case "glucoseFasting":
      return "Fasting Glucose ";
    case "glucosePreMeal":
      return "Pre-meal Glucose ";
    case "glucosePostMeal":
      return "Post-meal Glucose ";
    case "medianTemperature":
      return "Skin Temperature ";
    case "temperature":
      return "Body Temperature ";
    default:
      return "";
  }
};

export const getReadingAlgorithm = (algorithm?: string | null) => {
  switch (algorithm) {
    case "latest":
      return "latest reading ";
    case "max":
      return "max reading ";
    case "min":
      return "min reading ";
    case "avg":
      return "readings avg. ";
    default:
      return "";
  }
};

export const getComparator = (type?: string | null) => {
  switch (type) {
    case "eq":
      return "";
    case "gt":
      return "> ";
    case "gte":
      return ">= ";
    case "lt":
      return "< ";
    case "lte":
      return "<= ";
    default:
      return "";
  }
};

export const getChangeAlgorithm = (type?: string | null) => {
  switch (type) {
    case "decrease":
      return "decreases by ";
    case "increase":
      return "increases by ";
    case "absolute":
      return "is ";
    default:
      return "";
  }
};

export const areAllThresholdsDeleted = (
  timelineEvent: PatientTimelineEvent,
) => {
  if (!timelineEvent.thresholds) {
    return false;
  }
  return timelineEvent.thresholds.filter((t) => !t.hiddenFromPP).length === 0;
};

export const getThresholdsMarkersData = (
  vitals: IPatientActivity[],
  thresholds: IThreshold[],
  range?: IThresholdsDataRange,
  disableChanges?: boolean | null,
): IVitalsReadings => {
  const markerNames = thresholds.reduce((thresholdsResult, threshold) => {
    return new Set<string>([
      ...thresholdsResult,
      ...getThresholdMarkers(threshold),
    ]);
  }, new Set<string>());
  const r = range == null ? getVitalsRangeFromThresholds(thresholds) : range;
  const data = fillVitalsDataGaps(vitals, r, disableChanges);

  const markerDataMap: Record<string, any> = {};
  for (const c of markerNames ?? []) {
    markerDataMap[c] = getReadingsFromDailyVitals(c, data);
  }
  return markerDataMap;
};

const fillVitalsDataGaps = (
  data: IPatientActivity[],
  range: IThresholdsDataRange,
  disableChanges?: boolean | null,
) => {
  const vitals: (IPatientActivity | null)[] = [...data].sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
  let start = moment(range.start);
  const days = moment(range.end).diff(start, "days") + 1; // To make start and end dates inclusive
  if (vitals.length >= days) {
    return vitals;
  }
  for (let i = 0; i < days; i++) {
    if (
      i >= vitals.length ||
      vitals[i]?.date !== start.format(DATE_FORMAT_YYYY_MM_DD)
    ) {
      vitals.splice(i, 0, null);
    }
    if (!disableChanges) {
      start = start.add(1, "day");
    } else {
      start.add(1, "day");
    }
  }
  return vitals;
};

const getVitalsRangeFromThresholds = (
  thresholds: IThreshold[],
  date?: string,
) => {
  const endDate = date != null ? moment(date) : moment();
  const maxDays = thresholds.reduce(
    (result, threshold) => Math.max(getThresholdMaxTime(threshold), result),
    0,
  );
  const startDate =
    maxDays > 0
      ? moment(endDate).subtract(maxDays, "days")
      : moment(endDate).subtract(1, "days");
  return {
    end: endDate.format(DATE_FORMAT_YYYY_MM_DD),
    start: startDate.format(DATE_FORMAT_YYYY_MM_DD),
  };
};

const getReadingsFromDailyVitals = (
  markerName: string,
  vitals: (IPatientActivity | null)[],
): IReading[] => {
  const prop = rawStatMetricNameMap[markerName] || markerName;
  return vitals.map((vital) => {
    if (
      prop === "pulseRate" &&
      vital?.[prop] == null &&
      vital?.medianRestHr == null
    ) {
      return [];
    }

    if (vital == null || vital[prop] == null) {
      return [];
    }
    const additionalVitals = prop === "pulseRate" ? vital.medianRestHr : [];
    return vital[prop]
      .concat(additionalVitals)
      .filter((v: IVitalsArray) => v != null)
      .map((v: IVitalsArray) => ({ r: v[2], t: v[0], v: v[1] }));
  });
};

const getThresholdMaxTime = (threshold: IThreshold): number =>
  Object.values(threshold.rules || {}).reduce(
    (result, rule) => Math.max(result, getThresholdGroupMaxTime(rule)),
    0,
  );

const getThresholdGroupMaxTime = (rule: IThresholdRule): number =>
  (rule.conditions || []).reduce(
    (result, condition) => Math.max(result, condition.time || 0),
    0,
  );

export const getThresholdAllMarkers = (threshold: IThreshold) =>
  Object.values(threshold.rules || {})
    .map((r) => (r.conditions || []).map((c) => c.marker))
    .filter((m) => m != null)
    .flat();
