import {
  DATE_FORMAT_MMM_D_YYYY,
  DAYS_IN_MONTH,
  DAYS_IN_WEEK,
  DAYS_IN_YEAR,
  DAY_IN_MILLISECONDS,
} from "@/configs/constants";
import { PatientTimelineEvent, PatientTimelineTask } from "generated/types-gql";
import moment from "moment";

import { IActiveTimelineTask, TimeUnit } from "../../@types/patient";

export const vitalTypes = [
  "weight",
  "avgHr",
  "avgRr",
  "durationInBed",
  "emojis",
  "systolicBp",
];

export const getEventHeader = (ev: PatientTimelineEvent) => {
  switch (ev.eventType) {
    case "annotation":
      return `Annotation - ${moment(ev.startDate).format(
        DATE_FORMAT_MMM_D_YYYY,
      )}`;
    case "medChange":
      return `Med Change - ${moment(ev.startDate).format(
        DATE_FORMAT_MMM_D_YYYY,
      )}`;
    case "medicalVisit":
      return `${ev.visitType?.join(", ")} - ${moment(ev.startDate).format(
        DATE_FORMAT_MMM_D_YYYY,
      )}${
        ev.endDate
          ? moment(ev.endDate).format(` - ${DATE_FORMAT_MMM_D_YYYY}`)
          : ""
      }`;
    case "alert":
    case "warning": // TODO remove this line when all logged alerts have only "alert" type
      return `${
        ev.eventName || ev.thresholds?.[0].name || ev.eventType
      } - ${moment(ev.createdAt).format(DATE_FORMAT_MMM_D_YYYY)}`;
    case "periodicFollowup":
      return `Periodic Reminder to Review - ${moment(ev.startDate).format(
        DATE_FORMAT_MMM_D_YYYY,
      )}`;
    case "followup":
      return "Scheduled Review";
    default:
      return ev.eventType;
  }
};

export const isHospitalizationTimelineEvent = (
  event: PatientTimelineEvent | null,
) => {
  return event?.eventName?.toLowerCase().includes("hospitalization");
};

export const isAdmissionTimelineEvent = (event: PatientTimelineEvent) => {
  return isHospitalizationTimelineEvent(event) && !event.endDate;
};

export const isDischargeTimelineEvent = (event: PatientTimelineEvent) => {
  return isHospitalizationTimelineEvent(event) && event.endDate;
};

export const isEventDateNotEditable = (event: PatientTimelineEvent) => {
  return event.dateNotEditable;
};

export const isAdmissionEhr = (event: PatientTimelineEvent) => {
  return isAdmissionTimelineEvent(event) && event.source === "sdk";
};

export const isHospitalizationEvent = (event: PatientTimelineEvent) => {
  return !!event?.visitType
    ?.map((t: string) => t.toLowerCase())
    .find((t: string) => t.includes("hospitalization"));
};

export const isAdmissionEvent = (event: PatientTimelineEvent) => {
  return !!(isHospitalizationEvent(event) && !event.endDate);
};

export const isDischargeEvent = (event: PatientTimelineEvent) => {
  return !!(isHospitalizationEvent(event) && event.endDate);
};

export const validateReminderValues = (
  period: number | string | null,
  reminderUnit: TimeUnit | null,
) => {
  const valueRanges: any = {
    d: [0, 30],
    w: [0, 4],
  };
  if (reminderUnit == null) {
    return { status: false };
  }

  if (!valueRanges[reminderUnit]) {
    return {
      message: "Reminder unit not supported",
      status: true,
    };
  }

  if (!period) {
    return {
      message: "Please enter a number",
      status: true,
    };
  }
  const value = Number(period);
  if (period) {
    if (!Number.isInteger(value))
      return {
        message: "Decimal numbers are not allowed",
        status: true,
      };
    if (String(period) !== String(value)) {
      return {
        message: "Please enter a valid number",
        status: true,
      };
    }
  }

  if (
    value < valueRanges[reminderUnit][0] ||
    value > valueRanges[reminderUnit][1]
  ) {
    const periodName = reminderUnit === "d" ? "days" : "weeks";
    return {
      message: `Must be between ${valueRanges[reminderUnit][0]} and ${valueRanges[reminderUnit][1]} ${periodName}.`,
      status: true,
    };
  }
  return { status: false };
};

export const getMillisecondsFromTimeUnit = (timeUnit: TimeUnit | string) => {
  switch (timeUnit) {
    case "d":
      return DAY_IN_MILLISECONDS;
    case "w":
      return DAYS_IN_WEEK * DAY_IN_MILLISECONDS;
    case "m":
      return DAYS_IN_MONTH * DAY_IN_MILLISECONDS;
    case "y":
      return DAYS_IN_YEAR * DAY_IN_MILLISECONDS;
    default:
      return 0;
  }
};

export const getTaskReminderTimestamp = (
  task: PatientTimelineTask | IActiveTimelineTask,
) => {
  if (task.reminder == null || task.reminderUnit == null) {
    return task.taskDate;
  }
  return (
    task.taskDate -
    task.reminder * getMillisecondsFromTimeUnit(task.reminderUnit)
  );
};
