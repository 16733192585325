
import { Component, Vue } from "vue-property-decorator";
import {
  logout,
  resetLastInteraction,
  silentLogout,
} from "@/services/auth.service";
import { INTERACTION_EVENT_LIST } from "@/configs/constants";

@Component
export default class MyiaTimedOut extends Vue {
  private mounted() {
    sessionStorage.setItem("destinationPath", this.$route.fullPath);
    INTERACTION_EVENT_LIST.forEach((event) => {
      window.removeEventListener(event, resetLastInteraction);
    });
    silentLogout();
  }
  private goToLogin() {
    logout();
  }
}
